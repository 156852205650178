import React from 'react';
import PropTypes from 'prop-types';

function TableRow({
  year,
  type_of_credit,
  qualifying_years,
  annualized_earnings,
  isOdd,
}) {
  return (
    <tr className={isOdd ? 'bg-light-gray' : ''}>
      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal text-b10'>
        {year}
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal text-b10'>
        {type_of_credit}
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal text-b10'>
        {qualifying_years}
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal text-b10'>
        {annualized_earnings}
      </td>
    </tr>
  );
}

export default TableRow;
TableRow.propTypes = {
  annualized_earnings: PropTypes.string,
  qualifying_years: PropTypes.string,
  type_of_credit: PropTypes.string,
  year: PropTypes.string,
  isOdd: PropTypes.bool,
};

TableRow.defaultProps = {
  annualized_earnings: '',
  qualifying_years: '',
  type_of_credit: '',
  year: '',
  isOdd: false,
};
