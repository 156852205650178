import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import { STORAGE_KEYS, emailRegex } from '../../helper';
import { toastMessage } from '../../helper/function';
import { ROUTE_PATH, TOAST_MESSAGE_TYPE } from '../../helper/constant';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const loginHandler = async (data) => {
    try {
      setIsLoading(true);
      const loginResponse = await axiosInstance.post('login', {
        email: data?.email.trim(),
        password: data?.password?.trim(),
      });
      setIsLoading(false);
      localStorage.setItem(
        STORAGE_KEYS.token,
        loginResponse.headers['auth_token']
      );
      toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, 'Login Successful');
      navigate(ROUTE_PATH.HOME);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, error?.response?.data?.meta?.message);
      }
    }
  };

  return (
    <div className="bg-cover bg-center bg-no-repeat bg-auth-panel h-screen w-screen">
      <div className="p-4 h-screen w-screen flex items-center justify-center">
        <div className="p-8 bg-black bg-opacity-60 backdrop-blur-lg rounded-4xl max-w-md border border-white ">
          <form noValidate onSubmit={handleSubmit(loginHandler)}>
            <div className="flex flex-col items-center gap-7">
              <div>
                <img
                  src="/assets/images/pension-id-logo.svg"
                  alt="PENSION ID"
                />
              </div>

              <p className="text-white text-xl font-semibold">
                Log in to pensionID
              </p>

              <div className="w-full">
                {/* Enter Email */}
                <div className="mb-6">
                  <label className="block text-white text-lsm font-normal mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Your email"
                    {...register('email', {
                      required: 'Email is required.',
                      pattern: {
                        value: emailRegex,
                        message: 'Email is not valid.',
                      },
                    })}
                    className="w-full p-3 bg-white border border-white text-lsm font-normal text-black focus:outline-none focus:border-primary duration-500 rounded-lg min-w-96 placeholder:text-neutral-gray"
                  />
                  {errors.email && (
                    <p className="text-red-600 text-xxs mt-1">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                {/* Enter password */}
                <div className="">
                  <label className="block text-white text-lsm font-normal mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder="Password"
                    {...register('password', {
                      required: 'Password is required.',
                      validate: {
                        uppercase: (value) =>
                          /[A-Z]/.test(value) ||
                          'Password should contain at least one uppercase letter.',
                        lowercase: (value) =>
                          /[a-z]/.test(value) ||
                          'Password should contain at least one lowercase letter.',
                        digit: (value) =>
                          /[0-9]/.test(value) ||
                          'Password should contain at least one digit.',
                        special: (value) =>
                          /[!@#$*]/.test(value) ||
                          'Password should contain at least one special symbol.',
                      },
                      minLength: {
                        value: 6,
                        message: 'Password should be at-least 6 characters.',
                      },
                    })}
                    className="w-full p-3 bg-white border border-white text-lsm font-normal text-black focus:outline-none focus:border-primary duration-500 rounded-lg min-w-96 placeholder:text-neutral-gray"
                  />
                  {errors.password && (
                    <p className="text-red-600 text-xxs mt-1">
                      {errors.password.message}
                    </p>
                  )}
                  <Link
                    to="/forgot-password"
                    className="block mt-3 w-fill text-end font-semibold text-white underline "
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <button
                disabled={isLoading}
                type="submit"
                className="font-ibm py-3 px-6 rounded-80x bg-primary text-white text-lsm font-semibold min-w-32 text-center"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
