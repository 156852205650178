import React from 'react';
import PropTypes from 'prop-types';

function PensionInfoSummaryCard({
  summaryData,
  showLastServiceRecordDate,
  fromApplicationForm,
  headerClassName,
  contentClassName,
  titleClassName,
  dateClassName,
  itemLabelClassName,
  itemValueClassName,
}) {
  const getItemClass = (isLabel, isFirst = false) => {
    if (fromApplicationForm) {
      return isFirst
        ? isLabel
          ? 'text-lsm font-semibold text-b10'
          : 'text-md font-semibold text-b10'
        : 'text-xxs font-medium text-neutral-gray';
    }
    return isLabel ? itemLabelClassName : itemValueClassName;
  };
  return (
    <div className='bg-white p-4 rounded-2xl shadow-single flex-grow'>
      <div className={headerClassName}>
        <p className={titleClassName}>Summary</p>
        {(fromApplicationForm || showLastServiceRecordDate) && (
          <p className={dateClassName}>
            {fromApplicationForm
              ? 'as of ' + summaryData?.last_service_record_date
              : summaryData?.last_service_record_date}
          </p>
        )}
      </div>
      <div className={contentClassName}>
        <div className='flex justify-between items-center'>
          <p className={getItemClass(true, true)}>
            {fromApplicationForm
              ? 'Total Credit in years'
              : 'Total credit in years:'}
          </p>
          <p className={getItemClass(false, true)}>
            {summaryData?.total_credited_years}
          </p>
        </div>
        <div className='flex justify-between items-center'>
          <p className={getItemClass(true)}>
            {fromApplicationForm
              ? 'Total Qualifying years'
              : 'Total qualifying years:'}
          </p>
          <p className={getItemClass(false)}>
            {summaryData?.total_qualifying_years}
          </p>
        </div>
        <div className='flex justify-between items-center'>
          <p className={getItemClass(true)}>Age:</p>
          <p className={getItemClass(false)}>{summaryData?.age}</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className={getItemClass(true)}>
            {fromApplicationForm ? 'Qualifying Factor' : 'Qualifying factor:'}
          </p>
          <p className={getItemClass(false)}>
            {summaryData?.qualifying_factor}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PensionInfoSummaryCard;
PensionInfoSummaryCard.propTypes = {
  summaryData: PropTypes.shape({
    age: PropTypes.number.isRequired,
    total_credited_years: PropTypes.string.isRequired,
    total_qualifying_years: PropTypes.string.isRequired,
    qualifying_factor: PropTypes.string.isRequired,
    last_service_record_date: PropTypes.string.isRequired,
  }),
  showLastServiceRecordDate: PropTypes.bool,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  dateClassName: PropTypes.string,
  itemLabelClassName: PropTypes.string,
  itemValueClassName: PropTypes.string,
  fromApplicationForm: PropTypes.bool,
};
PensionInfoSummaryCard.defaultProps = {
  showLastServiceRecordDate: false,
  headerClassName:
    'flex justify-between items-center mb-4 pb-2.5 border-b border-b-bord-g20',
  contentClassName: 'flex flex-col gap-3',
  titleClassName: 'text-lbase font-semibold text-blue-900',
  dateClassName: 'text-lsm font-semibold text-blue-900',
  itemLabelClassName: 'text-sm font-normal text-neutral-gray',
  itemValueClassName: 'text-md font-medium text-blue-800',
  fromApplicationForm: false,
};
