import React, { useEffect, useState, useCallback } from 'react';
import { Label, Radio } from 'flowbite-react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  CustomDatePicker,
  FormInput,
  FormSelect,
  FormSubmit,
} from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import {
  STORAGE_KEYS,
  emailRegex,
  nameRegex,
  radioButtonTheme,
} from '../../helper';
import {
  debounce,
  getExactValueFromEnum,
  handleCSSEffect,
  toastMessage,
  updatePensionApplicationFormStep,
} from '../../helper/function';
import {
  FIELDS_UPDATE_EFFECT_TIMEOUT,
  GENDER_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  PENSION_APPLICATION_FORM_STEPS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  SELECT_OPTIONS,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import {
  setDataForChatBot,
  setUpdatedDataFromChatBot,
  setWantToUpdateData,
} from '../../features/chatbot/chatbot';
import { setUserInfo } from '../../features/user/userSlice';

function PersonalInformation({ tabHandler, tabsRef }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;

  const { userInfo } = useSelector((state) => state.user);
  const {
    screenName,
    updatedDataFromChatBot,
    wantToUpdateData,
    dataForChatBot,
  } = useSelector((state) => state.chatbot);
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );

  const [personalInfo, setPersonalInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [aiUpdatedDataEffectFields, setAiUpdatedDataEffectFields] = useState(
    []
  );

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      gender: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      date_of_birth: '',
      marital_status: '',
    },
  });

  const formData = watch();

  const getPersonalInfo = async () => {
    try {
      const response = await axiosInstance('profile/personal');
      setPersonalInfo(response?.data?.data);
    } catch (err) {
      console.error('error while fetching personal details', err);
    }
  };

  const submitPersonalInfoData = async (
    data,
    isNext = false,
    isPrevious = false
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        'update/profile/personal-details',
        data
      );
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
        dispatch(
          setUserInfo({
            ...userInfo,
            data: {
              ...userInfo.data,
              first_name: response?.data?.data?.first_name,
              middle_name: response?.data?.data?.middle_name || '',
              last_name: response?.data?.data?.last_name,
              // email: response?.data?.data?.email,
            },
          })
        );
        if (isNext) {
          const updatedPensionApplicationFormData = await axiosInstance.get(
            'retirement/form/get'
          );
          const lastUpdatedStep =
            updatedPensionApplicationFormData?.data?.data?.step;
          if (
            pathname === ROUTE_PATH.APPLICATION_FORM &&
            (pensionApplicationFormData?.step ===
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE ||
              lastUpdatedStep ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE)
          ) {
            await updatePensionApplicationFormStep({
              step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT,
            });
          }
          tabsRef?.current?.setActiveTab(1);
        } else if (isPrevious) {
          tabHandler(
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
          );
        }
      }
      getPersonalInfo();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('error while updating personal details', err);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        err?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  const onSubmit = async (data) => {
    submitPersonalInfoData({
      ...data,
      marital_status: data.marital_status?.value || data.marital_status,
    });
  };

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE ||
      screenName === SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.MY_PROFILE
    ) {
      getPersonalInfo();
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE
    ) {
      dispatch(
        setDataForChatBot({
          personal_profile: {
            gender: personalInfo?.gender,
            first_name: personalInfo?.first_name,
            middle_name: personalInfo?.middle_name || '',
            last_name: personalInfo?.last_name,
            // email: personalInfo?.email,
            date_of_birth: personalInfo?.date_of_birth,
            marital_status: personalInfo?.marital_status,
          },
          node: 'personal_profile',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }
  }, [screenName, personalInfo]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit &&
      'data' in updatedDataFromChatBot
    ) {
      const updatedFields = [];
      if (
        (getExactValueFromEnum(
          updatedDataFromChatBot?.data?.gender,
          Object.values(GENDER_OPTIONS)
        ) || personalInfo?.gender) !== formData?.gender
      ) {
        updatedFields.push({
          fieldName: 'gender',
          oldValue: formData?.gender,
          newValue:
            getExactValueFromEnum(
              updatedDataFromChatBot?.data?.gender,
              Object.values(GENDER_OPTIONS)
            ) || personalInfo?.gender,
        });
      }
      if (updatedDataFromChatBot?.data?.first_name !== formData?.first_name) {
        updatedFields.push({
          fieldName: 'first_name',
          oldValue: formData?.first_name,
          newValue: updatedDataFromChatBot?.data?.first_name,
        });
      }
      if (
        (updatedDataFromChatBot?.data?.middle_name || '') !==
        formData?.middle_name
      ) {
        updatedFields.push({
          fieldName: 'middle_name',
          oldValue: formData?.middle_name,
          newValue: updatedDataFromChatBot?.data?.middle_name || '',
        });
      }
      if (updatedDataFromChatBot?.data?.last_name !== formData?.last_name) {
        updatedFields.push({
          fieldName: 'last_name',
          oldValue: formData?.last_name,
          newValue: updatedDataFromChatBot?.data?.last_name,
        });
      }
      if (
        updatedDataFromChatBot?.data?.date_of_birth !== formData?.date_of_birth
      ) {
        updatedFields.push({
          fieldName: 'date_of_birth',
          oldValue: formData?.date_of_birth,
          newValue: updatedDataFromChatBot?.data?.date_of_birth,
        });
      }
      if (
        (getExactValueFromEnum(
          updatedDataFromChatBot?.data?.marital_status,
          Object.values(MARITAL_STATUS_OPTIONS)
        ) || personalInfo?.marital_status) !== formData?.marital_status
      ) {
        updatedFields.push({
          fieldName: 'marital_status',
          oldValue: formData?.marital_status,
          newValue:
            getExactValueFromEnum(
              updatedDataFromChatBot?.data?.marital_status,
              Object.values(MARITAL_STATUS_OPTIONS)
            ) || personalInfo?.marital_status,
        });
      }
      setAiUpdatedDataEffectFields(updatedFields);

      setValue(
        'gender',
        getExactValueFromEnum(
          updatedDataFromChatBot?.data?.gender,
          Object.values(GENDER_OPTIONS)
        ) || personalInfo?.gender
      );
      setValue('first_name', updatedDataFromChatBot?.data?.first_name);
      setValue('middle_name', updatedDataFromChatBot?.data?.middle_name || '');
      setValue('last_name', updatedDataFromChatBot?.data?.last_name);
      // setValue('email', updatedDataFromChatBot?.data?.email);
      setValue('date_of_birth', updatedDataFromChatBot?.data?.date_of_birth);
      setValue(
        'marital_status',
        getExactValueFromEnum(
          updatedDataFromChatBot?.data?.marital_status,
          Object.values(MARITAL_STATUS_OPTIONS)
        ) || personalInfo?.marital_status
      );
      setPersonalInfo({
        ...personalInfo,
        first_name: updatedDataFromChatBot?.data?.first_name,
        middle_name: updatedDataFromChatBot?.data?.middle_name || '',
        last_name: updatedDataFromChatBot?.data?.last_name,
        marital_status:
          getExactValueFromEnum(
            updatedDataFromChatBot?.data?.marital_status,
            Object.values(MARITAL_STATUS_OPTIONS)
          ) || personalInfo?.marital_status,
        // email: updatedDataFromChatBot?.data?.email,
        gender:
          getExactValueFromEnum(
            updatedDataFromChatBot?.data?.gender,
            Object.values(GENDER_OPTIONS)
          ) || personalInfo?.gender,
        date_of_birth: updatedDataFromChatBot?.data?.date_of_birth,
      });
      dispatch(setWantToUpdateData(false));
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  useEffect(() => {
    if (personalInfo) {
      setValue('gender', personalInfo?.gender);
      setValue('first_name', personalInfo?.first_name);
      setValue('middle_name', personalInfo?.middle_name || '');
      setValue('last_name', personalInfo?.last_name);
      setValue('email', personalInfo?.email);
      setValue('date_of_birth', personalInfo?.date_of_birth);
      setValue('marital_status', personalInfo?.marital_status);
    }
  }, [personalInfo]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE &&
      (updatedDataFromChatBot?.want_submit ||
        updatedDataFromChatBot?.is_next ||
        updatedDataFromChatBot?.is_previous) &&
      Object.values(dataForChatBot?.personal_profile)?.length > 0
    ) {
      submitPersonalInfoData(
        personalInfo,
        updatedDataFromChatBot?.is_next,
        updatedDataFromChatBot?.is_previous
      );
      if (
        !updatedDataFromChatBot?.is_next &&
        !updatedDataFromChatBot?.is_previous
      ) {
        dispatch(
          setUpdatedDataFromChatBot({
            text: updatedDataFromChatBot?.text,
            redable_text: updatedDataFromChatBot?.redable_text,
          })
        );
      } else {
        dispatch(setUpdatedDataFromChatBot({}));
      }
    }
  }, [updatedDataFromChatBot]);

  useEffect(() => {
    if (aiUpdatedDataEffectFields.length > 0) {
      setTimeout(() => {
        setAiUpdatedDataEffectFields([]);
      }, FIELDS_UPDATE_EFFECT_TIMEOUT);
    }
  }, [aiUpdatedDataEffectFields]);

  const { call: debounceUpdate, cancel: cancelDebounce } = useCallback(
    debounce((formData) => {
      dispatch(
        setDataForChatBot({
          personal_profile: {
            gender: formData?.gender,
            first_name: formData?.first_name,
            middle_name: formData?.middle_name || '',
            last_name: formData?.last_name,
            // email: formData?.email,
            date_of_birth: formData?.date_of_birth,
            marital_status: formData?.marital_status,
          },
          node: 'personal_profile',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE
    ) {
      debounceUpdate(formData);
    }
    return () => {
      cancelDebounce();
    };
  }, [formData, debounceUpdate, screenName]);

  return (
    <>
      <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
        <h3 className='text-base font-semibold text-b10'>
          Personal Information
        </h3>
        <img src='/assets/images/icons/edit.svg' alt='Edit' />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-6'>
          <span className='mb-2 block text-lsm font-normal text-b10'>
            Gender
          </span>
          <div className='flex gap-6 items-center'>
            <div
              className={`flex items-center gap-3 ${handleCSSEffect({
                stateVariableArray: aiUpdatedDataEffectFields,
                fieldName: 'gender',
                value: GENDER_OPTIONS.MALE,
              })}`}
            >
              <Radio
                theme={radioButtonTheme}
                id='male'
                {...register('gender', { required: 'Gender is required' })}
                value={GENDER_OPTIONS.MALE}
                defaultChecked={personalInfo?.gender === GENDER_OPTIONS.MALE}
              />
              <Label className='text-lsm font-normal text-b10' htmlFor='male'>
                Male
              </Label>
            </div>
            <div
              className={`flex items-center gap-3 ${handleCSSEffect({
                stateVariableArray: aiUpdatedDataEffectFields,
                fieldName: 'gender',
                value: GENDER_OPTIONS.FEMALE,
              })}`}
            >
              <Radio
                theme={radioButtonTheme}
                id='female'
                {...register('gender', { required: 'Gender is required' })}
                value={GENDER_OPTIONS.FEMALE}
                defaultChecked={personalInfo?.gender === GENDER_OPTIONS.FEMALE}
              />
              <Label className='text-lsm font-normal text-b10' htmlFor='female'>
                Female
              </Label>
            </div>
            <div
              className={`flex items-center gap-3 ${handleCSSEffect({
                stateVariableArray: aiUpdatedDataEffectFields,
                fieldName: 'gender',
                value: GENDER_OPTIONS.OTHER,
              })}`}
            >
              <Radio
                theme={radioButtonTheme}
                id='other'
                {...register('gender', { required: 'Gender is required' })}
                value={GENDER_OPTIONS.OTHER}
                defaultChecked={personalInfo?.gender === GENDER_OPTIONS.OTHER}
              />
              <Label className='text-lsm font-normal text-b10' htmlFor='other'>
                Other
              </Label>
            </div>
            {errors.gender && (
              <p className='text-red-600 text-xxs mt-1'>
                {errors.gender.message}
              </p>
            )}
          </div>
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              First Name
            </span>
            <Controller
              name='first_name'
              control={control}
              rules={{
                required: 'First Name is required',
                pattern: {
                  value: nameRegex,
                  message: 'First name is not valid.',
                },
              }}
              render={({ field }) => (
                <FormInput
                  type='text'
                  placeholder='First Name'
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'first_name',
                  })}
                  errorFieldName='first_name'
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Middle Name
            </span>
            <Controller
              name='middle_name'
              control={control}
              rules={{
                pattern: {
                  value: nameRegex,
                  message: 'Middle Name is not valid.',
                },
              }}
              render={({ field }) => (
                <FormInput
                  type='text'
                  placeholder='Middle Name'
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'middle_name',
                  })}
                  errorFieldName='middle_name'
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Last Name
            </span>
            <Controller
              name='last_name'
              control={control}
              rules={{
                required: 'Last Name is required',
                pattern: {
                  value: nameRegex,
                  message: 'Last name is not valid.',
                },
              }}
              render={({ field }) => (
                <FormInput
                  type='text'
                  placeholder='last Name'
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'last_name',
                  })}
                  errorFieldName='last_name'
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Date of Birth
            </span>
            <Controller
              name='date_of_birth'
              control={control}
              rules={{ required: 'Date of Birth is required' }}
              render={({ field }) => (
                <CustomDatePicker
                  value={field.value}
                  onChange={field.onChange}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  autoComplete='off'
                  placeholderText='DD/MM/YYYY'
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'date_of_birth',
                  })}
                />
              )}
            />
            {errors.date_of_birth && (
              <p className='text-red-600 text-xxs mt-1'>
                {errors.date_of_birth.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Marital Status
            </span>
            <Controller
              name='marital_status'
              control={control}
              rules={{ required: 'Marital Status is required' }}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormSelect
                    errors={errors}
                    id='maritialStatus'
                    SelectOptions={SELECT_OPTIONS?.map((option) => {
                      if (
                        personalInfo?.marital_status ===
                        MARITAL_STATUS_OPTIONS.SINGLE
                      ) {
                        if (option.value === MARITAL_STATUS_OPTIONS.DIVORCED) {
                          return { ...option, isDisabled: true };
                        }
                      }
                      if (
                        personalInfo?.marital_status ===
                        MARITAL_STATUS_OPTIONS.MARRIED
                      ) {
                        if (option.value === MARITAL_STATUS_OPTIONS.SINGLE) {
                          return { ...option, isDisabled: true };
                        }
                      }
                      return option;
                    })}
                    value={SELECT_OPTIONS?.find(
                      (option) => option.value === value
                    )}
                    onChange={onChange}
                    updateEffect={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'marital_status',
                    })}
                  />
                );
              }}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Email Address
            </span>
            <Controller
              name='email'
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: emailRegex,
                  message: 'Email is not valid.',
                },
              }}
              render={({ field }) => (
                <FormInput
                  type='email'
                  placeholder='lealfonso.b@gmail.com'
                  readOnly
                  errorFieldName='email'
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className='flex justify-end mt-6'>
          <FormSubmit
            type={'submit'}
            buttonText={'Save Changes'}
            btnDisable={isLoading}
          />
        </div>
      </form>
    </>
  );
}

PersonalInformation.propTypes = {
  tabHandler: PropTypes.func,
  tabsRef: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
};

PropTypes.defaultProps = {
  tabHandler: () => {},
  tabsRef: null,
};

export default PersonalInformation;
