import { createSlice } from '@reduxjs/toolkit';

const navbar = createSlice({
  name: 'navbarTitle',
  initialState: 'Service Record',
  reducers: {
    setNavbarTitle: (_, action) => action.payload,
  },
});

export const { setNavbarTitle } = navbar.actions;

export default navbar.reducer;
