import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { setNavbarTitle } from '../features/navbar/navbarSlice';
import { ROUTE_PATH } from '../helper/constant';

export const useCompareScenario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [scenariosData, setScenariosData] = useState({ scenarios: [] });
  const [compareScenarioData, setCompareScenarioData] = useState([
    { title: '', scenarioId: '' },
  ]);
  const [comparedData, setComparedData] = useState({});
  const [projectionData, setProjectionData] = useState({});

  const fetchCompareScenarioData = useCallback(async (myQueryParam) => {
    try {
      const res = await axiosInstance.post('scenarios/compare', {
        scenarios_id: [...myQueryParam],
      });
      setComparedData(res?.data?.data);
    } catch (error) {
      console.error('error while fetching compare scenarios', error);
    }
  }, []);

  const formatTitles = useCallback((data) => {
    if (data?.length < 2) {
      return 'Not enough data to compare';
    }
    const titles = data?.map((item) => item.title);
    return titles.join(' vs ');
  }, []);

  const formattedTitles = useMemo(
    () => formatTitles(compareScenarioData),
    [compareScenarioData, formatTitles]
  );

  useEffect(() => {
    if (ids?.length >= 2) {
      fetchCompareScenarioData(ids);
    }
  }, [ids, fetchCompareScenarioData]);

  useEffect(() => {
    const urlIds =
      new URLSearchParams(location.search)
        .get('ids')
        ?.split(',')
        .filter(Boolean) || [];
    setIds(urlIds);
  }, [location.search]);

  useEffect(() => {
    dispatch(setNavbarTitle('Pension Calculator'));
    axiosInstance
      .get('scenarios')
      .then((response) => {
        if (response?.data?.data) {
          setScenariosData(response.data.data || { scenarios: [] });
          setProjectionData(response.data.data?.projection || {});
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  }, []);

  useEffect(() => {
    if (
      !projectionData.id ||
      !scenariosData.scenarios ||
      scenariosData.scenarios.length < 1
    )
      return;

    let scenariosToCompare = [];

    if (ids.length >= 1) {
      scenariosToCompare = ids
        .map((id) => {
          if (id === projectionData.id.toString()) {
            return projectionData;
          }
          return scenariosData.scenarios.find((s) => s.id.toString() === id);
        })
        .filter(Boolean);
    }

    // If no scenarios are selected or only projection is selected, default to projection + first scenario
    if (
      scenariosToCompare.length === 0 ||
      (scenariosToCompare.length === 1 &&
        scenariosToCompare[0].id === projectionData.id)
    ) {
      scenariosToCompare = [projectionData, scenariosData.scenarios[0]].filter(
        Boolean
      );
    }

    const newCompareData = scenariosToCompare.map((scenario) => ({
      title: scenario.name,
      scenarioId: scenario.id,
    }));

    setCompareScenarioData(newCompareData);

    const newIds = scenariosToCompare.map((s) => s.id).join(',');
    if (newIds !== ids.join(',')) {
      navigate(`${ROUTE_PATH.COMPARE_SCENARIO}?ids=${newIds}`, { replace: true });
    }
  }, [projectionData, scenariosData, ids, navigate]);

  return {
    compareScenarioData,
    setCompareScenarioData,
    formattedTitles,
    fetchCompareScenarioData,
    comparedData,
    projectionData,
  };
};
