import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { STORAGE_KEYS } from '../../helper';
import Navbar from '../Navbar/Navbar';
import { fetchUserInfo } from '../../features/user/userThunk';
import { ROUTE_PATH } from '../../helper/constant';

function ProtectedRoute() {
  const token = localStorage.getItem(STORAGE_KEYS.token);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !token ? <Navigate to={ROUTE_PATH.LOGIN} replace /> :
    <div className={`p-4 w-screen h-screen relative overflow-hidden  ${location.pathname === ROUTE_PATH.HOME ? 'bg-cover bg-center bg-no-repeat bg-auth-panel' : 'bg-light-gray'}`}>
      <Navbar transparent={location.pathname === ROUTE_PATH.HOME} />
      <div id='mainPanel'>
        <Outlet />
      </div>
    </div>;
}

export default ProtectedRoute
