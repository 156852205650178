import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Progress } from 'flowbite-react';
import { FormSubmit } from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import {
  toastMessage,
  updatePensionApplicationFormStep,
} from '../../helper/function';
import {
  MARITAL_STATUS_OPTIONS,
  MAX_DOCUMENT_UPLOAD_SIZE,
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { setDataForChatBot } from '../../features/chatbot/chatbot';
import { STORAGE_KEYS } from '../../helper';

const progressBarTheme = {
  color: {
    primary: 'bg-primary',
  },
};

const DocumentCenter = ({ tabHandler }) => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.user);
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const { screenName, updatedDataFromChatBot } = useSelector(
    (state) => state.chatbot
  );

  const [allFiles, setAllFiles] = useState([]);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },

    onDrop: async (acceptedFiles) => {
      for (let file of acceptedFiles) {
        if (file.size > MAX_DOCUMENT_UPLOAD_SIZE) {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            'File size exceeds the 2MB limit.'
          );
          return;
        }
      }
      const newDocuments = acceptedFiles.map((file) => {
        return {
          name: file.name,
          size: 'Uploading...',
        };
      });

      setAllFiles([...newDocuments, ...allFiles]);
      const promises = acceptedFiles?.map((file, index) => {
        const formData = new FormData();
        formData.append('document', file);

        return axiosInstance.post(
          `retirement/form/${pensionApplicationFormData?.id}/documents`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setAllFiles((prev) => {
                const updatedProgress = [...prev];
                updatedProgress[index].uploadingProgress = progress;
                return updatedProgress;
              });
            },
          }
        );
      });

      try {
        await Promise.all(promises);
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        getDocumentData();
      }
    },
  });

  const handleNextButton = async () => {
    try {
      const isSingle =
        userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.SINGLE;
      const requiredDocuments = isSingle ? 1 : 3;

      if (allFiles.length < requiredDocuments) {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          `Please upload all required documents, at least ${requiredDocuments} document${
            requiredDocuments > 1 ? 's' : ''
          } are required.`
        );
      } else {
        const updatedPensionApplicationFormData = await axiosInstance.get(
          'retirement/form/get'
        );
        const lastUpdatedStep =
          updatedPensionApplicationFormData?.data?.data?.step;
        if (
          pensionApplicationFormData?.step ===
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER ||
          lastUpdatedStep ===
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER
        ) {
          await updatePensionApplicationFormStep({
            step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT,
          });
        }
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
        );
      }
    } catch (error) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while going to next step from document center'
      );

      console.error(
        'Error while going to next step from document center',
        error
      );
    }
  };

  const getDocumentData = async () => {
    try {
      const response = await axiosInstance.get('retirement/form/get/data', {
        params: {
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER,
        },
      });
      setAllFiles(response?.data?.data);
    } catch (error) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while getting document data'
      );
      console.error('Error while getting document data', error);
    }
  };

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER
    ) {
      getDocumentData();
      dispatch(
        setDataForChatBot({ sender: localStorage.getItem(STORAGE_KEYS.token) })
      );
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER &&
      !updatedDataFromChatBot?.is_error_occur &&
      updatedDataFromChatBot?.want_to_upload_document
    ) {
      // open file upload dialog
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER &&
      !updatedDataFromChatBot?.is_error_occur &&
      (updatedDataFromChatBot?.is_next ||
        updatedDataFromChatBot?.want_submit ||
        updatedDataFromChatBot?.is_previous)
    ) {
      if (updatedDataFromChatBot?.is_previous) {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO
        );
      } else {
        handleNextButton();
      }
    }
  }, [updatedDataFromChatBot]);

  const documentDeleteHandler = async (id) => {
    try {
      await axiosInstance.delete(
        `retirement/form/${pensionApplicationFormData?.id}/documents/${id}`
      );
      toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, 'Document deleted successfully');
      await getDocumentData();
    } catch (error) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Error while deleting document'
      );
      console.error('Error while deleting document', error);
    }
  };
  const files = allFiles.map((file) => {
    return (
      <div className='flex w-full items-center gap-2' key={file.id}>
        <div className='p-2.5 w-fit bg-light-gray rounded-lmd flex items-center gap-2 flex-auto'>
          <div className='w-2/5 flex items-center gap-2'>
            <img src='/assets/images/icons/file.svg' alt='File' />
            <p className='text-lsm font-normal text-b10'>
              {file.path || file.name}
            </p>
          </div>
          <div className='w-4/5'>
            {file?.uploadingProgress ? (
              <Progress
                progress={file?.uploadingProgress}
                theme={progressBarTheme}
                color='primary'
              />
            ) : null}
          </div>
        </div>

        <button
          className='text-black text-lsm font-bold'
          onClick={() => {
            documentDeleteHandler(file.id);
          }}
        >
          <img src='/assets/images/icons/close.svg' alt='Cancel' />
        </button>
      </div>
    );
  });
  return (
    <>
      <div className='relative  h-full flex flex-col justify-between'>
        <div className='pb-2 sticky top-0  bg-light-gray'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            Document Centre
          </h2>
        </div>
        <div className='h-[calc(100%_-_50px)] overflow-auto'>
          <div className='p-4 rounded-2xl bg-white shadow-double flex flex-col gap-10'>
            <div className='flex flex-col gap-6'>
              <div
                {...getRootProps()}
                className='py-10 relative bg-bord-g40 min-h-60 w-full rounded-md flex flex-col justify-center items-center cursor-pointer'
              >
                {isDragActive && (
                  <div className='absolute pointer-event-none inset-4 border-4 border-dashed border-neutral-gray/50 rounded-xl'></div>
                )}

                <input {...getInputProps()} />
                <div className='w-40 opacity-60'>
                  <img src='/assets/images/icons/img-upload.svg' alt='upload' />
                </div>
                <p className='mt-4 text-base font-semibold text-neutral-gray'>
                  Drop files here
                </p>
              </div>
              <div className='flex w-full justify-end'>
                <button
                  id='uploadButton'
                  className='primary-btn'
                  type='button'
                  onClick={open}
                >
                  Upload Document
                </button>
              </div>
            </div>

            {files.length > 0 && (
              <div className='flex flex-col gap-6'>
                <div className='pb-2  border-b border-bord-g10 flex justify-between items-center'>
                  <h3 className='text-base font-semibold text-b10'>
                    Uploaded File(s)
                  </h3>
                </div>
                <div className='flex flex-col gap-4'>{files}</div>
              </div>
            )}

            <div className='flex flex-col gap-6'>
              <div className='pb-2  border-b border-bord-g10 flex justify-between items-center'>
                <h3 className='text-base font-semibold text-b10'>
                  Required Documents
                </h3>
              </div>
              <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-4'>
                  <img
                    src='/assets/images/icons/check-circle.svg'
                    alt='Check'
                  />
                  <p className='text-lsm font-normal text-b10'>
                    a photocopy of your birth certificate, passport or
                    driver&apos;s license
                  </p>
                </div>
                {userInfo?.data?.marital_status !==
                  MARITAL_STATUS_OPTIONS.SINGLE && (
                  <>
                    <div className='flex items-center gap-4'>
                      <img
                        src='/assets/images/icons/check-circle.svg'
                        alt='Check'
                      />
                      <p className='text-lsm font-normal text-b10'>
                        a photocopy of your marriage certificate
                      </p>
                    </div>
                    <div className='flex items-center gap-4'>
                      <img
                        src='/assets/images/icons/check-circle.svg'
                        alt='Check'
                      />
                      <p className='text-lsm font-normal text-b10'>
                        a photocopy of your spouse&apos;s birth certificate,
                        passport or driver&apos;s license
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom buttons */}
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
};
DocumentCenter.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};

export default DocumentCenter;
