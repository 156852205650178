import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import navbarReducer from './features/navbar/navbarSlice';
import chatbotReducer from './features/chatbot/chatbot';
import pensionApplicationFormReducer from './features/pensionApplicationForm/pensionApplicationFormSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    navbar: navbarReducer,
    chatbot: chatbotReducer,
    pensionApplicationForm: pensionApplicationFormReducer,
  },
});

export default store;