import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

function FormInput(
  {
    type,
    placeholder,
    icon,
    errorFieldName,
    errors,
    parentClass,
    className,
    ...rest
  },
  ref
) {
  return (
    <>
      <div className={`relative w-full ${parentClass}`}>
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          className={`${
            icon ? 'p-3 pl-12' : 'p-3'
          }  block w-full text-lsm font-normal text-black border border-black rounded-lg ring-0 placeholder:text-neutral-gray focus:ring-0 focus:ring-opacity-0 focus:border-black ${className}`}
          {...rest}
        />

        {icon && (
          <img
            className='absolute inset-0 left-3 top-1/2 -translate-y-1/2'
            src={icon}
            alt='Mail'
          />
        )}
      </div>
      {errors?.[errorFieldName] && (
        <p className='text-red-600 text-xxs mt-1'>
          {errors?.[errorFieldName].message}
        </p>
      )}
    </>
  );
}

const Input = forwardRef(FormInput);
Input.displayName = 'Input';
FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  parentClass: PropTypes.string,
  className: PropTypes.string,
  errorFieldName: PropTypes.string,
  errors: PropTypes.object,
};

FormInput.defaultProps = {
  errorFieldName: '',
  errors: {},
};

export default Input;
