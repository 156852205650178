import { toast } from 'react-toastify';
import axiosInstance from '../api/axiosInstance';
import {
  AI_CHATBOT_EVENT_TYPE_LABEL,
  FIELDS_UPDATE_EFFECT_CSS,
  ROUTE_PATH,
  TOAST_MESSAGE_TYPE,
} from './constant';
import { zipCodeRegex } from '.';

const toastMessage = (type, message) => {
  switch (type) {
    case TOAST_MESSAGE_TYPE.SUCCESS:
      return toast.success(
        <>
          <p className='font-bold	text-toast-success'>Success Message</p>
          <p className='text-neutral-gray'>{message}</p>
        </>
      );
    case TOAST_MESSAGE_TYPE.ERROR:
      return toast.error(
        <>
          <p className='font-bold	text-toast-error'>Error Message</p>
          <p className='text-neutral-gray'>{message}</p>
        </>
      );
    default:
      return toast.info(
        <>
          <p className='font-bold	text-toast-info'>Info Message</p>
          <p className='text-neutral-gray'>{message}</p>
        </>
      );
  }
};

const getExactValueFromEnum = (value, array) => {
  const exactValue = value?.trim()?.toLowerCase();
  return array.includes(exactValue) ? exactValue : '';
};

const debounce = (func, wait) => {
  let timeout;
  return {
    call: function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    },
    cancel: function () {
      clearTimeout(timeout);
    },
  };
};

// Function to convert base64 to File object
function base64ToFile(base64String, filename, mimeType) {
  // Convert base64 to Blob
  const byteCharacters = atob(base64String.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  // Create File object
  return new File([blob], filename, {
    type: mimeType,
    lastModified: new Date().getTime(),
  });
}

const updatePensionApplicationFormStep = async (data) => {
  try {
    await axiosInstance.put('retirement/form/update', data);
  } catch (error) {
    console.error('error while update pension application form step', error);
  }
};

function validatePostalCode(code) {
  // Remove any existing spaces and convert to uppercase
  code = code.replace(/\s/g, '').toUpperCase();

  // For Canadian postal codes, insert a space after the third character
  if (code.length === 6 && /^[A-Z]/.test(code)) {
    code = code.slice(0, 3) + ' ' + code.slice(3);
  }

  if (zipCodeRegex.test(code)) {
    return code; // Return the formatted code
  } else {
    return ''; // Invalid code
  }
}

function getFullName(firstName, middleName, lastName) {
  return `${firstName}${middleName ? ' ' + middleName : ''}${
    lastName ? ' ' + lastName : ''
  }`;
}

const parseDateDDMMYYYYToDateObject = (dateString, noOfDaysToAdd = 0) => {
  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() + noOfDaysToAdd);
  return date;
};

const isDate1LessThanDate2OrEqual = (date1, date2, isAllowEqual = false) => {
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(year, month - 1, day);
  };

  const dateObj1 = parseDate(date1);
  const dateObj2 = parseDate(date2);

  if (isAllowEqual) {
    return dateObj1 <= dateObj2;
  }
  return dateObj1 < dateObj2;
};

const eventTypeFromPathName = (pathname) => {
  switch (pathname) {
    case ROUTE_PATH.HOME:
      return AI_CHATBOT_EVENT_TYPE_LABEL.DASHBOARD;
    case ROUTE_PATH.MY_PROFILE:
      return AI_CHATBOT_EVENT_TYPE_LABEL.PROFILE;
    case ROUTE_PATH.DOCUMENT:
      return AI_CHATBOT_EVENT_TYPE_LABEL.DOCUMENTS;
    case ROUTE_PATH.PENSION_CALCULATOR:
    case ROUTE_PATH.CREATE_SCENARIO:
    case ROUTE_PATH.EDIT_SCENARIO:
    case ROUTE_PATH.COMPARE_SCENARIO:
      return AI_CHATBOT_EVENT_TYPE_LABEL.PENSION_CALCULATOR;
    case ROUTE_PATH.SERVICE_RECORD:
      return AI_CHATBOT_EVENT_TYPE_LABEL.SERVICE_RECORD;
    case ROUTE_PATH.BUYBACK_CALCULATOR:
      return AI_CHATBOT_EVENT_TYPE_LABEL.BUYBACK_CALCULATOR;
    case ROUTE_PATH.APPLICATION_FORM:
      return AI_CHATBOT_EVENT_TYPE_LABEL.RETIREMENT_APPLICATION;

    default:
      return 'Miscellaneous' + ' ' + pathname;
  }
};

const handleCSSEffect = ({stateVariableArray = [], fieldName, value = ''}) => {
  if (
    stateVariableArray.some(
      (element) =>
        element.fieldName === fieldName &&
        (!value || (value && element.newValue === value))
    )
  ) {
    return FIELDS_UPDATE_EFFECT_CSS;
  }
  return '';
};


export {
  toastMessage,
  getExactValueFromEnum,
  debounce,
  base64ToFile,
  updatePensionApplicationFormStep,
  validatePostalCode,
  getFullName,
  parseDateDDMMYYYYToDateObject,
  isDate1LessThanDate2OrEqual,
  eventTypeFromPathName,
  handleCSSEffect
};
