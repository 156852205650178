import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import { emailRegex } from '../../helper';
import { TOAST_MESSAGE_TYPE } from '../../helper/constant';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const forgotPasswordHandler = async (data) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post('password/forgot', {
        email: data?.email.trim(),
      });
      if(response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('error while forgot password', error);
      toastMessage(TOAST_MESSAGE_TYPE.ERROR, error?.response?.data?.meta?.message)
    }
  };

  return (
    <div className="bg-cover bg-center bg-no-repeat bg-auth-panel h-screen w-screen">
    <div className="p-4 h-screen w-screen flex items-center justify-center">
      <div className="p-8 bg-black bg-opacity-60 backdrop-blur-lg rounded-4xl max-w-md border border-white ">
        <form noValidate onSubmit={handleSubmit(forgotPasswordHandler)}>
          <div className='flex flex-col items-center gap-7'>
            <div>
              <img src="/assets/images/pension-id-logo.svg" alt="PENSION ID" />
            </div>

            <p className="text-white text-xl font-semibold">
              Forgot Password ?
            </p>

            <div className="w-full">
              {/* Enter email to get link */}
              <div className="mb-6">
                <label className="block text-white text-lsm font-normal mb-2">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Your email"
                  {...register('email', {
                    required: 'Email is required.',
                    pattern: {
                      value: emailRegex,
                      message: 'Email is not valid.',
                    },
                  })}
                  className="w-full p-3 bg-white border border-white text-lsm font-normal text-black focus:outline-none focus:border-primary duration-500 rounded-lg min-w-96 placeholder:text-neutral-gray"
                />
                {errors.email && <p className="text-red-600 text-xxs mt-1">{errors.email.message}</p>}
              </div>
            </div>

            <button disabled={isLoading} type="submit" className="font-ibm py-3 px-6 rounded-80x bg-primary text-white text-lsm font-semibold min-w-32 text-center" >
              Send Link
            </button>
          </div>
        </form>
      </div >
    </div >
  </div >
  );
};

export default ForgotPassword;
