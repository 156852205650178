import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Label, Radio } from 'flowbite-react';
import PropTypes from 'prop-types';
import { FormSubmit } from '../Generic';
import { toastMessage } from '../../helper/function';
import axiosInstance from '../../api/axiosInstance';
import {
  LIFETIME_GUARANTEE_PERIOD_OPTIONS,
  ROUTE_PATH,
  FLAG_Y_N,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { radioButtonTheme, scenarioPropType } from '../../helper';

const PensionPackage = ({ tabHandler, scenarioData, activeTab }) => {
  const navigate = useNavigate();
  const [survivorPensionData, setSurvivorPensionData] = useState([]);
  const [activePackage, setActivePackage] = useState(1);
  const [pensionGuaranteePeriod, setPensionGuaranteePeriod] = useState(
    LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
  );

  const handleCalculate = async () => {
    try {
      if (scenarioData?.id && survivorPensionData?.length > 0) {
        const survivor_pension_percentage =
          survivorPensionData[activePackage]?.percentage;
        const response = await axiosInstance.put(
          `scenarios/${scenarioData?.id}/last-step`,
          {
            survivor_pension_percentage,
            pension_guarantee_period: pensionGuaranteePeriod,
            step: 4,
          }
        );
        if (response?.status === 200 || response?.status === 201) {
          const responseData = await axiosInstance.get(
            `scenarios/${scenarioData?.id}/calculate`
          );
          if (responseData?.status === 200 || responseData?.status === 201) {
            toastMessage(
              TOAST_MESSAGE_TYPE.SUCCESS,
              responseData?.data?.meta?.message ||
                'Calculation done successfully'
            );
            navigate(ROUTE_PATH.PENSION_CALCULATOR);
          }
        }
      } else {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Something went wrong');
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  const fetchSurvivorPensionData = async () => {
    try {
      if (!scenarioData?.id || activeTab !== 3) return;
      const response = await axiosInstance.get(
        `scenarios/${scenarioData?.id}/get-survivor-pension-amount`
      );
      if (response?.data?.data?.length > 0) {
        setSurvivorPensionData(response.data.data);
        if (scenarioData?.is_calculated === FLAG_Y_N.YES) {
          const resultIndex = response.data.data?.findIndex(
            (data) =>
              data?.percentage ===
              scenarioData?.less_reduction?.survivor_pension_percentage
          );
          setActivePackage(resultIndex === -1 ? 0 : resultIndex);
        }
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    if (scenarioData?.is_small_pension_eligible === 'y') return;
    fetchSurvivorPensionData();
    if (scenarioData?.is_calculated === FLAG_Y_N.YES) {
      setPensionGuaranteePeriod(
        !scenarioData?.less_reduction?.pension_guarantee_period
          ? LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
          : LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS
      );
    }
  }, [scenarioData?.id, activeTab]);

  return (
    <>
      <div className="bg-light-gray p-4 rounded-xl">
        <h2 className="mb-6 text-lsm font-normal text-b10 font-semibold font-ibm">
          Survivor Pension
        </h2>
        {scenarioData?.is_small_pension_eligible === 'y' ? (
          <div>
            {`You will not receive a monthly or annual pension. Instead, you will receive a lump sum amount of ${
              scenarioData?.small_pension_lump_sum_amount || '$0.00'
            } because you are eligible for a small pension.`}
          </div>
        ) : (
          <>
            <div className="mb-6 grid grid-cols-4 gap-2.5">
              {survivorPensionData.map((survivorPension, index) => (
                <div
                  key={index}
                  className={`px-3.5 py-2 flex flex-col items-center gap-6 border rounded-2xl duration-500 ${
                    activePackage === index
                      ? 'bg-white border-primary shadow-double'
                      : 'border-transparent'
                  }`}
                >
                  <FormSubmit
                    onClick={() => setActivePackage(index)}
                    className={
                      activePackage === index
                        ? 'primary-btn border border-transparent'
                        : 'secondary-btn-blue'
                    }
                    buttonText={survivorPension.percentage + '%'}
                  />
                  <div className="flex flex-col gap-4">
                    <p className="text-xxs font-medium text-neutral-gray">
                      {survivorPension.annual} Annually
                    </p>
                    <p className="text-xxs font-medium text-neutral-gray">
                      {survivorPension.monthly} Monthly
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="pt-6 border-t border-bord-g10">
              <h2 className="mb-6 text-lsm font-normal text-b10 font-semibold font-ibm">
                Lifetime Guaranteed Option
              </h2>
              <div className="flex gap-6 items-center">
                <div className="flex items-center gap-3">
                  <Radio
                    theme={radioButtonTheme}
                    id="none"
                    name="survivor-pension"
                    value={LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE}
                    onChange={(e) => setPensionGuaranteePeriod(e.target.value)}
                    checked={
                      pensionGuaranteePeriod ===
                      LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
                    }
                  />
                  <Label
                    className="text-lsm font-normal text-b10"
                    htmlFor="none"
                  >
                    None
                  </Label>
                </div>
                <div className="flex items-center gap-3">
                  <Radio
                    theme={radioButtonTheme}
                    id="10-years"
                    name="survivor-pension"
                    value={LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS}
                    onChange={(e) => setPensionGuaranteePeriod(e.target.value)}
                    checked={
                      pensionGuaranteePeriod ===
                      LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS
                    }
                  />
                  <Label
                    className="text-lsm font-normal text-b10"
                    htmlFor="10-years"
                  >
                    10 Years
                  </Label>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between ">
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() => tabHandler(2)}
        />
        <FormSubmit
          buttonText={'Calculate'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={() => handleCalculate()}
        />
      </div>
    </>
  );
};

PensionPackage.propTypes = {
  tabHandler: PropTypes.func,
  scenarioData: scenarioPropType,
  activeTab: PropTypes.number,
};

PensionPackage.defaultProps = {
  scenarioData: undefined,
};

export default PensionPackage;
