import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CustomDatePicker, FormInput, FormSubmit } from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import { nameRegex, percentageRegex, zipCodeRegex } from '../../helper';
import { handleCSSEffect, toastMessage, validatePostalCode } from '../../helper/function';
import {
  FIELDS_UPDATE_EFFECT_TIMEOUT,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { setDataForChatBot } from '../../features/chatbot/chatbot';

function BeneficiaryForm({
  openBeneficiaryForm,
  setOpenBeneficiaryForm,
  getBeneficiariesInfo,
  editBeneficiaryData,
  setEditBeneficiaryData,
}) {
  const {
    screenName,
    updatedDataFromChatBot,
    wantToUpdateData,
    dataForChatBot,
  } = useSelector((state) => state.chatbot);

  const [aiUpdatedDataEffectFields, setAiUpdatedDataEffectFields] = useState(
    []
  );
  
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      percentage: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: '',
      address_line_1: '',
      address_line_2: '',
      apartment: '',
      city: '',
      state: '',
      zip_code: '',
    },
  });

  const formData = watch();

  const onSubmit = async (data) => {
    try {
      if (editBeneficiaryData && Object.keys(editBeneficiaryData)?.length > 0) {
        const formattedZipCode = validatePostalCode(data.zip_code);
        if (!formattedZipCode) {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            'Please enter a valid ZIP code'
          );
          return;
        }
        const response = await axiosInstance.put(
          `profile/beneficiaries/${editBeneficiaryData?.id}`,
          { ...data, zip_code: formattedZipCode }
        );
        if (response?.data?.meta?.message) {
          toastMessage(
            TOAST_MESSAGE_TYPE.SUCCESS,
            response?.data?.meta?.message
          );
        }
      } else {
        const response = await axiosInstance.post(
          'profile/beneficiaries',
          data
        );
        if (response?.data?.meta?.message) {
          toastMessage(
            TOAST_MESSAGE_TYPE.SUCCESS,
            response?.data?.meta?.message
          );
        }
      }
    } catch (error) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while adding or updating beneficiary'
      );

      console.error('error while adding or updating beneficiary', error);
    } finally {
      setOpenBeneficiaryForm(false);
      setEditBeneficiaryData(null);
      getBeneficiariesInfo();
    }
  };

  useEffect(() => {
    if (editBeneficiaryData && Object.keys(editBeneficiaryData)?.length > 0) {
      setValue('percentage', editBeneficiaryData?.percentage);
      setValue('first_name', editBeneficiaryData?.first_name);
      setValue('middle_name', editBeneficiaryData?.middle_name);
      setValue('last_name', editBeneficiaryData?.last_name);
      setValue('date_of_birth', editBeneficiaryData?.date_of_birth);
      setValue('address_line_1', editBeneficiaryData?.address_line_1);
      setValue('address_line_2', editBeneficiaryData?.address_line_2);
      setValue('apartment', editBeneficiaryData?.apartment);
      setValue('city', editBeneficiaryData?.city);
      setValue('state', editBeneficiaryData?.state);
      setValue('zip_code', editBeneficiaryData?.zip_code);
    }
  }, [editBeneficiaryData]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit
    ) {
      const openFormData =
        updatedDataFromChatBot?.data?.beneficiary_information?.find(
          (item) => item.is_form_open
        );
        
      const updatedFields = [];
      if ((openFormData?.beneficiary_percentage || '') !== formData?.percentage) {
        updatedFields.push({
          fieldName: 'percentage',
          oldValue: formData?.percentage,
          newValue: openFormData?.beneficiary_percentage,
        });
      }
      if ((openFormData?.first_name || '') !== formData?.first_name) {
        updatedFields.push({
          fieldName: 'first_name',
          oldValue: formData?.first_name,
          newValue: openFormData?.first_name,
        });
      }
      if ((openFormData?.middle_name || '') !== formData?.middle_name) {
        updatedFields.push({
          fieldName: 'middle_name',
          oldValue: formData?.middle_name,
          newValue: openFormData?.middle_name,
        });
      }
      if ((openFormData?.last_name || '') !== formData?.last_name) {
        updatedFields.push({
          fieldName: 'last_name',
          oldValue: formData?.last_name,
          newValue: openFormData?.last_name,
        });
      }
      if ((openFormData?.dob || '') !== formData?.date_of_birth) {
        updatedFields.push({
          fieldName: 'date_of_birth',
          oldValue: formData?.date_of_birth,
          newValue: openFormData?.dob,
        });
      }
      if ((openFormData?.street || '') !== formData?.address_line_1) {
        updatedFields.push({
          fieldName: 'address_line_1',
          oldValue: formData?.address_line_1,
          newValue: openFormData?.street,
        });
      }
      if ((openFormData?.address_line_2 || '') !== formData?.address_line_2) {
        updatedFields.push({
          fieldName: 'address_line_2',
          oldValue: formData?.address_line_2,
          newValue: openFormData?.address_line_2,
        });
      }
      if ((openFormData?.apartment || '') !== formData?.apartment) {
        updatedFields.push({
          fieldName: 'apartment',
          oldValue: formData?.apartment,
          newValue: openFormData?.apartment,
        });
      }
      if ((openFormData?.city || '') !== formData?.city) {
        updatedFields.push({
          fieldName: 'city',
          oldValue: formData?.city,
          newValue: openFormData?.city,
        });
      }
      if ((openFormData?.state || '') !== formData?.state) {
        updatedFields.push({
          fieldName: 'state',
          oldValue: formData?.state,
          newValue: openFormData?.state,
        });
      }
      if ((openFormData?.zip || '') !== formData?.zip_code) {
        updatedFields.push({
          fieldName: 'zip_code',
          oldValue: formData?.zip_code,
          newValue: openFormData?.zip,
        });
      }
      setAiUpdatedDataEffectFields(updatedFields);

      setValue('percentage', openFormData?.beneficiary_percentage);
      setValue('first_name', openFormData?.first_name);
      setValue('middle_name', openFormData?.middle_name);
      setValue('last_name', openFormData?.last_name);
      setValue('date_of_birth', openFormData?.dob);
      setValue('address_line_1', openFormData?.street);
      setValue('address_line_2', openFormData?.address_line_2);
      setValue('apartment', openFormData?.apartment);
      setValue('city', openFormData?.city);
      setValue('state', openFormData?.state);
      setValue('zip_code', openFormData?.zip);
      const result = {
        ...dataForChatBot,
        node: 'beneficiary_information',
        beneficiary_information:
          dataForChatBot?.beneficiary_information?.map((item) => {
            if (item.id === openFormData?.id) {
              return openFormData;
            }
            return item;
          }) || [],
      };
      setDataForChatBot(result);
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES &&
      wantToUpdateData &&
      updatedDataFromChatBot?.want_submit
    ) {
      onSubmit(formData);
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  useEffect(() => {
    if (aiUpdatedDataEffectFields.length > 0) {
      setTimeout(() => {
        setAiUpdatedDataEffectFields([]);
      }, FIELDS_UPDATE_EFFECT_TIMEOUT);
    }
  }, [aiUpdatedDataEffectFields]);

  return (
    <div
      className={`${
        openBeneficiaryForm ? 'animate-inAnimation' : 'animate-outAnimation'
      } transition-all   p-4 border border-bord-g30 rounded-lg`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-6'>
          <div className='flex-grow flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Percentage
            </span>
            <div className='flex flex-col gap-4'>
              <Controller
                name='percentage'
                control={control}
                rules={{
                  required: 'Survivor Pension Percentage is required',
                  pattern: {
                    value: percentageRegex,
                    message: 'Survivor Pension Percentage is not valid.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    type='text'
                    placeholder='Enter Percentage'
                    className={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'percentage',
                    })}
                    errorFieldName='percentage'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                First Name
              </span>
              <Controller
                name='first_name'
                control={control}
                rules={{
                  required: 'First Name is required',
                  pattern: {
                    value: nameRegex,
                    message: 'First name is not valid.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    type='text'
                    placeholder='Enter First Name'
                    className={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'first_name',
                    })}
                    errorFieldName='first_name'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Middle Name
              </span>
              <Controller
                name='middle_name'
                control={control}
                rules={{
                  pattern: {
                    value: nameRegex,
                    message: 'Middle Name is not valid.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    type='text'
                    placeholder='Enter Middle Name'
                    className={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'middle_name',
                    })}
                    errorFieldName='middle_name'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Last Name
              </span>
              <Controller
                name='last_name'
                control={control}
                rules={{
                  required: 'Last Name is required',
                  pattern: {
                    value: nameRegex,
                    message: 'Last name is not valid.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    type='text'
                    placeholder='Enter Last Name'
                    className={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'last_name',
                    })}
                    errorFieldName='last_name'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Date of Birth
              </span>
              <Controller
                name='date_of_birth'
                control={control}
                rules={{ required: 'Date of Birth is required' }}
                render={({ field }) => (
                  <CustomDatePicker
                    value={field.value}
                    onChange={field.onChange}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    autoComplete='off'
                    placeholderText='DD/MM/YYYY'
                    maxDate={new Date()}
                    className={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'date_of_birth',
                    })}
                  />
                )}
              />
              {errors.date_of_birth && (
                <p className='text-red-600 text-xxs mt-1'>
                  {errors.date_of_birth.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Address Line
              </span>
              <div className='flex flex-col gap-4'>
                <Controller
                  name='address_line_1'
                  control={control}
                  rules={{
                    required: 'Address line 1 is required',
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter Address Line 1'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'address_line_1',
                      })}
                      errorFieldName='address_line_1'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='address_line_2'
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter Address Line 2'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'address_line_2',
                      })}
                      errorFieldName='address_line_2'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>

            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Apartment / Unit
              </span>
              <div className='flex flex-col gap-4'>
                <Controller
                  name='apartment'
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter Apartment / Unit'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'apartment',
                      })}
                      errorFieldName='apartment'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>

            <div className='w-full flex gap-4'>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  City
                </span>
                <Controller
                  name='city'
                  control={control}
                  rules={{
                    required: 'City is required',
                    pattern: {
                      value: nameRegex,
                      message: 'City is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter City'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'city',
                      })}
                      errorFieldName='city'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  State / Province
                </span>
                <Controller
                  name='state'
                  control={control}
                  rules={{
                    required: 'State is required',
                    pattern: {
                      value: nameRegex,
                      message: 'State is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter State'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'state',
                      })}
                      errorFieldName='state'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  ZIP / Postal code
                </span>
                <Controller
                  name='zip_code'
                  control={control}
                  rules={{
                    required: 'Zip code is required',
                    pattern: {
                      value: zipCodeRegex,
                      message: 'Zip code is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='number'
                      placeholder='Enter ZIP'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'zip_code',
                      })}
                      errorFieldName='zip_code'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-center gap-4 w-full justify-end'>
          <FormSubmit
            type='button'
            className='secondary-btn-blue'
            buttonText={'Cancel'}
            onClick={() => {
              setEditBeneficiaryData(null);
              setOpenBeneficiaryForm(false);
            }}
          />
          <FormSubmit type={'submit'} buttonText={'Save Changes'} />
        </div>
      </form>
    </div>
  );
}

BeneficiaryForm.propTypes = {
  openBeneficiaryForm: PropTypes.bool.isRequired,
  setOpenBeneficiaryForm: PropTypes.func.isRequired,
  getBeneficiariesInfo: PropTypes.func.isRequired,
  editBeneficiaryData: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      middle_name: PropTypes.string,
      last_name: PropTypes.string,
      date_of_birth: PropTypes.string,
      percentage: PropTypes.number,
      address_line_1: PropTypes.string,
      address_line_2: PropTypes.string,
      apartment: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      zip_code: PropTypes.string,
    }),
    PropTypes.instanceOf(null),
  ]),
  setEditBeneficiaryData: PropTypes.func.isRequired,
};
BeneficiaryForm.defaultProps = {
  editBeneficiaryData: {},
};

export default BeneficiaryForm;
