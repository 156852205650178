import React, { useState, useEffect, useRef } from 'react';
import { FileInput, Progress } from 'flowbite-react';
import Sidebar from '../../components/Sidebar/Sidebar';
import useResizeHeight from '../../hooks/useResizeHeight';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import ConfirmationModal from '../../components/Generic/ConfirmationModal';
import { FILE_FORMAT_ICONS, MAX_DOCUMENT_UPLOAD_SIZE, TOAST_MESSAGE_TYPE } from '../../helper/constant';

const progressBarTheme = {
  color: {
    primary: 'bg-primary',
  },
};

const Documents = () => {
  useResizeHeight('dashPanel');
  const personalFileInputRef = useRef(null);
  const annualFileInputRef = useRef(null);

  const [personalDocuments, setPersonalDocuments] = useState([]);
  const [annualDocuments, setAnnualDocuments] = useState([]);
  const [personalDocumentDeleteId, setPersonalDocumentDeleteId] =
    useState(null);
  const [annualDocumentDeleteId, setAnnualDocumentDeleteId] = useState(null);

  const handleFileChangePersonalDocuments = async (event) => {
    const files = Array.from(event.target.files);

    if (files.length === 0) {
      console.log('No files selected');
      return;
    }
    for (let file of files) {
      if (file.size > MAX_DOCUMENT_UPLOAD_SIZE) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR,'File size exceeds the 2MB limit.');
        event.target.value = '';
        return;
      }
    }

    const newDocuments = files.map((file) => {
      return {
        name: file.name,
        size: 'Uploading...',
      };
    });
    setPersonalDocuments([...newDocuments, ...personalDocuments]);
    const promises = files.map((file, index) => {
      const formData = new FormData();
      formData.append('document', file);
      formData.append('type', 'personal');

      return axiosInstance.post('profile/documents', formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPersonalDocuments((prevProgress) => {
            const updatedProgress = [...prevProgress];
            updatedProgress[index].uploadingProgress = progress;
            return updatedProgress;
          });
        },
      });
    });

    try {
      await Promise.all(promises);
      console.log('All files uploaded successfully');
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      fetchPersonalDocuments();
      event.target.value = null;
    }
  };

  const handleFileChangeAnnualStatement = async (event) => {
    const files = Array.from(event.target.files);

    if (files.length === 0) {
      console.log('No files selected');
      return;
    }
    for (let file of files) {
      if (file.size > MAX_DOCUMENT_UPLOAD_SIZE) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR,'File size exceeds the 2MB limit.');
        event.target.value = '';
        return;
      }
    }

    const newDocuments = files.map((file) => {
      return {
        name: file.name,
        size: 'Uploading...',
      };
    });
    setAnnualDocuments([...newDocuments, ...annualDocuments]);
    const promises = files.map((file, index) => {
      const formData = new FormData();
      formData.append('document', file);
      formData.append('type', 'annual_statement');

      return axiosInstance.post('profile/documents', formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setAnnualDocuments((prevProgress) => {
            const updatedProgress = [...prevProgress];
            updatedProgress[index].uploadingProgress = progress;
            return updatedProgress;
          });
        },
      });
    });

    try {
      await Promise.all(promises);
      console.log('All files uploaded successfully');
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      fetchPersonalDocuments();
      event.target.value = '';
    }
  };

  const handleDeletePersonalDocuments = async (id) => {
    try {
      const response = await axiosInstance.delete(`profile/documents/${id}`);
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response.data.meta.message);
      }
      fetchPersonalDocuments();
      setPersonalDocumentDeleteId(null);
    } catch (error) {
      console.error('Error while deleting personal document', error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Error deleting document'
      );
    }
  };

  const handleDeleteAnnualStatement = async (id) => {
    try {
      const response = await axiosInstance.delete(`profile/documents/${id}`);
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response.data.meta.message);
      }
      fetchPersonalDocuments();
      setAnnualDocumentDeleteId(null);
    } catch (error) {
      console.error('Error while deleting annual statement', error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Error deleting document'
      );
    }
  };

  const fetchPersonalDocuments = async () => {
    try {
      const response = await axiosInstance.get('profile/documents');
      if (response?.data?.data?.personal_documents) {
        setPersonalDocuments(response.data.data.personal_documents || []);
      }
      if (response?.data?.data?.annual_reports) {
        setAnnualDocuments(response.data.data.annual_reports || []);
      }
    } catch (error) {
      console.error('Error while fetching personal documents', error);
    }
  };

  useEffect(() => {
    fetchPersonalDocuments();
  }, []);

  return (
    <div className="mt-4 flex gap-2">
      <Sidebar />
      <div
        id="dashPanel"
        className="w-full p-4 bg-dashboard-panel bg-contain bg-bottom bg-no-repeat overflow-auto"
      >
        <div className="mb-6 p-4 bg-white rounded-2xl shadow-double">
          <div className="mb-4 py-2 border-b border-bord-g10 flex justify-between items-center">
            <h3 className="text-base font-semibold text-b10">
              Personal Documents
            </h3>

            <button
              onClick={() => personalFileInputRef.current.click()}
              className="flex items-center gap-2"
            >
              <span>
                <img
                  className="invert"
                  src="/assets/images/icons/plus.svg"
                  alt="Add"
                />
              </span>
              <span className="text-lsm font-semibold font-ibm">
                Upload File
              </span>
            </button>
            <FileInput
              ref={personalFileInputRef}
              className="hidden"
              id="personal-file-upload"
              onChange={handleFileChangePersonalDocuments}
              accept={['application/pdf', 'image/jpeg', 'image/png']}
              multiple
            />
          </div>

          <div className="max-h-60 overflow-auto">
            
            <table className="min-w-full relative">
              {personalDocuments?.length > 0 && (
                <thead className="bg-white sticky top-0">
                  <tr>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    >
                      File Size
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    >
                      Uploaded
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    ></th>
                  </tr>
                </thead>
              )}

              {personalDocuments.map((document) => (
                <tbody key={document?.id}>
                  <tr className="bg-light-gray ">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center gap-3">
                        <img
                          src={FILE_FORMAT_ICONS[document?.extension?.toLowerCase() || 'pdf']}
                          alt={document?.extension || 'pdf'}
                        />
                        <span className="text-xxs font-normal text-b10">
                          {document?.name}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-xxs font-normal text-b10">
                        {document?.size}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-xxs font-normal text-b10">
                        {document?.uploadingProgress ? (
                          <Progress
                            progress={document?.uploadingProgress || 0}
                            theme={progressBarTheme}
                            color="primary"
                          />
                        ) : (
                          document?.uploaded_at
                        )}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex w-full justify-end">
                        <div className="flex items-center gap-5">
                          <button onClick={() => window.open(document?.url, '_blank')}>
                            <img
                              src="/assets/images/icons/view.svg"
                              alt="view"
                            />
                          </button>
                          <button
                            onClick={() =>
                              setPersonalDocumentDeleteId(document?.id)
                            }
                          >
                            <img
                              src="/assets/images/icons/delete.svg"
                              alt="delete"
                            />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}

              <ConfirmationModal
                open={Boolean(personalDocumentDeleteId)}
                text="Are you sure you want to delete this document?"
                handleClose={() => setPersonalDocumentDeleteId(null)}
                handleSubmit={() =>
                  handleDeletePersonalDocuments(personalDocumentDeleteId)
                }
              />

              {!personalDocuments?.length && (
                <tbody className="bg-light-gray">
                  <tr>
                    <td colSpan="100%" className="px-6 py-4">
                      <div className="flex items-center justify-center">
                        <span className="text-xxs font-normal text-b10">
                          No personal document uploaded
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div className="p-4 bg-white rounded-2xl shadow-double">
          <div className="mb-4 py-2 border-b border-bord-g10 flex justify-between items-center">
            <h3 className="text-base font-semibold text-b10">
              Annual Statements
            </h3>
            <button
              onClick={() => annualFileInputRef.current.click()}
              className="flex items-center gap-2"
            >
              <span>
                <img
                  className="invert"
                  src="/assets/images/icons/plus.svg"
                  alt="Add"
                />
              </span>
              <span className="text-lsm font-semibold font-ibm">
                Upload File
              </span>
            </button>
            <FileInput
              ref={annualFileInputRef}
              className="hidden"
              id="personal-file-upload"
              onChange={handleFileChangeAnnualStatement}
              accept={['application/pdf', 'image/jpeg', 'image/png']}
              multiple
            />
          </div>

          <div className="max-h-60 overflow-auto">
            <table className="min-w-full relative">
              {annualDocuments?.length > 0 && (
                <thead className="bg-white sticky top-0">
                  <tr>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    >
                      File Size
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    >
                      Uploaded
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    ></th>
                  </tr>
                </thead>
              )}

          <tbody >
              {annualDocuments.map((document) => (
                  <tr className="bg-light-gray " key={document?.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center gap-3">
                      <img
                          src={FILE_FORMAT_ICONS[document?.extension?.toLowerCase() || 'pdf']}
                          alt={document?.extension || 'pdf'}
                        />
                        <span className="text-xxs font-normal text-b10">
                          {document?.name}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-xxs font-normal text-b10">
                        {document?.size}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-xxs font-normal text-b10">
                        {document?.uploaded_at}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex w-full justify-end">
                        <div className="flex items-center gap-5">
                          <button onClick={() => window.open(document?.url, '_blank')}>
                            <img
                              src="/assets/images/icons/view.svg"
                              alt="view"
                            />
                          </button>
                          <button
                            onClick={() =>
                              setAnnualDocumentDeleteId(document?.id)
                            }
                          >
                            <img
                              src="/assets/images/icons/delete.svg"
                              alt="delete"
                            />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
              ))}
              </tbody>

              <ConfirmationModal
                open={Boolean(annualDocumentDeleteId)}
                text="Are you sure you want to delete this document?"
                handleClose={() => setAnnualDocumentDeleteId(null)}
                handleSubmit={() =>
                  handleDeleteAnnualStatement(annualDocumentDeleteId)
                }
              />

              {!annualDocuments?.length && (
                <tbody className="bg-light-gray">
                  <tr>
                    <td colSpan="100%" className="px-6 py-4">
                      <div className="flex items-center justify-center">
                        <span className="text-xxs font-normal text-b10">
                          No annual statement uploaded
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
