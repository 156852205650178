import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { convertToDateString, formatDateDDMMYYYY } from '../../helper';

function CustomDatePicker({
  value,
  onChange,
  showMonthDropdown,
  showYearDropdown,
  scrollableYearDropdown,
  className,
  ...rest
}) {
  const selectedDate = value ? convertToDateString(value) : null;
  return (
    <DatePicker
      showIcon
      dateFormat='dd/MM/yyyy'
      isClearable
      selected={selectedDate}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      scrollableYearDropdown={scrollableYearDropdown}
      dropdownMode='select'
      className={`${className} !py-3 !pl-8 block w-full text-lsm font-normal text-black border border-black rounded-lg ring-0 placeholder:text-neutral-gray focus:ring-0 focus:ring-opacity-0 focus:border-black`}
      onChange={(date) => onChange(date ? formatDateDDMMYYYY(date) : null)}
      {...rest}
    />
  );
}

export default CustomDatePicker;
CustomDatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  scrollableYearDropdown: PropTypes.bool,
  className: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  value: '',
  onChange: () => {},
  showMonthDropdown: false,
  showYearDropdown: false,
  scrollableYearDropdown: false,
};
