import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormSubmit, FullScreenLoader } from '../Generic';
import {
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
} from '../../helper/constant';
import { updatePensionApplicationFormStep } from '../../helper/function';
import { useServiceRecord } from '../../hooks/useServiceRecord';
import PensionInfoSummaryCard from '../SummaryCard/PensionInfoSummaryCard';

const ServiceRecords = ({ tabHandler }) => {
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const { updatedDataFromChatBot, screenName } = useSelector(
    (state) => state.chatbot
  );
  const { serviceRecord, loading } = useServiceRecord();

  const handleNextButton = async () => {
    try {
      if (
        pensionApplicationFormData?.step ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
      )
        await updatePensionApplicationFormStep({
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE,
        });
    } catch (error) {
      console.error('error while udpate step', error);
    } finally {
      tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE);
    }
  };

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD &&
      updatedDataFromChatBot?.is_next
    ) {
      handleNextButton();
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD &&
      updatedDataFromChatBot?.is_previous
    ) {
      tabHandler(
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES
      );
    }
  }, [screenName, updatedDataFromChatBot]);

  return (
    <>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <div className='relative h-full flex flex-col justify-between'>
          <div className='pb-2'>
            <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
              Service Records
            </h2>
          </div>
          <div className='h-[calc(100%_-_50px)] overflow-auto flex flex-col gap-4'>
            <div className='flex gap-4'>
              <div className='w-2/3'>
                <PensionInfoSummaryCard
                  summaryData={serviceRecord?.data?.service_records_summery}
                  showLastServiceRecordDate={true}
                  fromApplicationForm={true}
                  headerClassName='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between'
                  contentClassName='flex flex-col gap-2'
                  titleClassName='text-lbase font-semibold text-b10'
                  dateClassName='text-lsm font-semibold text-b10'
                />
              </div>
              <div className='flex-1 p-4 bg-primary rounded-2xl shadow-heavy flex flex-col gap-2 items-center'>
                <div className='aspect-square w-10'>
                  <img src='/assets/images/icons/buy.svg' alt='Buy' />
                </div>
                <div>
                  <p className='text-md text-center text-white font-bold'>
                    Have you taken a leave?
                  </p>
                  <p className='text-xxs font-normal text-b10'>
                    A leave can affect both your retirement benefits and your
                    service. If you&apos;ve already taken a leave or are
                    planning to, you might be able to buyback the credit for it.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0'>
                <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                  <p className='text-lbase font-semibold text-blue-900'>
                    Service Details
                  </p>
                  <button className='flex items-center gap-2'>
                    <span className='block font-ibm text-lsm font-semibold text-black'>
                      Report issue
                    </span>
                    <img
                      src='/assets/images/icons/error-circle.svg'
                      alt='error'
                    />
                  </button>
                </div>

                <div className='flex flex-col'>
                  <div className=' overflow-x-auto sm:mx-0.5 lg:mx-0.5'>
                    <div className=' inline-block min-w-full sm:px-6 lg:px-8'>
                      <div className=''>
                        <table className='min-w-full relative'>
                          <thead className='bg-white sticky top-0'>
                            <tr>
                              <th
                                scope='col'
                                className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                              >
                                Year
                              </th>
                              <th
                                scope='col'
                                className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                              >
                                Type of Credit
                              </th>
                              <th
                                scope='col'
                                className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                              >
                                Qualifying years
                              </th>
                              <th
                                scope='col'
                                className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                              >
                                Annualized earnings($)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {serviceRecord?.data?.service_records?.map(
                              (record, idx) => {
                                return (
                                  <tr
                                    key={record?.id}
                                    className={
                                      idx % 2 === 0 ? 'bg-light-gray' : ''
                                    }
                                  >
                                    <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                      {record?.year}
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                      {record?.type_of_credit}
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                      {record?.qualifying_years}
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                      {record?.annualized_earnings}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            {/* <tr className='bg-light-gray '>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                2024
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Employment
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                0.500
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                95,500.00
                              </td>
                            </tr>
                            <tr className='bg-primary bg-opacity-50 '>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                2023
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                --
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                0
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal flex justify-between items-center w-full text-b10'>
                                0{' '}
                                <img
                                  className='w-6 h-6'
                                  src='/assets/images/icons/buy.svg'
                                  alt='buy'
                                />
                              </td>
                            </tr>
                            <tr className='bg-light-gray '>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                2022
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Employment
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                1.0000
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Not Available
                              </td>
                            </tr>
                            <tr className=''>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                2021
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Employment
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                1.0000
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Not Available
                              </td>
                            </tr>
                            <tr className='bg-light-gray'>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                2020
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Employment
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                1.0000
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Not Available
                              </td>
                            </tr>
                            <tr className=''>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                2019
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Employment
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                1.0000
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                Not Available
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='pt-4 px-2 border-t border-t-bord-g30'>
                  <div className='p-4 rounded-lg bg-neutral-light-gray'>
                    <p className='xxs font-normal text-neutral-gray'>
                      If your service record appears incomplete, it may be due
                      to the absence of your latest service information being
                      reported. Additionally, it might seem incomplete if
                      you&apos;ve previously received a credit refund, commuted
                      value, or transferred service out of the plan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* bottom buttons */}
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
};
ServiceRecords.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};
export default ServiceRecords;
