import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNavbarTitle } from '../features/navbar/navbarSlice';
import axiosInstance from '../api/axiosInstance';
import { TOAST_MESSAGE_TYPE } from '../helper/constant';
import { toastMessage } from '../helper/function';
import { STORAGE_KEYS } from '../helper';

function useScenario() {
  const dispatch = useDispatch();
  const [openProjectionDetails, setOpenProjectionDetails] = useState(false);
  const [scenariosData, setScenariosData] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [projectionData, setProjectionData] = useState({});

  const isScenarioSelected = selectedScenario !== null;
  const fetchScenarios = async () => {
    axiosInstance
      .get('scenarios')
      .then((response) => {
        if (response?.data?.data) {
          setScenariosData(response.data.data?.scenarios || []);
          setProjectionData(response.data.data?.projection || {});
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };
  const handleDeleteAllScenarios = async () => {
    axiosInstance
      .delete('scenarios/delete')
      .then((res) => {
        fetchScenarios();
        localStorage.removeItem(STORAGE_KEYS.selectedSceanrioId);
        setSelectedScenario(null);
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, res?.data?.meta?.message);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };
  useEffect(() => {
    dispatch(setNavbarTitle('Pension Calculator'));
    fetchScenarios();
  }, []);

  useEffect(() => {
    if (
      (localStorage.getItem(STORAGE_KEYS.selectedSceanrioId),
      scenariosData.length > 0)
    ) {
      setSelectedScenario(
        scenariosData?.find(
          (scenario) =>
            scenario?.id ===
            Number(localStorage.getItem(STORAGE_KEYS.selectedSceanrioId))
        ) || null
      );
    }
  }, [localStorage.getItem(STORAGE_KEYS.selectedSceanrioId), scenariosData]);

  return {
    openProjectionDetails,
    setOpenProjectionDetails,
    scenariosData,
    selectedScenario,
    setSelectedScenario,
    isScenarioSelected,
    fetchScenarios,
    handleDeleteAllScenarios,
    projectionData,
  };
}

export default useScenario;
