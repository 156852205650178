import React from 'react';
import PropTypes from 'prop-types';
import { scenarioPropType } from '../../helper';
import TabContent from './TabContent';

function ScenarioListingMoreDetail({
  openProjectionDetails,
  isScenarioSelected,
  selectedScenario,
  projectionData,
}) {
  return (
    <div
      className={`absolute inset-6 overflow-auto ${
        openProjectionDetails && 'translate-x-full'
      }   bg-light-gray border border-bord-g10 p-4 pl-8 rounded-2xl z-0 duration-500`}
    >
      <div className='px-4'>
        <TabContent
          isScenarioSelected={isScenarioSelected}
          selectedScenario={selectedScenario}
          projectionData={projectionData}
        />
      </div>
    </div>
  );
}

export default ScenarioListingMoreDetail;

ScenarioListingMoreDetail.propTypes = {
  isScenarioSelected: PropTypes.bool,
  openProjectionDetails: PropTypes.bool,
  selectedScenario: scenarioPropType,
  projectionData: scenarioPropType,
};

ScenarioListingMoreDetail.defaultProps = {
  isScenarioSelected: false,
  openProjectionDetails: false,
  selectedScenario: null,
};
