import React, { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../api/axiosInstance';
import { LIFETIME_GUARANTEE_PERIOD_OPTIONS, PENSION_APPLICATION_FORM_STEPS } from '../../../helper/constant';

const modalTheme = {
  content: {
    base: ' relative h-full w-full p-4 md:h-auto flex items-center justify-center max-w-[100%] px-20 ',
    inner:
      'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 w-full',
  },
};

const ElectionsViewModal = ({ open, text, handleClose }) => {
  const [electionsData, setElectionsData] = useState(null);
  const [loading, setLoading] = useState(true);

  const survivorPensionDetail = electionsData?.survivor_pension_percentage ? electionsData?.survivor_pension_values?.find(item => item.percentage === electionsData?.survivor_pension_percentage) : undefined;
  
  useEffect(() => {
    let didCancel = false;
    const fetchElectionsData = async () => {
      try {
        const response = await axiosInstance.get('retirement/form/get/data', {
          params: {
            step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS,
          },
        });
        if (!didCancel) {
          setElectionsData(response?.data?.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error while fetching elections data', error);
        if (!didCancel) {
          setLoading(false);
        }
      }
    };
    fetchElectionsData();
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Modal
      position='center'
      theme={modalTheme}
      show={open}
      popup
      // size='' to make the modal full screen
      size=''
      onClose={handleClose}
    >
      <Modal.Header>
        <h2 className='mt-[10px] ml-[20px] text-3xl font-semibold text-blue-900'>
          {text}
        </h2>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className='flex items-center justify-center w-full h-full'>
            <div className='inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-primary border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white'></div>
          </div>
        ) : (
          <div className='flex flex-col gap-8 items-center justify-center text-center w-full'>
            <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
              <div className='grid grid-cols-3 gap-6'>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Retirement Date
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${electionsData?.retirement_date}`}</p>
                </div>
              </div>
            </div>

            {survivorPensionDetail?.percentage && (
              <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
              <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                <p className='text-lbase font-semibold text-blue-900'>
                Survivor Pension
                </p>
              </div>
              <div className='grid grid-cols-3 gap-6'>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Percentage
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${survivorPensionDetail?.percentage}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Annually
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${survivorPensionDetail?.net?.annual}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Monthly
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${survivorPensionDetail?.net?.monthly}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                  Lifetime Guaranteed Option
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{` : ${electionsData?.lifetime_gaurentee_option} ${electionsData?.lifetime_gaurentee_option !== LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE ? 'years' : ''}`}</p>
                </div>
              </div>
            </div>
            )}

          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

ElectionsViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ElectionsViewModal;
