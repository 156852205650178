import axios from 'axios';
import { STORAGE_KEYS } from '../helper';
import { ROUTE_PATH } from '../helper/constant';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});


axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config, add headers, or perform other tasks
    const token = localStorage.getItem(STORAGE_KEYS.token);
    // If a token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log('interceptors request error', error)
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // If the response was successful, just forward it
    return response;
  },
  (error) => {
    // If the response had an error, you can handle it here
    if (error.response && error.response.status === 401) {
      // Handle authentication error here, for example, by redirecting to login page
      console.error('Authentication error, possibly due to expired token', error);
      localStorage.removeItem(STORAGE_KEYS.token); // remove the token
      window.location = ROUTE_PATH.LOGIN; // redirect to login page
    }

    // Always reject the Promise so that the calling code knows the request failed
    return Promise.reject(error);
  }
);



export default axiosInstance;
