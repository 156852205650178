import React, { useEffect, useState } from 'react';
import { Label, Radio } from 'flowbite-react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { CustomDatePicker, FormInput, FormSubmit } from '../Generic';
import useDelayUnmount from '../../hooks/useDelayUnmount';
import axiosInstance from '../../api/axiosInstance';
import {
  parseDateDDMMYYYYToDateObject,
  toastMessage,
} from '../../helper/function';
import {
  formatDateDDMMYYYY,
  percentageRegex,
  radioButtonTheme,
  scenarioPropType,
} from '../../helper';
import {
  DATE_DD_MM_YYYY_FORMAT_REGEX,
  FLAG_Y_N,
  SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';

const EmploymentForm = ({
  employmentEditData,
  fetchAllEmploymentDetails,
  scenarioDataId,
  setOpenEmploymentForm,
  setEmploymentEditData,
  openEmploymentForm,
  employmentDetails = [],
}) => {
  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      start_date: '',
      end_date: '',
      annual_salary: '',
      annual_increment_value: '',
      annual_increment_type:
        SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD,
    },
  });

  const watchAll = watch();
  const watchAnnualIncrementType = watchAll?.annual_increment_type;
  const watchStartDate = watchAll?.start_date;

  const onSubmit = async (data) => {
    try {
      if (employmentEditData?.id) {
        const response = await axiosInstance.put(
          `scenario/${scenarioDataId}/employements/${employmentEditData?.id}`,
          {
            ...data,
            annual_increment_type:
              watchAnnualIncrementType ===
              SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD
                ? undefined
                : watchAnnualIncrementType,
          }
        );
        if (response?.data?.data) {
          fetchAllEmploymentDetails();
          setOpenEmploymentForm(false);
          setEmploymentEditData({});
          reset();
        } else {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            response?.data?.meta?.message || 'Something went wrong'
          );
        }
      } else if (scenarioDataId) {
        const response = await axiosInstance.post(
          `scenario/${scenarioDataId}/employements`,
          {
            ...data,
            annual_increment_type:
              watchAnnualIncrementType ===
              SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD
                ? undefined
                : watchAnnualIncrementType,
          }
        );
        if (response?.data?.data) {
          fetchAllEmploymentDetails();
          setOpenEmploymentForm(false);
          reset();
        } else {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            response?.data?.meta?.message || 'Something went wrong'
          );
        }
      } else {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Something went wrong');
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    clearErrors('annual_increment_value');
    clearErrors('annual_salary');
    if(!employmentEditData?.id) {
      setValue('annual_increment_value', '');
      setValue('annual_salary', '');
    }
  }, [watchAnnualIncrementType]);

  useEffect(() => {
    if (employmentEditData?.id) {
      reset({
        name: employmentEditData?.name,
        start_date: employmentEditData?.start_date,
        end_date: employmentEditData?.end_date,
        annual_salary: employmentEditData?.annual_salary,
        annual_increment_type: employmentEditData?.annual_increment_type || SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD,
        annual_increment_value: employmentEditData?.annual_increment_value,
      });
    }
  }, [employmentEditData?.id]);

  useEffect(() => {
    if (!employmentEditData?.id && employmentDetails.length > 0) {
      const lastElement = employmentDetails[employmentDetails.length - 1];
      const lastEndDate = lastElement?.end_date
        ? parseDateDDMMYYYYToDateObject(lastElement?.end_date, 1)
        : null;
      setValue('start_date', formatDateDDMMYYYY(lastEndDate));
    }
  }, [employmentDetails]);

  return (
    <div
      className={`${
        openEmploymentForm ? 'animate-inAnimation' : 'animate-outAnimation'
      } duration-500 transition-all p-4 border border-primary rounded-xl`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-8'>
          <div className='grid grid-cols-2 gap-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Employment Name
              </span>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: 'Employment Name is required',
                }}
                render={({ field }) => (
                  <FormInput
                    type='text'
                    placeholder='Employment Name'
                    errorFieldName='name'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className='grid grid-cols-3 gap-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Employment Start Date*
              </span>
              <Controller
                name='start_date'
                control={control}
                rules={{
                  required: 'Start Date is required',
                  pattern: {
                    value: DATE_DD_MM_YYYY_FORMAT_REGEX,
                    message: 'Start Date must be in the format DD/MM/YYYY',
                  },
                }}
                render={({ field }) => (
                  <CustomDatePicker
                    value={field.value}
                    onChange={field.onChange}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    autoComplete='off'
                    placeholderText='DD/MM/YYYY'
                  />
                )}
              />
              {errors.start_date && (
                <p className='text-red-600 text-xxs mt-1'>
                  {errors.start_date.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Employment End Date*
              </span>
              <Controller
                name='end_date'
                control={control}
                rules={{
                  required: 'End Date is required',
                  pattern: {
                    value: DATE_DD_MM_YYYY_FORMAT_REGEX,
                    message: 'End Date must be in the format DD/MM/YYYY',
                  },
                }}
                render={({ field }) => (
                  <CustomDatePicker
                    value={field.value}
                    onChange={field.onChange}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    autoComplete='off'
                    placeholderText='DD/MM/YYYY'
                    minDate={
                      watchStartDate
                        ? parseDateDDMMYYYYToDateObject(watchStartDate, 1)
                        : null
                    }
                  />
                )}
              />
              {errors.end_date && (
                <p className='text-red-600 text-xxs mt-1'>
                  {errors.end_date.message}
                </p>
              )}
            </div>
          </div>
          <div className='grid grid-cols-1 gap-6'>
            <div className='flex flex-col gap-4'>
              <div className='flex gap-6 items-center'>
                <div className='flex items-center gap-3'>
                  <Radio
                    theme={radioButtonTheme}
                    id={
                      SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD
                    }
                    {...register('annual_increment_type', {
                      required: 'Annual Increment is required',
                    })}
                    value={
                      SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD
                    }
                  />
                  <Label
                    className='text-lsm font-normal text-b10'
                    htmlFor={
                      SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD
                    }
                  >
                    Overall Salary For Given Period
                  </Label>
                </div>
                <div className='flex items-center gap-3'>
                  <Radio
                    theme={radioButtonTheme}
                    id={SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE}
                    {...register('annual_increment_type', {
                      required: 'Annual Increment is required',
                    })}
                    value={SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE}
                  />
                  <Label
                    className='text-lsm font-normal text-b10'
                    htmlFor={SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE}
                  >
                    Annually Increment By Percentage
                  </Label>
                </div>
                <div className='flex items-center gap-3'>
                  <Radio
                    theme={radioButtonTheme}
                    id={SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.FIXED_AMOUNT}
                    {...register('annual_increment_type', {
                      required: 'Annual Increment is required',
                    })}
                    value={SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.FIXED_AMOUNT}
                  />
                  <Label
                    className='text-lsm font-normal text-b10'
                    htmlFor={SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.FIXED_AMOUNT}
                  >
                    Annually Increment By Fixed Amount
                  </Label>
                </div>
              </div>
              {errors.annual_increment_type && (
                <p className='text-red-600 text-xxs mt-1'>
                  {errors.annual_increment_type.message}
                </p>
              )}
            </div>
          </div>
          <div className='grid grid-cols-3 gap-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                {watchAnnualIncrementType ===
                SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD
                  ? 'Overall Salary Amount'
                  : 'Annual Salary'}
              </span>
              <Controller
                name='annual_salary'
                control={control}
                rules={{
                  required: watchAnnualIncrementType ===
                  SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD ? 'Overall Salary Amount is required' : 'Annual Salary is required',
                  pattern: {
                    value: percentageRegex,
                    message: watchAnnualIncrementType ===
                  SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.OVERALL_GIVEN_PERIOD ? 'Overall Salary Amount is not valid.' : 'Annual Salary is not valid.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    type='number'
                    placeholder='Amount'
                    errorFieldName='annual_salary'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            {(watchAnnualIncrementType ===
              SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE ||
              watchAnnualIncrementType ===
                SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.FIXED_AMOUNT) && (
              <div className='flex flex-col gap-4'>
                <p className='text-lsm font-normal text-b10'>
                  {watchAnnualIncrementType ===
                  SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE
                    ? 'Annually Increment Percentage'
                    : 'Annually Increment Amount'}
                </p>
                <Controller
                  name='annual_increment_value'
                  control={control}
                  rules={{
                    required: `${
                      watchAnnualIncrementType ===
                      SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE
                        ? 'Percentage'
                        : 'Amount'
                    } is required`,
                    pattern: {
                      value: percentageRegex,
                      message: `${
                        watchAnnualIncrementType ===
                        SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE
                          ? 'Percentage'
                          : 'Amount'
                      } is not valid.`,
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='number'
                      placeholder={`${
                        watchAnnualIncrementType ===
                        SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE
                          ? 'Percentage'
                          : 'Amount'
                      }`}
                      errorFieldName='annual_increment_value'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className='flex gap-2 justify-end'>
            <FormSubmit
              onClick={(e) => {
                e.preventDefault();
                setOpenEmploymentForm(false);
                setEmploymentEditData({});
              }}
              className={'secondary-btn-blue border-transparent'}
              buttonText={'Cancel'}
            />
            <FormSubmit
              className='secondary-btn-blue'
              buttonText='Save'
              type='submit'
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const EmploymentDetails = ({ tabHandler, scenarioData }) => {
  const [openEmploymentForm, setOpenEmploymentForm] = useState(false);
  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [employmentEditData, setEmploymentEditData] = useState({});

  const showEmploymentForm = useDelayUnmount(openEmploymentForm, 250);

  const fetchAllEmploymentDetails = async () => {
    try {
      if (!scenarioData?.id) return;
      const response = await axiosInstance.get(
        `scenario/${scenarioData?.id}/employements`
      );
      if (response?.data?.data?.length > 0) {
        setEmploymentDetails(response?.data?.data);
      } else if (response?.data?.data?.length === 0) {
        setOpenEmploymentForm(true);
      } else {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          response?.data?.meta?.message || 'Something went wrong'
        );
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  const handleNextStep = async () => {
    try {
      /* if (employmentDetails.length === 0) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please add at least one employment');
        return;
      } */
      if (scenarioData?.step === 1) {
        const response = await axiosInstance.put(
          `scenarios/${scenarioData?.id}`,
          {
            step: 2,
          }
        );
        if (response?.data?.data) {
          tabHandler(2);
          if (scenarioData?.is_calculated === FLAG_Y_N.YES) {
            const calculateResponse = await axiosInstance.get(
              `scenarios/${scenarioData?.id}/calculate`
            );
            if (
              !(
                calculateResponse.status === 200 ||
                calculateResponse.status === 201
              )
            ) {
              toastMessage(
                TOAST_MESSAGE_TYPE.ERROR,
                calculateResponse?.data?.meta?.message ||
                  'Unable to calculate scenario.'
              );
            }
          }
        } else {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            response?.data?.meta?.message || 'Something went wrong'
          );
        }
      } else if (scenarioData?.step >= 2) {
        tabHandler(2);
        if (scenarioData?.is_calculated === FLAG_Y_N.YES) {
          const calculateResponse = await axiosInstance.get(
            `scenarios/${scenarioData?.id}/calculate`
          );
          if (
            !(
              calculateResponse.status === 200 ||
              calculateResponse.status === 201
            )
          ) {
            toastMessage(
              TOAST_MESSAGE_TYPE.ERROR,
              calculateResponse?.data?.meta?.message ||
                'Unable to calculate scenario.'
            );
          }
        }
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    fetchAllEmploymentDetails();
  }, [scenarioData?.id]);

  return (
    <>
      <div className='pb-6 flex flex-col gap-6'>
        <div className='py-2 border-b border-bord-g10 flex justify-between items-center'>
          <h3 className='text-base font-semibold text-b10'>
            Employment Details
          </h3>
          <FormSubmit
            onClick={() => setOpenEmploymentForm(true)}
            className={'flex-row-reverse secondary-btn-blue group'}
            iconClass={
              'duration-500 group-hover:invert group-hover:grayscale group-hover:brightness-0'
            }
            icon={'/assets/images/icons/plus-circle-blue.svg'}
            buttonText={'Add Employment'}
            btnDisable={openEmploymentForm}
          />
        </div>

        <div className='flex flex-col gap-6'>
          {/* employment form start */}
          {showEmploymentForm && !employmentEditData?.id && (
            <EmploymentForm
              employmentEditData={employmentEditData}
              fetchAllEmploymentDetails={fetchAllEmploymentDetails}
              scenarioDataId={scenarioData?.id}
              setOpenEmploymentForm={setOpenEmploymentForm}
              setEmploymentEditData={setEmploymentEditData}
              openEmploymentForm={openEmploymentForm}
              employmentDetails={employmentDetails}
            />
          )}

          {/* employment form end */}

          {employmentDetails.map((employment) =>
            employment?.id === employmentEditData?.id ? (
              <EmploymentForm
                key={employment?.id}
                employmentEditData={employmentEditData}
                fetchAllEmploymentDetails={fetchAllEmploymentDetails}
                scenarioDataId={scenarioData?.id}
                setOpenEmploymentForm={setOpenEmploymentForm}
                setEmploymentEditData={setEmploymentEditData}
                openEmploymentForm={openEmploymentForm}
              />
            ) : (
              <div
                className='odd:bg-white even:bg-light-gray p-4 border border-bord-g30 rounded-xl'
                key={employment?.id}
              >
                <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
                  <h3 className='text-lsm font-semibold text-b10'>
                    {employment?.name || 'Employment Name'}
                  </h3>
                  <img
                    src='/assets/images/icons/edit.svg'
                    alt='Edit'
                    onClick={() => {
                      setEmploymentEditData(employment);
                      setOpenEmploymentForm(true);
                    }}
                  />
                </div>

                <div className='grid gap-6'>
                  <div className='grid gap-6 grid-cols-3'>
                    <div className='flex flex-col gap-1'>
                      <p className='text-lsm font-normal text-b10'>
                        Employment Start Date *
                      </p>
                      <p className='text-lsm font-ibm font-semibold text-b10'>
                        {employment?.start_date || 'DD/MM/YYYY'}
                      </p>
                    </div>
                    <div className='flex flex-col gap-1'>
                      <p className='text-lsm font-normal text-b10'>
                        Employment End Date *
                      </p>
                      <p className='text-lsm font-ibm font-semibold text-b10'>
                        {employment?.end_date || 'DD/MM/YYYY'}
                      </p>
                    </div>
                  </div>

                  <div className='text-lsm font-ibm font-semibold text-b10'>
                    {employment?.annual_increment_type ===
                    SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE
                      ? 'Annually Increment By Percentage'
                      : employment?.annual_increment_type ===
                        SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.FIXED_AMOUNT
                      ? 'Annually Increment By Fixed Amount'
                      : 'Overall Salary For Given Period'}
                  </div>

                  <div className='grid gap-6 grid-cols-3'>
                    <div className='flex flex-col gap-1'>
                      <p className='text-lsm font-normal text-b10'>
                        {employment?.annual_increment_type ===
                          SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE ||
                        employment?.annual_increment_type ===
                          SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.FIXED_AMOUNT
                          ? 'Annual Salary'
                          : 'Overall Salary Amount'}
                      </p>
                      <p className='text-lsm font-ibm font-semibold text-b10'>
                        {employment?.annual_salary}
                      </p>
                    </div>
                    {(employment?.annual_increment_type ===
                      SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE ||
                      employment?.annual_increment_type ===
                        SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.FIXED_AMOUNT) && (
                      <div className='flex flex-col gap-1'>
                        <p className='text-lsm font-normal text-b10'>
                          {employment?.annual_increment_type ===
                          SALARY_INCREMENT_AMOUNT_TYPE_OPTIONS.PERCENTAGE
                            ? 'Annually Increment Percentage'
                            : 'Annually Increment Amount'}
                        </p>
                        <p className='text-lsm font-ibm font-semibold text-b10'>
                          {employment?.annual_increment_value}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() => tabHandler(0)}
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={() => handleNextStep()}
        />
      </div>
    </>
  );
};

EmploymentDetails.propTypes = {
  tabHandler: PropTypes.func.isRequired,
  scenarioData: scenarioPropType,
};
EmploymentDetails.defaultProps = {
  scenarioData: {},
};

EmploymentForm.propTypes = {
  employmentEditData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    annual_salary: PropTypes.number,
    annual_increment_type: PropTypes.string,
    annual_increment_value: PropTypes.number,
  }),
  fetchAllEmploymentDetails: PropTypes.func.isRequired,
  scenarioDataId: PropTypes.number,
  setOpenEmploymentForm: PropTypes.func.isRequired,
  setEmploymentEditData: PropTypes.func.isRequired,
  openEmploymentForm: PropTypes.bool.isRequired,
  employmentDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      annual_salary: PropTypes.number,
      annual_increment_type: PropTypes.string,
      annual_increment_value: PropTypes.number,
    })
  ),
};
EmploymentForm.defaultProps = {
  employmentEditData: {},
  fetchAllEmploymentDetails: () => {},
  scenarioDataId: null,
  setOpenEmploymentForm: () => {},
  setEmploymentEditData: () => {},
  openEmploymentForm: false,
  employmentDetails: [],
};

export default EmploymentDetails;
