import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { scenarioPropType, STORAGE_KEYS } from '../../helper';
import { toastMessage } from '../../helper/function';
import axiosInstance from '../../api/axiosInstance';
import {
  ROUTE_PATH,
  FLAG_Y_N,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import ConfirmationModal from '../Generic/ConfirmationModal';

function ScenarioCard({
  scenariosData,
  isScenarioSelected,
  selectedScenario,
  setSelectedScenario,
  isRadioBtn,
  isCheckbox,
  compareScenarioIds,
  setCompareScenarioIds,
  fetchScenarios,
  projectionData,
}) {
  const navigate = useNavigate();

  const [wantToDeleteScenario, setWantToDeleteScenario] = useState({});

  const handleChange = (scenario, isChecked) => {
    let currentIds = compareScenarioIds.map((s) => s.scenarioId.toString());

    if (isChecked) {
      if (
        scenario.id !== projectionData.id &&
        scenario.is_calculated !== FLAG_Y_N.YES
      ) {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          'This scenario is not calculated yet. Please calculate it first.'
        );
        return;
      }
      if (!currentIds.includes(scenario.id.toString())) {
        currentIds.push(scenario.id.toString());
      }
    } else {
      currentIds = currentIds.filter((id) => id !== scenario.id.toString());
    }

    // Ensure we always have at least 2 item selected
    if (currentIds.length < 2) {
      toastMessage(
        TOAST_MESSAGE_TYPE.INFO,
        'At least one item must be selected for comparison.'
      );
      return;
    }

    setCompareScenarioIds(
      currentIds.map((id) => {
        if (id === projectionData.id.toString()) {
          return { title: projectionData.name, scenarioId: projectionData.id };
        }
        const scenarioItem = scenariosData.find((s) => s.id.toString() === id);
        return { title: scenarioItem.name, scenarioId: scenarioItem.id };
      })
    );

    updateUrl(currentIds);
  };

  const updateUrl = (ids) => {
    if (ids.length === 0) {
      navigate(ROUTE_PATH.COMPARE_SCENARIO);
    } else {
      const newUrl = `${ROUTE_PATH.COMPARE_SCENARIO}?ids=${ids.join(',')}`;
      navigate(newUrl);
    }
  };

  const scenarioDeleteHandler = async (id) => {
    try {
      const resposne = await axiosInstance.delete(`scenarios/delete/${id}`);
      if (resposne.status === 200) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, resposne?.data.meta?.message);
        await fetchScenarios();
        localStorage.removeItem(STORAGE_KEYS.selectedSceanrioId);
        setSelectedScenario(null);
      }
    } catch (error) {
      console.error('error while deleting scenario', error);
    } finally {
      setWantToDeleteScenario({});
    }
  };

  return scenariosData?.map((scenario) => (
    <div key={scenario.id}>
      <ConfirmationModal
        open={Boolean(wantToDeleteScenario?.id)}
        text={`Are you sure you want to delete ${wantToDeleteScenario?.name} scenario?`}
        handleClose={() => setWantToDeleteScenario({})}
        handleSubmit={() => scenarioDeleteHandler(wantToDeleteScenario?.id)}
      />
      {isRadioBtn && (
        <div
          className={`p-3 flex flex-col gap-4 items-center shrink-0 rounded-2xl border-2  ${
            isScenarioSelected && selectedScenario.id === scenario.id
              ? 'border-tertiary-gray'
              : 'border-transparent'
          }`}
        >
          <label
            className='flex flex-col gap-4 cursor-pointer border border-bord-g10 px-4 py-2 rounded-xl shadow-double min-w-[300px]'
            style={{ backgroundColor: scenario?.color || '#FFFFFF' }}
            htmlFor={scenario.id}
          >
            <div className='pb-2 border-b border-b-neutral-gray flex  justify-between items-center w-full'>
              <p className='text-base font-semibold text-b10'>
                {scenario.name}
              </p>
              <div className='flex gap-1.5'>
                <img src='/assets/images/icons/info-circle.svg' alt='Info' />
                <button
                  onClick={() => {
                    if (scenario?.id) {
                      navigate(
                        `/${
                          scenario?.is_calculated === FLAG_Y_N.YES
                            ? 'edit-scenario'
                            : 'create-scenario'
                        }/${scenario.id}`
                      );
                    }
                  }}
                >
                  <img src='/assets/images/icons/edit-black.svg' alt='Edit' />
                </button>
                <button onClick={() => setWantToDeleteScenario(scenario)}>
                  <img
                    src='/assets/images/icons/delete-black.svg'
                    alt='Delete'
                  />
                </button>
              </div>
            </div>

            <div className='flex gap-2 justify-evenly'>
              <div className='flex flex-col '>
                <p className='text-xxs font-normal text-neutral-gray'>Annual</p>
                <p className='text-base font-medium text-b10'>
                  {scenario?.net?.annual_pension || '- -'}
                </p>
              </div>
              <span className='block w-px min-h-full bg-white bg-opacity-40'></span>
              <div className='flex flex-col '>
                <p className='text-xxs font-normal text-neutral-gray'>
                  Monthly
                </p>
                <p className='text-base font-medium text-b10'>
                  {scenario?.net?.monthly_pension || '- -'}
                </p>
              </div>
            </div>

            <input
              type='checkbox'
              className='w-[18px] h-[18px] bg-white opacity-0 border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0 cursor-pointer absolute'
              style={{
                color: isScenarioSelected ? scenario?.color || '#5EDAF7' : '',
              }}
              id={scenario.id}
              checked={
                isScenarioSelected && selectedScenario.id === scenario.id
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedScenario(scenario);
                  localStorage.setItem(
                    STORAGE_KEYS.selectedSceanrioId,
                    scenario.id
                  );
                }
              }}
            />
          </label>
        </div>
      )}
      {isCheckbox && (
        <div className='p-2 flex flex-col gap-4 items-center shrink-0'>
          <div
            className='flex flex-col gap-4 border border-bord-g10 px-4 py-2 rounded-xl shadow-double min-w-[300px]'
            style={{ backgroundColor: scenario?.color || '#FFFFFF' }}
          >
            <div className='pb-2 border-b border-b-neutral-gray flex  justify-between items-center w-full'>
              <p className='text-base font-semibold text-b10'>
                {scenario.name}
              </p>
              <img src='/assets/images/icons/info-circle.svg' alt='Info' />
            </div>

            <div className='flex gap-2 justify-evenly'>
              <div className='flex flex-col '>
                <p className='text-xxs font-normal text-neutral-gray'>Annual</p>
                <p className='text-base font-medium text-b10'>
                  {scenario?.net?.annual_pension || '- -'}
                </p>
              </div>
              <span className='block w-px min-h-full bg-white bg-opacity-40'></span>
              <div className='flex flex-col '>
                <p className='text-xxs font-normal text-neutral-gray'>
                  Monthly
                </p>
                <p className='text-base font-medium text-b10'>
                  {scenario?.net?.monthly_pension || '- -'}
                </p>
              </div>
            </div>
          </div>
          <div className='w-fit'>
            {isCheckbox && (
              <input
                type='checkbox'
                className='w-[18px] h-[18px] text-primary bg-white border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0'
                checked={compareScenarioIds?.some(
                  (item) => item?.scenarioId === scenario?.id
                )}
                onChange={(e) => handleChange(scenario, e.target.checked)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  ));
}

export default ScenarioCard;
ScenarioCard.propTypes = {
  isScenarioSelected: PropTypes.bool,
  scenariosData: PropTypes.arrayOf(scenarioPropType),
  selectedScenario: scenarioPropType,
  setSelectedScenario: PropTypes.func,
  compareScenarioIds: PropTypes.array.isRequired,
  setCompareScenarioIds: PropTypes.func.isRequired,
  fetchScenarios: PropTypes.func,
  projectionData: scenarioPropType,
};

ScenarioCard.defaultProps = {
  isScenarioSelected: false,
  scenariosData: [],
  selectedScenario: null,
  setSelectedScenario: () => {},
  fetchScenarios: () => {},
  projectionData: null,
};
