import React from 'react';
import useResizeHeight from '../../hooks/useResizeHeight';
import Sidebar from '../../components/Sidebar/Sidebar';
import BarChart from '../../components/CompareScenarioComponents/BarChart';
import useScenario from '../../hooks/useScenario';
import ScenarioSection from '../pensionCalculator/ScenarioSection';
import { useCompareScenario } from '../../hooks/useComapreScenario';

const CompareScenario = () => {
  useResizeHeight('dashPanel');
  const { scenariosData } = useScenario();
  const {
    compareScenarioData,
    setCompareScenarioData,
    formattedTitles,
    comparedData,
    projectionData,
  } = useCompareScenario();

  return (
    <div className='mt-4 flex gap-2 '>
      <Sidebar />
      <div id='dashPanel' className='w-full p-4 bg-light-gray overflow-auto'>
        <div className='flex flex-col gap-6 '>
          {/* top section start */}
          <ScenarioSection
            scenariosData={scenariosData}
            isCheckbox
            compareScenarioIds={compareScenarioData}
            setCompareScenarioIds={setCompareScenarioData}
            projectionData={projectionData}
          />
          {/* top section end */}
          <div className='mt-4 '>
            {formattedTitles && (
              <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
                <h3 className='text-slg font-bold text-b10'>
                  {formattedTitles}
                </h3>
              </div>
            )}
            <BarChart comparedData={comparedData} />
          </div>

          <div className='border border-bord-g10 rounded-lg overflow-hidden bg-light-gray shadow-double'>
            <div className='max-h-96 overflow-auto'>
              <table className='min-w-full relative'>
                <thead className='bg-light-gray sticky top-0'>
                  <tr>
                    <th
                      scope='col'
                      className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                    >
                      Scenario
                    </th>
                    <th
                      scope='col'
                      className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                    >
                      Retirement Start Date
                    </th>
                    <th
                      scope='col'
                      className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                    >
                      Survivor Pension
                    </th>
                    <th
                      scope='col'
                      className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                    >
                      Lifetime Guarantee
                    </th>
                    <th
                      scope='col'
                      className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                    >
                      Gross Annual Pension
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {comparedData?.scenarios?.map((data) => (
                    <tr key={data?.id} style={{ backgroundColor: data?.color }}>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-semibold  text-b10'>
                        {data?.name}
                      </td>

                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-semibold  text-b10'>
                        {data?.retirement_start_date}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-semibold  text-b10'>
                        {data?.survivor_pension_percentage}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-semibold  text-b10'>
                        {data?.pension_guarantee_period || 'None'}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-semibold  text-b10'>
                        {data?.gross_annual_pension || '$0.00'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareScenario;
