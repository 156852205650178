import React from 'react';
import PropTypes from 'prop-types';

const FormSubmit = ({ icon, endIcon, type, buttonText, btnDisable, onClick, ...rest }) => {
  return (
    <div className={rest.parentClass}>
      <button
        type={type}
        disabled={btnDisable}
        className={`${(icon || endIcon) && 'flex gap-3 items-center'} ${rest.className} primary-btn`}
        onClick={onClick}
      >
        {icon && (
          <span>
            <img className={rest.iconClass} src={icon} alt='icon' />
          </span>
        )}
        {buttonText}
      {endIcon && (
          <span>
            <img className={rest.endIconClass} src={endIcon} alt='icon' />
          </span>
        )}
      </button>
    </div>
  );
};
FormSubmit.propTypes = {
  icon: PropTypes.string,
  endIcon: PropTypes.string,
  type: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  btnDisable: PropTypes.bool,
  onClick: PropTypes.func,
};

FormSubmit.defaultProps = {
  btnDisable: false,
  onClick: () => { },
};
export default FormSubmit;
