import React, { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../api/axiosInstance';
import {
  MARITAL_STATUS_OPTIONS,
  PENSION_APPLICATION_FORM_STEPS,
} from '../../../helper/constant';

const modalTheme = {
  content: {
    base: ' relative h-full w-full p-4 md:h-auto flex items-center justify-center max-w-[100%] px-20 ',
    inner:
      'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 w-full',
  },
};

const ProfileViewModal = ({ open, text, handleClose }) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    let didCancel = false;
    const fetchProfileData = async () => {
      try {
        const response = await axiosInstance.get('retirement/form/get/data', {
          params: {
            step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE,
          },
        });
        if (!didCancel) {
          setProfileData(response?.data?.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error while fetching profile data', error);
        if (!didCancel) {
          setLoading(false);
        }
      }
    };
    fetchProfileData();
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Modal
      position='center'
      theme={modalTheme}
      show={open}
      popup
      // size='' to make the modal full screen
      size=''
      onClose={handleClose}
    >
      <Modal.Header>
        <h2 className='mt-[10px] ml-[20px] text-3xl font-semibold text-blue-900'>
          {text}
        </h2>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className='flex items-center justify-center w-full h-full'>
            <div className='inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-primary border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white'></div>
          </div>
        ) : (
          <div className='flex flex-col gap-8 items-center justify-center text-center w-full'>
            <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
              <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                <p className='text-lbase font-semibold text-blue-900'>
                  Personal Information
                </p>
              </div>
              <div className='grid grid-cols-3 gap-6'>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    First Name
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.first_name}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Middle Name
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.middle_name}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Last Name
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.last_name}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Date of Birth
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.date_of_birth}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Gender
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.gender}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Marital Status
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.marital_status}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Email Address
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.email}`}</p>
                </div>
              </div>
            </div>

            <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
              <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                <p className='text-lbase font-semibold text-blue-900'>
                  Contact Information
                </p>
              </div>
              <div className='grid grid-cols-3 gap-6'>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Email address
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.contact_information?.email}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Phone Numbers
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: (${profileData?.contact_information?.contact_no_type}) ${profileData?.contact_information?.contact_no}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Address Line 1
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.contact_information?.address_line_1}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Address Line 2
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.contact_information?.address_line_2}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    Apartment / Unit
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.contact_information?.apartment}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    City
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.contact_information?.city}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    State / Province
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.contact_information?.state}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px] text-left'>
                    ZIP / Postal code
                  </p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.contact_information?.zip_code}`}</p>
                </div>
              </div>
            </div>

            {profileData?.marital_status !== MARITAL_STATUS_OPTIONS.SINGLE && (
              <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
                <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                  <p className='text-lbase font-semibold text-blue-900'>
                    Spousal Information
                  </p>
                </div>
                <div className='grid grid-cols-3 gap-6'>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      First Name
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.first_name}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Middle Name
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.middle_name}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Last Name
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.last_name}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Date of Birth
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.date_of_birth}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Email address
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.email}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Phone Numbers
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: (${profileData?.spousal_information?.contact_no_type}) ${profileData?.spousal_information?.contact_no}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Address Line 1
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.address_line_1}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Address Line 2
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.address_line_2}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      Apartment / Unit
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.apartment}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      City
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.city}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      State / Province
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.state}`}</p>
                  </div>
                  <div className='flex'>
                    <p className='text-lsm text-b10 min-w-[125px] text-left'>
                      ZIP / Postal code
                    </p>
                    <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.spousal_information?.zip_code}`}</p>
                  </div>
                </div>
              </div>
            )}

            {profileData?.beneficiaries?.length > 0 && (
              <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
                {profileData?.beneficiaries?.map((beneficiary, idx) => (
                  <div key={idx} className={idx > 0 ? 'mt-[25px]' : ''}>
                    <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                      <p className='text-lbase font-semibold text-blue-900'>
                        Beneficiaries Information {idx + 1}
                      </p>
                    </div>
                    <div className='grid grid-cols-3 gap-6'>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          Percentage
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.percentage}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          First Name
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.first_name}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          Middle Name
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.middle_name}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          Last Name
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.last_name}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          Date of Birth
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.date_of_birth}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          Address Line 1
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.address_line_1}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          Address Line 2
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.address_line_2}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          Apartment / Unit
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.apartment}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          City
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.city}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          State / Province
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.state}`}</p>
                      </div>
                      <div className='flex'>
                        <p className='text-lsm text-b10 min-w-[125px] text-left'>
                          ZIP / Postal code
                        </p>
                        <p className='text-lsm font-semibold text-b10'>{`: ${beneficiary?.zip_code}`}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
              <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                <p className='text-lbase font-semibold text-blue-900'>
                  Preferences Information
                </p>
              </div>
              <div className='grid grid-cols-3 gap-6'>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px]'>Language</p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.preference_tab?.language}`}</p>
                </div>
                <div className='flex'>
                  <p className='text-lsm text-b10 min-w-[125px]'>Email Subscriptions</p>
                  <p className='text-lsm font-semibold text-b10'>{`: ${profileData?.preference_tab?.subscription?.email}`}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

ProfileViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProfileViewModal;
