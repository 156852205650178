import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormSubmit } from '../Generic';
import {
  PENSION_APPLICATION_FORM_STEPS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import {
  toastMessage,
  updatePensionApplicationFormStep,
} from '../../helper/function';
import axiosInstance from '../../api/axiosInstance';
import { setUpdatedDataFromChatBot } from '../../features/chatbot/chatbot';
import ServiceRecordsViewModal from './ViewDetail/ServiceRecordsViewModal';
import ProfileViewModal from './ViewDetail/ProfileViewModal';
import ElectionsViewModal from './ViewDetail/ElectionsViewModal';
import DocumentCenterViewModal from './ViewDetail/DocumentCenterViewModal';
import BankingDirectDepositViewModal from './ViewDetail/BankingDirectDepositViewModal';

const Review = ({ tabHandler }) => {
  const dispatch = useDispatch();

  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const { screenName, updatedDataFromChatBot } = useSelector(
    (state) => state.chatbot
  );

  const [reviewData, setReviewData] = useState([]);
  const [viewDetailModal, setViewDetailModal] = useState('');

  const getReviewData = async () => {
    try {
      const response = await axiosInstance.get('retirement/form/get/data', {
        params: {
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW,
        },
      });
      setReviewData(response.data.data);
    } catch (error) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while getting review data'
      );
      console.error('Error while getting review data', error);
    }
  };

  const handleEditAction = (data) => {
    tabHandler(data?.redirect_screen);
  };

  const handleNextButton = async () => {
    try {
      if (
        pensionApplicationFormData?.step ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW
      ) {
        await updatePensionApplicationFormStep({
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH,
          is_completed: 'y',
        });
      }
      tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH);
    } catch (error) {
      console.error(
        'Error while updating pension application form step',
        error
      );
    }
  };

  useEffect(() => {
    if (
      screenName === SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW
    ) {
      getReviewData();
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW &&
      updatedDataFromChatBot?.is_next
    ) {
      handleNextButton();
      dispatch(setUpdatedDataFromChatBot({}));
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW &&
      updatedDataFromChatBot?.is_previous
    ) {
      tabHandler(
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
      );
      dispatch(setUpdatedDataFromChatBot({}));
    }
  }, [updatedDataFromChatBot]);

  return (
    <>
      <div className=' relative  h-full flex flex-col justify-between   '>
        <div className='pb-2'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            Review
          </h2>
        </div>
        <div className='h-[calc(100%_-_50px)] overflow-auto'>
          <div className='mx-6 p-4 rounded-2xl bg-white shadow-double min-h-[450px]'>
            {reviewData?.length > 0 && (
              <div className='mt-8 px-4 flex flex-col gap-6'>
                {reviewData.map((data, idx) => (
                  <div
                    key={idx}
                    className='p-2.5 bg-light-gray rounded-lg flex items-center justify-between '
                  >
                    <div className='flex items-center gap-3'>
                      <img src='/assets/images/icons/file.svg' alt='File' />
                      <p className='text-lsm font-normal text-b10'>
                        {data?.title}
                      </p>
                    </div>
                    <div className='flex items-center gap-4'>
                      <div
                        className='w-6 h-6 aspect-square cursor-pointer'
                        onClick={() => {
                          setViewDetailModal(data?.redirect_screen || '');
                        }}
                      >
                        <img
                          className='w-full h-auto object-contain'
                          src='/assets/images/icons/show.svg'
                          alt='show'
                        />
                      </div>
                      <a
                        className='w-6 h-6 aspect-square'
                        href={data?.download_PDF_URL || ROUTE_PATH.HOME}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className='w-full h-auto object-contain'
                          src='/assets/images/icons/download.svg'
                          alt='download'
                        />
                      </a>
                      <button
                        className='w-6 h-6 aspect-square'
                        onClick={() => handleEditAction(data)}
                      >
                        <img
                          className='w-full h-auto object-contain'
                          src='/assets/images/icons/edit-dark.svg'
                          alt='edit'
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {viewDetailModal ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD && (
        <ServiceRecordsViewModal
          open={Boolean(viewDetailModal)}
          handleClose={() => setViewDetailModal('')}
          text={'Service record review'}
        />
      )}
      {viewDetailModal ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE && (
        <ProfileViewModal
          open={Boolean(viewDetailModal)}
          handleClose={() => setViewDetailModal('')}
          text={'Profile review'}
        />
      )}
      {viewDetailModal ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS && (
        <ElectionsViewModal
          open={Boolean(viewDetailModal)}
          handleClose={() => setViewDetailModal('')}
          text={'Elections review'}
        />
      )}
      {viewDetailModal ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER && (
        <DocumentCenterViewModal
          open={Boolean(viewDetailModal)}
          handleClose={() => setViewDetailModal('')}
          text={'Document centre review'}
        />
      )}
      {viewDetailModal ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT && (
        <BankingDirectDepositViewModal
          open={Boolean(viewDetailModal)}
          handleClose={() => setViewDetailModal('')}
          text={'Banking direct deposit review'}
        />
      )}
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
};

Review.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};

export default Review;
