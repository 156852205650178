import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { scenarioPropType } from '../../helper';
import {
  LIFETIME_GUARANTEE_PERIOD_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { FormSelect } from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';

function ScenarioListingDetail({
  openProjectionDetails,
  setOpenProjectionDetails,
  isScenarioSelected,
  selectedScenario,
  projectionData,
  fetchScenarios,
}) {
  const { userInfo } = useSelector((state) => state.user);
  const { control, setValue } = useForm();

  useEffect(() => {
    if (!selectedScenario) {
      setValue(
        'survivor_pension_percentage',
        projectionData?.less_reduction?.survivor_pension_percentage
      );
      setValue(
        'pension_guarantee_period',
        projectionData?.less_reduction?.pension_guarantee_period
      );
    } else {
      setValue(
        'survivor_pension_percentage',
        selectedScenario?.less_reduction?.survivor_pension_percentage
      );
      setValue(
        'pension_guarantee_period',
        selectedScenario?.less_reduction?.pension_guarantee_period
      );
    }
  }, [selectedScenario, projectionData]);

  const renderPensionGrossItem = (label, value) => (
    <div className='flex justify-between'>
      <div className='flex gap-4'>
        <p className='text-lsm font-normal text-neutral-gray'>{label}</p>
      </div>
      <p className='text-lsm font-medium text-b10'>{value}</p>
    </div>
  );

  const renderLessReductionDetailItem = (
    label,
    amount,
    options,
    selectorName
  ) => (
    <div className='flex justify-between items-center'>
      <div className='flex gap-4 items-center'>
        <Controller
          name={selectorName}
          control={control}
          render={({ field: { value } }) => {
            return (
              <FormSelect
                id={selectorName}
                parentClass='!w-[120px]'
                SelectOptions={options}
                value={options?.find((option) => option.value === value)}
                onChange={async (selectedOption) => {
                  try {
                    setValue(selectorName, selectedOption?.value);
                    const response = await axiosInstance.put(
                      `scenarios/${
                        isScenarioSelected
                          ? selectedScenario?.id
                          : projectionData?.id
                      }/last-step`,
                      {
                        [selectorName]: selectedOption?.value,
                        step: 4,
                      }
                    );
                    if (response?.status === 200 || response?.status === 201) {
                      const responseData = await axiosInstance.get(
                        `scenarios/${
                          isScenarioSelected
                            ? selectedScenario?.id
                            : projectionData?.id
                        }/calculate`
                      );
                      if (
                        responseData?.status === 200 ||
                        responseData?.status === 201
                      ) {
                        fetchScenarios();
                      }
                    }
                  } catch (error) {
                    console.error(error);
                    toastMessage(
                      TOAST_MESSAGE_TYPE.ERROR,
                      error?.response?.data?.meta?.message ||
                        'Something went wrong'
                    );
                  }
                }}
              />
            );
          }}
        />
        <p className='text-lsm font-normal text-neutral-gray'>{label}</p>
      </div>
      <p className='text-lsm font-medium text-b10'>{amount}</p>
    </div>
  );

  return (
    <div className='bg-white p-4 rounded-2xl shadow-double border border-primary relative z-1'>
      <div className='mb-4 pb-4 border-b border-b-bord-g30 flex items-center justify-between'>
        <div className='flex gap-4 items-center'>
          <p className='text-base font-semibold text-blue-900'>Details</p>
        </div>

        <div>
          <button
            onClick={() => setOpenProjectionDetails((prev) => !prev)}
            className='font-ibm text-lsm font-semibold text-b10 flex items-center gap-2'
          >
            <span className='block'>More Details</span>
            <img
              className={`duration-500 ${
                openProjectionDetails && 'rotate-180'
              }`}
              src='/assets/images/icons/right-arrow.svg'
              alt='Right'
            />
          </button>
        </div>
      </div>

      <div className='flex flex-col gap-3'>
        <div className='p-2.5 bg-light-gray rounded-lg'>
          <div className='mb-4 flex items-center gap-2'>
            <p className='font-ibm text-lsm font-semibold text-b10'>Formula</p>
            <img
              className='w-4 h-4'
              src='/assets/images/icons/info-circle.svg'
              alt='Info'
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex justify-between'>
              <div className='flex gap-4'>
                <p className='text-lsm font-normal text-neutral-gray'>
                  {isScenarioSelected
                    ? selectedScenario?.formula?.multipler
                    : projectionData?.formula?.multipler || ''}
                </p>
                <p className='text-lsm font-normal text-neutral-gray'>X</p>
                <p className='text-lsm font-normal text-neutral-gray'>
                  <span className='block'>
                    {isScenarioSelected
                      ? selectedScenario?.formula?.credit
                      : projectionData?.formula?.credit || ''}
                  </span>
                  <span className='block'>(Credit)</span>
                </p>
                <p className='text-lsm font-normal text-neutral-gray'>X</p>
                <p className='text-lsm font-normal text-neutral-gray'>
                  <span className='block'>
                    {isScenarioSelected
                      ? selectedScenario?.formula?.average_salary
                      : projectionData?.formula?.average_salary || ''}
                  </span>
                  <span className='block'>(Avg Salary)</span>
                </p>
                <p className='text-lsm font-normal text-neutral-gray'>=</p>
              </div>

              <p className='text-lsm font-medium text-b10'>
                {isScenarioSelected
                  ? selectedScenario?.formula?.pension
                  : projectionData?.formula?.pension || ''}
              </p>
            </div>
          </div>
        </div>
        {(!isScenarioSelected ||
          (isScenarioSelected &&
            (selectedScenario?.less_reduction
              ?.less_reduction_pension_guarantee_period_amount ||
              selectedScenario?.less_reduction
                ?.less_reduction_survior_pension_amount))) &&
          userInfo?.data?.marital_status !== MARITAL_STATUS_OPTIONS.SINGLE && (
            <div className='p-2.5 bg-light-gray rounded-lg'>
              <div className='mb-4 flex items-center gap-2'>
                <p className='font-ibm text-lsm font-semibold text-b10'>
                  Less Reductions
                </p>
                <img
                  className='w-4 h-4'
                  src='/assets/images/icons/info-circle.svg'
                  alt='Info'
                />
              </div>

              <div className='flex flex-col gap-4'>
                {(!isScenarioSelected ||
                  (isScenarioSelected &&
                    selectedScenario?.less_reduction
                      ?.less_reduction_survior_pension_amount)) &&
                  renderLessReductionDetailItem(
                    'Survivor Pension',
                    isScenarioSelected
                      ? selectedScenario?.less_reduction
                          ?.less_reduction_survior_pension_amount
                      : projectionData?.less_reduction
                          ?.less_reduction_survior_pension_amount,
                    isScenarioSelected
                      ? selectedScenario?.survivor_pension_percentage?.map(
                          (item) => ({
                            value: item,
                            label: item + '%',
                          })
                        )
                      : projectionData?.survivor_pension_percentage?.map(
                          (item) => ({
                            value: item,
                            label: item + '%',
                          })
                        ),
                    'survivor_pension_percentage'
                  )}
                {(!isScenarioSelected ||
                  (isScenarioSelected &&
                    selectedScenario?.less_reduction
                      ?.less_reduction_pension_guarantee_period_amount)) &&
                  renderLessReductionDetailItem(
                    'Pension Guarantee Period',
                    isScenarioSelected
                      ? selectedScenario?.less_reduction
                          ?.less_reduction_pension_guarantee_period_amount
                      : projectionData?.less_reduction
                          ?.less_reduction_pension_guarantee_period_amount,
                    isScenarioSelected
                      ? selectedScenario?.pension_guarantee_period?.map(
                          (item) => {
                            if (
                              item === LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
                            ) {
                              return {
                                value: item,
                                label: 'None',
                              };
                            } else {
                              return {
                                value: item,
                                label: item + ' years',
                              };
                            }
                          }
                        )
                      : projectionData?.pension_guarantee_period?.map(
                          (item) => {
                            if (
                              item === LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
                            ) {
                              return {
                                value: item,
                                label: 'None',
                              };
                            } else {
                              return {
                                value: item,
                                label: item + ' years',
                              };
                            }
                          }
                        ),
                    'pension_guarantee_period'
                  )}
              </div>
            </div>
          )}
        <div className='p-2.5 bg-light-gray rounded-lg'>
          <div className='mb-4 flex items-center gap-2'>
            <p className='font-ibm text-lsm font-semibold text-b10'>
              Pension (Net)
            </p>
            <img
              className='w-4 h-4'
              src='/assets/images/icons/info-circle.svg'
              alt='Info'
            />
          </div>

          <div className='flex flex-col gap-4'>
            {renderPensionGrossItem(
              'Annual',
              isScenarioSelected
                ? selectedScenario?.net?.annual_pension
                : projectionData?.net?.annual_pension
            )}
            {renderPensionGrossItem(
              'Monthly',
              isScenarioSelected
                ? selectedScenario?.net?.monthly_pension
                : projectionData?.net?.monthly_pension
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScenarioListingDetail;
ScenarioListingDetail.propTypes = {
  isScenarioSelected: PropTypes.bool,
  openProjectionDetails: PropTypes.bool,
  selectedScenario: scenarioPropType,
  setOpenProjectionDetails: PropTypes.func,
  projectionData: scenarioPropType,
  fetchScenarios: PropTypes.func,
};

ScenarioListingDetail.defaultProps = {
  isScenarioSelected: false,
  openProjectionDetails: false,
  selectedScenario: null,
  setOpenProjectionDetails: () => {},
  fetchScenarios: () => {},
};
