import React from 'react';
import PropTypes from 'prop-types';

const ServiceDetails = ({ serviceData }) => {
  return (
    serviceData?.length > 0 && (
      <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0'>
        <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
          <p className='text-lbase font-semibold text-blue-900'>
            Service Details
          </p>
          <button className='flex items-center gap-2'>
            <span className='block font-ibm text-lsm font-semibold text-black'>
              Filter
            </span>
            <img src='/assets/images/icons/filter.svg' alt='Filter' />
          </button>
        </div>

        <div className='flex flex-col'>
          <div className=' overflow-x-auto sm:mx-0.5 lg:mx-0.5'>
            <div className=' inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='max-h-96 overflow-auto'>
                <table className='min-w-full relative'>
                  <thead className='bg-white sticky top-0'>
                    <tr>
                      <th
                        scope='col'
                        className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                      >
                        Year
                      </th>
                      <th
                        scope='col'
                        className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                      >
                        Type of Credit
                      </th>
                      <th
                        scope='col'
                        className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                      >
                        Qualifying years
                      </th>
                      <th
                        scope='col'
                        className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                      >
                        Annualized earnings($)
                      </th>
                      <th
                        scope='col'
                        className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                      >
                        Credit(years)
                      </th>
                      <th
                        scope='col'
                        className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                      >
                        Pensionable earnings($)
                      </th>
                      <th
                        scope='col'
                        className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                      >
                        Contributions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceData.map((data, index) => {
                      return (
                        <tr
                          key={data?.id}
                          className={index % 2 === 0 ? 'bg-light-gray' : ''}
                        >
                          <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                            {data.year}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                            {data.type_of_credit}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                            {data.qualifying_years}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                            {data.annualized_earnings}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                            {data.credited_years}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                            {data.pensionable_earnings}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                            {data.contribution}
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr className='bg-light-gray '>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        2022/2023
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Employment
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        1.0000
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Not Available
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        0.3299
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        26,521.39
                      </td>
                    </tr>
                    <tr className='bg-primary bg-opacity-50 '>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        2022/2023
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Employment
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        1.0000
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Not Available
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        0.3299
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        26,521.39
                      </td>
                    </tr>
                    <tr className='bg-light-gray '>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        2022/2023
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Employment
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        1.0000
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Not Available
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        0.3299
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        26,521.39
                      </td>
                    </tr>
                    <tr className=''>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        2022/2023
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Employment
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        1.0000
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Not Available
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        0.3299
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        26,521.39
                      </td>
                    </tr>
                    <tr className='bg-light-gray'>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        2022/2023
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Employment
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        1.0000
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Not Available
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        0.3299
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        26,521.39
                      </td>
                    </tr>
                    <tr className=''>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        2022/2023
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Employment
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        1.0000
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        Not Available
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        0.3299
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                        26,521.39
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ServiceDetails;
ServiceDetails.propTypes = {
  serviceData: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string,
      type_of_credit: PropTypes.string,
      qualifying_years: PropTypes.string,
      annualized_earnings: PropTypes.string,
      credited_years: PropTypes.string,
      pensionable_earnings: PropTypes.string,
    })
  ),
};

ServiceDetails.defaultProps = {
  serviceData: [],
};
