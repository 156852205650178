import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { RangeSlider } from 'flowbite-react';
import { CustomDatePicker, FormSubmit } from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import {
  isDate1LessThanDate2OrEqual,
  parseDateDDMMYYYYToDateObject,
  toastMessage,
} from '../../helper/function';
import {
  DATE_DD_MM_YYYY_FORMAT_REGEX,
  FLAG_Y_N,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';

function LeaveForm({
  scenarioDataId,
  openLeaveForm,
  setOpenLeaveForm,
  fetchAllLeaves,
  editLeaveData,
  setEditLeaveData,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      start_date: '',
      end_date: '',
    },
  });

  const watchAll = watch();
  const watchStartDate = watchAll?.start_date;

  const editLeaveDateID = editLeaveData?.id;

  const [sliderValue, setSliderValue] = useState(50);
  const [isBuyBack, setIsBuyBack] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [buyBackAmount100PercentAmount, setBuyBackAmount100PercentAmount] =
    useState(0);
  const [buyBackAmount, setBuyBackAmount] = useState(0);
  const [isExistBuyBack, setIsExistBuyBack] = useState(false);
  const [isWantToEditBuyBack, setIsWantToEditBuyBack] = useState(true);

  const handleSliderValue = (e, value) => {
    e.preventDefault();
    setSliderValue(value);
  };

  const onSubmit = async (data) => {
    try {
      const { start_date, end_date } = data;
      if (!isDate1LessThanDate2OrEqual(start_date, end_date, true)) {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          'Leave End Date must be greater than or equal to Leave Start Date'
        );
        return;
      }

      if (editLeaveDateID) {
        const response = await axiosInstance.put(
          `scenario/${scenarioDataId}/leaves/${editLeaveDateID}`,
          {
            ...data,
            buy_back_percentage: isBuyBack ? sliderValue : 0,
            buy_back_leave_amount: isBuyBack ? buyBackAmount : 0,
          }
        );
        if (response?.data?.data) {
          setEditLeaveData(null);
          setOpenLeaveForm(false);
          fetchAllLeaves();
        }
      } else {
        const response = await axiosInstance.post(
          `scenario/${scenarioDataId}/leaves`,
          { ...data }
        );
        if (isBuyBack) {
          await axiosInstance.put(
            `scenario/${scenarioDataId}/leaves/${editLeaveDateID}/buy-back-leave`,
            {
              buy_back_percentage: sliderValue,
              buy_back_leave_amount: buyBackAmount,
            }
          );
        }
        if (response?.data?.data) {
          setOpenLeaveForm(false);
          fetchAllLeaves();
        }
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    if (editLeaveDateID) {
      const {
        start_date,
        end_date,
        is_buy_back,
        currency_symbol,
        buy_back_leave_amount,
        buy_back_percentage,
      } = editLeaveData;
      reset({
        start_date,
        end_date,
      });
      if (is_buy_back === FLAG_Y_N.YES) {
        setIsBuyBack(true);
        setCurrencySymbol(currency_symbol);
        setBuyBackAmount(buy_back_leave_amount);
        setSliderValue(buy_back_percentage);
        setIsExistBuyBack(true);
      }
    }
  }, [editLeaveDateID]);

  useEffect(() => {
    const fetchBuyBackAmount = async () => {
      try {
        const response = await axiosInstance.get(
          `scenario/${scenarioDataId}/leaves/${editLeaveDateID}/buy-back-leave-amount`
        );
        if (response?.data?.data) {
          setBuyBackAmount100PercentAmount(
            response?.data?.data?.buy_back_leave_amount
          );
          setCurrencySymbol(response?.data?.data?.currency_symbol);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (editLeaveDateID && (isBuyBack || isWantToEditBuyBack)) {
      fetchBuyBackAmount();
    }
  }, [isBuyBack, isWantToEditBuyBack]);

  useEffect(() => {
    if (isBuyBack && buyBackAmount100PercentAmount) {
      setBuyBackAmount(
        (buyBackAmount100PercentAmount * (sliderValue / 100)).toFixed(2)
      );
    }
  }, [buyBackAmount100PercentAmount, sliderValue, isBuyBack]);

  return (
    <div
      className={`${
        openLeaveForm ? 'animate-inAnimation' : 'animate-outAnimation'
      } duration-500 transition-all p-4 border border-primary rounded-xl`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-6'>
          <div className='grid grid-cols-2 gap-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Leave Start Date *
              </span>
              <Controller
                name='start_date'
                control={control}
                rules={{
                  required: 'Leave Start Date is required',
                  pattern: {
                    value: DATE_DD_MM_YYYY_FORMAT_REGEX,
                    message:
                      'Leave Start Date must be in the format DD/MM/YYYY',
                  },
                }}
                render={({ field }) => (
                  <CustomDatePicker
                    value={field.value}
                    onChange={field.onChange}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    autoComplete='off'
                    placeholderText='DD/MM/YYYY'
                    disabled={editLeaveDateID}
                  />
                )}
              />
              {errors.start_date && (
                <p className='text-red-600 text-xxs mt-1'>
                  {errors.start_date.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Leave End Date *
              </span>
              <Controller
                name='end_date'
                control={control}
                rules={{
                  required: 'Leave End Date is required',
                  pattern: {
                    value: DATE_DD_MM_YYYY_FORMAT_REGEX,
                    message: 'Leave End Date must be in the format DD/MM/YYYY',
                  },
                }}
                render={({ field }) => (
                  <CustomDatePicker
                    value={field.value}
                    onChange={field.onChange}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    autoComplete='off'
                    placeholderText='DD/MM/YYYY'
                    disabled={editLeaveDateID}
                    minDate={
                      watchStartDate
                        ? parseDateDDMMYYYYToDateObject(watchStartDate)
                        : null
                    }
                  />
                )}
              />
              {errors.end_date && (
                <p className='text-red-600 text-xxs mt-1'>
                  {errors.end_date.message}
                </p>
              )}
            </div>
          </div>
          {editLeaveDateID &&
            editLeaveData?.is_buy_back === FLAG_Y_N.NO &&
            !isBuyBack && (
              <div className='flex justify-end items-center'>
                {!isBuyBack && (
                  <div className='mr-3'>
                    Do you want to buy back this leave?
                  </div>
                )}
                <FormSubmit
                  className={'secondary-btn-blue'}
                  buttonText={`${
                    isExistBuyBack ? 'Edit Buy Back' : 'Buy Back'
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (isExistBuyBack) {
                      setIsWantToEditBuyBack(true);
                    } else {
                      setIsBuyBack(true);
                    }
                  }}
                />
              </div>
            )}
          {isBuyBack && (
            <div className='p-4 bg-light-gray rounded-lg flex flex-col gap-2'>
              <div className='text-right'>
                <button
                  disabled={
                    isExistBuyBack && !isWantToEditBuyBack ? true : false
                  }
                  onClick={() => setIsBuyBack(false)}
                >
                  <img src='/assets/images/icons/close.svg' alt='close' />
                </button>
              </div>
              <div className='flex justify-between items-center'>
                <p className='text-lsm text-b10 font-semibold'>
                  Buy Back this leave for
                </p>
                <p className='text-md text-b10 font-semibold'>
                  {currencySymbol} {buyBackAmount}
                </p>
              </div>

              <div className='flex flex-col gap-6'>
                <RangeSlider
                  onChange={(e) => setSliderValue(e.target.value)}
                  min={0}
                  max={100}
                  step={25}
                  id='sm-range'
                  sizing='sm'
                  value={sliderValue}
                  disabled={
                    isExistBuyBack && !isWantToEditBuyBack ? true : false
                  }
                />
                <div className='flex items-center gap-[30px] '>
                  <button
                    onClick={(e) => handleSliderValue(e, 0)}
                    className={`${
                      sliderValue == 0 ? 'primary-btn' : 'secondary-btn-blue'
                    }  w-full flex-grow`}
                    disabled={
                      isExistBuyBack && !isWantToEditBuyBack ? true : false
                    }
                  >
                    0%
                  </button>
                  <button
                    onClick={(e) => handleSliderValue(e, 25)}
                    className={`${
                      sliderValue == 25 ? 'primary-btn' : 'secondary-btn-blue'
                    }  w-full flex-grow`}
                    disabled={
                      isExistBuyBack && !isWantToEditBuyBack ? true : false
                    }
                  >
                    25%
                  </button>
                  <button
                    onClick={(e) => handleSliderValue(e, 50)}
                    className={`${
                      sliderValue == 50 ? 'primary-btn' : 'secondary-btn-blue'
                    }  w-full flex-grow`}
                    disabled={
                      isExistBuyBack && !isWantToEditBuyBack ? true : false
                    }
                  >
                    50%
                  </button>
                  <button
                    onClick={(e) => handleSliderValue(e, 75)}
                    className={`${
                      sliderValue == 75 ? 'primary-btn' : 'secondary-btn-blue'
                    }  w-full flex-grow`}
                    disabled={
                      isExistBuyBack && !isWantToEditBuyBack ? true : false
                    }
                  >
                    75%
                  </button>
                  <button
                    onClick={(e) => handleSliderValue(e, 100)}
                    className={`${
                      sliderValue == 100 ? 'primary-btn' : 'secondary-btn-blue'
                    }  w-full flex-grow`}
                    disabled={
                      isExistBuyBack && !isWantToEditBuyBack ? true : false
                    }
                  >
                    100%
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className='flex gap-2 justify-end'>
            <FormSubmit
              onClick={(e) => {
                e.preventDefault();
                setEditLeaveData(null);
                setOpenLeaveForm(false);
              }}
              className={'secondary-btn-blue border-transparent'}
              buttonText={'Cancel'}
            />
            <FormSubmit
              className={'secondary-btn-blue'}
              buttonText={'Save'}
              type='submit'
            />
          </div>
        </div>
      </form>
    </div>
  );
}

LeaveForm.propTypes = {
  scenarioDataId: PropTypes.number,
  openLeaveForm: PropTypes.bool,
  setOpenLeaveForm: PropTypes.func,
  fetchAllLeaves: PropTypes.func,
  editLeaveData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(null),
  ]),
  setEditLeaveData: PropTypes.func,
};

LeaveForm.defaultProps = {
  openLeaveForm: false,
  setOpenLeaveForm: () => {},
  fetchAllLeaves: () => {},
  setEditLeaveData: () => {},
  editLeaveData: null,
};

export default LeaveForm;
