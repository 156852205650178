import { createSlice } from '@reduxjs/toolkit';
import { PENSION_APPLICATION_FORM_STEPS } from '../../helper/constant';
import { fetchPensionApplicationFormData } from './pensionApplicationFormThunk';

const pensionApplicationFormSlice = createSlice({
  name: 'pensionApplicationForm',
  initialState: {
    pensionApplicationFormData: null,
    status: 'idle',
    isLoading: false,
    error: null,
    pensionApplicationFormActiveTab: null,
  },
  reducers: {
    setPensionApplicationFormData: (state, action) => {
      state.pensionApplicationFormData = {
        ...state.pensionApplicationFormData,
        ...action.payload,
      };
    },
    setPensionApplicationFormActiveTab: (state, action) => {
      state.pensionApplicationFormActiveTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPensionApplicationFormData.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchPensionApplicationFormData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
        state.pensionApplicationFormData = action.payload;
        if(state.pensionApplicationFormActiveTab === null) {
          state.pensionApplicationFormActiveTab =
            action.payload?.step ||
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT;
        }
      })
      .addCase(fetchPensionApplicationFormData.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
        state.error = action.payload;
        state.pensionApplicationFormActiveTab =
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT;
      });
  },
});

export const {
  setPensionApplicationFormData,
  setPensionApplicationFormActiveTab,
} = pensionApplicationFormSlice.actions;

export default pensionApplicationFormSlice.reducer;
