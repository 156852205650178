import React from 'react';
import { Modal } from 'flowbite-react';
import PropTypes from 'prop-types';
import PensionInfoSummaryCard from '../../SummaryCard/PensionInfoSummaryCard';
import { useServiceRecord } from '../../../hooks/useServiceRecord';

const modalTheme = {
  content: {
    base: ' relative h-full w-full p-4 md:h-auto flex items-center justify-center max-w-[100%] px-20 ',
    inner:
      'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 w-full',
  },
};

const ServiceRecordsViewModal = ({ open, text, handleClose }) => {
  const { serviceRecord, loading } = useServiceRecord();

  return (
    <Modal
      position='center'
      theme={modalTheme}
      show={open}
      popup
      // size='' to make the modal full screen
      size=''
      onClose={handleClose}
    >
      <Modal.Header>
        <h2 className='mt-[10px] ml-[20px] text-3xl font-semibold text-blue-900'>
          {text}
        </h2>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className='flex items-center justify-center w-full h-full'>
            <div className='inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-primary border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white'></div>
          </div>
        ) : (
          <div className='flex flex-col gap-8 items-center justify-center text-center w-full'>
            <div className='w-full mt-[15px]'>
              <PensionInfoSummaryCard
                summaryData={serviceRecord?.data?.service_records_summery}
                showLastServiceRecordDate={true}
                fromApplicationForm={true}
                headerClassName='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between'
                contentClassName='flex flex-col gap-2'
                titleClassName='text-lbase font-semibold text-b10'
                dateClassName='text-lsm font-semibold text-b10'
              />
            </div>
            <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
              <div className='mb-4 pb-2.5 border-b border-b-bord-g30 flex justify-between items-center w-full'>
                <p className='text-lbase font-semibold text-blue-900'>
                  Service Details
                </p>
              </div>

              <div className='flex flex-col'>
                <div className=' overflow-x-auto sm:mx-0.5 lg:mx-0.5'>
                  <div className=' inline-block min-w-full sm:px-6 lg:px-8'>
                    <div className=''>
                      <table className='min-w-full relative'>
                        <thead className='bg-white sticky top-0'>
                          <tr>
                            <th
                              scope='col'
                              className='text-lsm font-semibold text-b10 px-6 py-4'
                            >
                              Year
                            </th>
                            <th
                              scope='col'
                              className='text-lsm font-semibold text-b10 px-6 py-4'
                            >
                              Type of Credit
                            </th>
                            <th
                              scope='col'
                              className='text-lsm font-semibold text-b10 px-6 py-4'
                            >
                              Qualifying years
                            </th>
                            <th
                              scope='col'
                              className='text-lsm font-semibold text-b10 px-6 py-4'
                            >
                              Annualized earnings($)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {serviceRecord?.data?.service_records?.map(
                            (record, idx) => {
                              return (
                                <tr
                                  key={record?.id}
                                  className={
                                    idx % 2 === 0 ? 'bg-light-gray' : ''
                                  }
                                >
                                  <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                    {record?.year}
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                    {record?.type_of_credit}
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                    {record?.qualifying_years}
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap text-xxs font-normal  text-b10'>
                                    {record?.annualized_earnings}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

ServiceRecordsViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ServiceRecordsViewModal;
