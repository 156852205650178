import React, { useEffect, useRef, useState } from 'react';
import { Tabs } from 'flowbite-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import useResizeHeight from '../../hooks/useResizeHeight';
import ProfileHeaderCard from '../../components/ProfileComponent/ProfileHeaderCard';
import PersonalInformation from '../../components/ProfileComponent/PersonalInformation';
import Contact from '../../components/ProfileComponent/Contact';
import Spousal from '../../components/ProfileComponent/Spousal';
import Beneficiaries from '../../components/ProfileComponent/Beneficiaries';
import Preferences from '../../components/ProfileComponent/Preferences';
import { PROFILE_SECTION_TABS_NAME, PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE, ROUTE_PATH } from '../../helper/constant';

const customTabTheme = {
  base: 'block',
  tablist: {
    base: 'hidden',
    tabitem: {},
  },
  tabitemcontainer: {
    base: 'p-0',
  },
  tabpanel: {
    base: 'p-0',
  },
};

const Profile = () => {
  useResizeHeight('dashPanel');
  const tabsRef = useRef(null);
  const location = useLocation();
  const pathname = location?.pathname;

  const { userInfo } = useSelector((state) => state.user);
  const { redirectScreenTab } = useSelector((state) => state.chatbot);

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: PROFILE_SECTION_TABS_NAME.PERSONAL,
      component: <PersonalInformation />,
    },
    {
      title: PROFILE_SECTION_TABS_NAME.CONTACT,
      component: <Contact />,
    },
    {
      title: PROFILE_SECTION_TABS_NAME.SPOUSAL,
      component: <Spousal />,
    },
    {
      title: PROFILE_SECTION_TABS_NAME.BENEFICIARIES,
      component: <Beneficiaries />,
    },
    {
      title: PROFILE_SECTION_TABS_NAME.PREFERENCES,
      component: <Preferences />,
    },
  ];

  const showTitle = () => {
    switch (activeTab) {
      case 0:
        return PROFILE_SECTION_TABS_NAME.PERSONAL;
      case 1:
        return PROFILE_SECTION_TABS_NAME.CONTACT;
      case 2:
        return PROFILE_SECTION_TABS_NAME.SPOUSAL;
      case 3:
        return PROFILE_SECTION_TABS_NAME.BENEFICIARIES;
      case 4:
        return PROFILE_SECTION_TABS_NAME.PREFERENCES;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (pathname === ROUTE_PATH.MY_PROFILE && redirectScreenTab) {
      switch (redirectScreenTab) {
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.PERSONAL:
          tabsRef.current?.setActiveTab(0);
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.CONTACT:
          tabsRef.current?.setActiveTab(1);
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.SPOUSAL:
          tabsRef.current?.setActiveTab(2);
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.BENEFICIARIES:
          tabsRef.current?.setActiveTab(3);
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.PREFERENCES:
          tabsRef.current?.setActiveTab(4);
          break;
        default:
          tabsRef.current?.setActiveTab(0);
          break;
      }
    }
  }, [redirectScreenTab]);

  return (
    <div className='mt-4 flex gap-2'>
      <Sidebar />
      <div
        id='dashPanel'
        className='w-full px-4 bg-dashboard-panel bg-contain bg-bottom bg-no-repeat overflow-auto'
      >
        <div className='p-2 mb-2'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            {showTitle()}
          </h2>
        </div>
        <div className='px-4 pt-4 mb-2 rounded-t-2xl bg-white shadow-double'>
          <ProfileHeaderCard
            firstName={userInfo?.data?.first_name}
            lastName={userInfo?.data?.last_name}
            email={userInfo?.data?.email}
            contactNo={userInfo?.data?.contact_information?.contact_no}
            state={userInfo?.data?.contact_information?.state}
            country={userInfo?.data?.contact_information?.country}
            profilePhotoUrl={userInfo?.data?.profile}
          />
          <div className='flex gap-10'>
            <button
              className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                activeTab === 0
                  ? 'border-black text-black'
                  : 'border-transparent text-neutral-gray'
              }`}
              onClick={() => tabsRef.current?.setActiveTab(0)}
            >
              Profile
            </button>
            <button
              className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                activeTab === 1
                  ? 'border-black text-black'
                  : 'border-transparent text-neutral-gray'
              }`}
              onClick={() => tabsRef.current?.setActiveTab(1)}
            >
              Contact
            </button>
            <button
              className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                activeTab === 2
                  ? 'border-black text-black'
                  : 'border-transparent text-neutral-gray'
              }`}
              onClick={() => tabsRef.current?.setActiveTab(2)}
            >
              Spousal
            </button>
            <button
              className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                activeTab === 3
                  ? 'border-black text-black'
                  : 'border-transparent text-neutral-gray'
              }`}
              onClick={() => tabsRef.current?.setActiveTab(3)}
            >
              Beneficiaries
            </button>
            <button
              className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                activeTab === 4
                  ? 'border-black text-black'
                  : 'border-transparent text-neutral-gray'
              }`}
              onClick={() => tabsRef.current?.setActiveTab(4)}
            >
              Preferences
            </button>
          </div>
        </div>

        <div className='bg-white p-6 rounded-b-lg'>
          <Tabs
            theme={customTabTheme}
            ref={tabsRef}
            onActiveTabChange={(tab) => setActiveTab(tab)}
          >
            {tabs?.map((tab, index) => {
              return (
                <Tabs.Item key={index} active title={tab.title}>
                  {tab.component}
                </Tabs.Item>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Profile;
