import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Sidebar from '../../components/Sidebar/Sidebar';
import useResizeHeight from '../../hooks/useResizeHeight';
import { FormInput, FormSubmit } from '../../components/Generic';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import { TOAST_MESSAGE_TYPE } from '../../helper/constant';

const ChangePassword = () => {
  useResizeHeight('dashPanel');
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const password = watch('password');

  const changePasswordHandler = async (data) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post('change-password', {
        current_password: data?.current_password?.trim(),
        password: data?.password?.trim(),
        password_confirmation: data?.password_confirmation?.trim(),
      });
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('error while resting password', error);
      toastMessage(TOAST_MESSAGE_TYPE.ERROR, error?.response?.data?.meta?.message);
    }
  };

  const validateConfirmPassword = (value) => {
    return value === password || 'Password and Confirm password must be same';
  };

  return (
    <div className="mt-4 flex gap-2">
      <Sidebar />
      <div
        id="dashPanel"
        className="w-full px-4 bg-dashboard-panel bg-contain bg-bottom bg-no-repeat overflow-auto"
      >
        <div className="p-2 mb-2">
          <h2 className="text-xxl font-ibm font-semibold text-piddy-dark-blue">
            Change Password
          </h2>
        </div>
        <div className="bg-white p-6 rounded-lg">
          <form onSubmit={handleSubmit(changePasswordHandler)}>
            <div className="flex flex-col gap-6">
              <div className="grid grid-cols-2 gap-6">
                <div className="flex flex-col gap-4">
                  <span className="block text-lsm font-normal text-b10">
                    Current Password*
                  </span>
                  <Controller
                    name="current_password"
                    control={control}
                    rules={{
                      required: 'Current Password is required.',
                      validate: {
                        uppercase: (value) =>
                          /[A-Z]/.test(value) ||
                          'Current Password should contain at least one uppercase letter.',
                        lowercase: (value) =>
                          /[a-z]/.test(value) ||
                          'Current Password should contain at least one lowercase letter.',
                        digit: (value) =>
                          /[0-9]/.test(value) ||
                          'Current Password should contain at least one digit.',
                        special: (value) =>
                          /[!@#$*]/.test(value) ||
                          'Current Password should contain at least one special symbol.',
                      },
                      minLength: {
                        value: 6,
                        message:
                          'Current Password should be at-least 6 characters.',
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        type="password"
                        placeholder="Current Password"
                        errorFieldName="current_password"
                        errors={errors}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div className="flex flex-col gap-4">
                  <span className="block text-lsm font-normal text-b10">
                    New Password*
                  </span>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'New Password is required.',
                      validate: {
                        uppercase: (value) =>
                          /[A-Z]/.test(value) ||
                          'New Password should contain at least one uppercase letter.',
                        lowercase: (value) =>
                          /[a-z]/.test(value) ||
                          'New Password should contain at least one lowercase letter.',
                        digit: (value) =>
                          /[0-9]/.test(value) ||
                          'New Password should contain at least one digit.',
                        special: (value) =>
                          /[!@#$*]/.test(value) ||
                          'New Password should contain at least one special symbol.',
                      },
                      minLength: {
                        value: 6,
                        message:
                          'New Password should be at-least 6 characters.',
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        type="password"
                        placeholder="New Password"
                        errorFieldName="password"
                        errors={errors}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div className="flex flex-col gap-4">
                  <span className="block text-lsm font-normal text-b10">
                    Confirm New Password*
                  </span>
                  <Controller
                    name="password_confirmation"
                    control={control}
                    rules={{
                      required: 'Confirm New Password is required.',
                      validate: {
                        validateConfirmPassword,
                      },
                      minLength: {
                        value: 6,
                        message:
                          'Confirm New Password should be at-least 6 characters.',
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        type="password"
                        placeholder="Confirm New Password"
                        errorFieldName="password_confirmation"
                        errors={errors}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-end mt-6">
                <FormSubmit
                  type={'submit'}
                  buttonText={'Save Changes'}
                  btnDisable={isLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
