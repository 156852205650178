import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ChatbotInput from '../../components/ChatbotInput/ChatbotInput';
import { ROUTE_PATH } from '../../helper/constant';
import { FormSubmit } from '../../components/Generic';

const Dashboard = () => {
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    const nav = document.getElementById('navbar');
    const welcomeTxt = document.getElementById('welcome-txt');
    const dashboardPanel = document.getElementById('dashboard-panel');
    if (nav && welcomeTxt && dashboardPanel) {
      setTimeout(() => {
        dashboardPanel.style.height = `${
          window.innerHeight -
          (nav.offsetHeight + (welcomeTxt.offsetHeight + 32))
        }px`;
      }, 100);
    }
  });

  return (
    <>
      <div className='absolute inset-0 z-0 bg-black bg-opacity-30'></div>

      <div className='relative z-1'>
        {/* main start */}
        <div className='px-4'>
          {/* main head start */}

          <div id='welcome-txt' className='mb-4'>
            <p className='text-slg text-white font-ibm font-medium text-center'>{`Hi ${
              userInfo?.data?.first_name || ''
            } ${userInfo?.data?.last_name || ''},`}</p>
            <p className='text-slg text-white font-ibm font-medium text-center'>
              Welcome to the future of pension administration!
            </p>
          </div>

          {/* main head end */}

          <div id='dashboard-panel' className='p-4'>
            <div className='w-full flex gap-10 h-full'>
              {/* left side start*/}
              <div className='w-2/5 flex flex-col justify-between'>
                <div className='mb-[18px] px-6 py-10 rounded-2xl bg-black bg-opacity-50 backdrop-blur-lg '>
                  <div className='w-fit mx-auto mb-10 w-36 h-36 aspect-square overflow-hidden rounded-full bg-light-gray bg-opacity-75'>
                    <img
                      className='w-36 h-36 object-cover'
                      src='/assets/images/piddy-bot.gif'
                      alt='Piddy Bot'
                    />
                  </div>

                  <div className='mx-8'>
                    <p className='text-md font-medium text-white text-center'>
                      I&apos;m Piddy, your digital navigator in the world of
                      pensions. Whether you&apos;re planning for a future of
                      leisure or managing the intricacies of retirement plans,
                      I&apos;m here to streamline your journey. How may I assist
                      you today?
                    </p>
                  </div>
                </div>

                <div className='flex flex-col gap-6'>
                  <div>
                    <span className='block  text-xxs font-normal text-white'>
                      Suggestions:
                    </span>

                    <div className='p-2 flex gap-4 flex-wrap'>
                      <FormSubmit
                        className='secondary-btn-white'
                        buttonText='Open my Pension Calculator'
                      />
                      <FormSubmit
                        className='secondary-btn-white'
                        buttonText='Back to Dashboard'
                      />
                      <FormSubmit
                        className='primary-btn'
                        buttonText='Apply for Retirement'
                      />
                    </div>
                  </div>
                  <ChatbotInput listen={true} />
                  <div>
                    <p className='text-xxs text-white font-normal'>
                      *Piddy® can assist you with all of your pension needs.
                      Just speak or type your request.
                    </p>
                  </div>
                </div>
              </div>
              {/* left side end */}

              {/* right side start */}
              <div className='w-3/5 h-full flex flex-col justify-between'>
                <div className='px-10 py-6 rounded-2xl border border-white bg-black bg-opacity-50 backdrop-blur-lg'>
                  <p className='mb-6 text-lsm font-normal text-white text-center'>
                    Select one of the menu items or use Piddy® to guide you
                  </p>

                  <div className='flex flex-wrap max-w-3xl mx-auto'>
                    <Link
                      to={ROUTE_PATH.MY_PROFILE}
                      className='w-1/3 mb-4 flex flex-col items-center'
                    >
                      <div className='p-4'>
                        <img
                          src='/assets/images/icons/user-circle.svg'
                          alt='Calculator'
                        />
                      </div>
                      <p className='text-xxs font-semibold text-white text-center'>
                        Profile
                      </p>
                    </Link>

                    <Link
                      to={ROUTE_PATH.PENSION_CALCULATOR}
                      className='w-1/3 mb-4 flex flex-col items-center'
                    >
                      <div className='p-4'>
                        <img
                          src='/assets/images/icons/calculator.svg'
                          alt='Calculator'
                        />
                      </div>
                      <p className='text-xxs font-semibold text-white text-center'>
                        Pension Calculator
                      </p>
                    </Link>

                    <Link
                      to={ROUTE_PATH.SERVICE_RECORD}
                      className='w-1/3 mb-4 flex flex-col items-center'
                    >
                      <div className='p-4'>
                        <img
                          src='/assets/images/icons/notepad.svg'
                          alt='Record'
                        />
                      </div>
                      <p className='text-xxs font-semibold text-white text-center'>
                        Service Record
                      </p>
                    </Link>

                    <Link
                      to={ROUTE_PATH.BUYBACK_CALCULATOR}
                      className='w-1/3 mb-4 flex flex-col items-center'
                    >
                      <div className='p-4'>
                        <img
                          src='/assets/images/icons/calculator.svg'
                          alt='Calculator'
                        />
                      </div>
                      <p className='text-xxs font-semibold text-white text-center'>
                        Buyback Calculator
                      </p>
                    </Link>

                    {userInfo?.data?.is_applicable_for_retirement && (
                      <Link
                        to={ROUTE_PATH.APPLICATION_FORM}
                        className='w-1/3 mb-4 flex flex-col items-center'
                      >
                        <div className='p-4'>
                          <img
                            src='/assets/images/icons/notepad.svg'
                            alt='Application'
                          />
                        </div>
                        <p className='text-xxs font-semibold text-white text-center'>
                          Retirement Application
                        </p>
                      </Link>
                    )}

                    <Link
                      to={ROUTE_PATH.DOCUMENT}
                      className='w-1/3 mb-4 flex flex-col items-center'
                    >
                      <div className='p-4'>
                        <img
                          src='/assets/images/icons/spreadsheet.svg'
                          alt='Application'
                        />
                      </div>
                      <p className='text-xxs font-semibold text-white text-center'>
                        Document Center
                      </p>
                    </Link>
                  </div>
                </div>

                <p className='text-xxs font-normal text-white text-end '>
                  © {new Date().getFullYear()} PensionID.com
                </p>
              </div>
            </div>
            {/* right side end */}
          </div>
        </div>
      </div>
      {/* main end */}
    </>
  );
};

export default Dashboard;
