import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Dashboard from './pages/dashboard';
import ServiceRecord from './pages/serviceRecord';
import PensionCalculator from './pages/pensionCalculator';
import PublicRoute from './components/PublicRoute/PublicRoute';
import Login from './pages/login';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import MyProfile from './pages/profile';
import BuyBackCalculator from './pages/buyBackCalculator';
import Documents from './pages/documents';
import CreateScenario from './pages/createScenario';
import 'react-toastify/dist/ReactToastify.css';
import CompareScenario from './pages/compareScenario';
import ChangePassword from './pages/changePassword';
import ApplicationForm from './pages/applicationForm';
import {
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
} from './helper/constant';
import { setScreenName } from './features/chatbot/chatbot';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;

  useEffect(() => {
    if (pathname === ROUTE_PATH.HOME) {
      dispatch(setScreenName(SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.HOME));
    } else if (pathname === ROUTE_PATH.MY_PROFILE) {
      dispatch(setScreenName(SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.MY_PROFILE));
    } else if (pathname === ROUTE_PATH.DOCUMENT) {
      dispatch(setScreenName(SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.MY_DOCUMENTS));
    } else if (pathname === ROUTE_PATH.CREATE_SCENARIO) {
      dispatch(
        setScreenName(SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.CREATE_SCENARIO)
      );
    } else if (pathname === ROUTE_PATH.COMPARE_SCENARIO) {
      dispatch(
        setScreenName(SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.COMPARE_SCENARIO)
      );
    } else if (pathname === ROUTE_PATH.CHANGE_PASSWORD) {
      dispatch(
        setScreenName(SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.CHANGE_PASSWORD)
      );
    }
  }, [pathname]);

  return (
    <div className='App'>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route index path={ROUTE_PATH.HOME} element={<Dashboard />} />
          <Route path={ROUTE_PATH.SERVICE_RECORD} element={<ServiceRecord />} />
          <Route
            path={ROUTE_PATH.PENSION_CALCULATOR}
            element={<PensionCalculator />}
          />
          <Route
            path={ROUTE_PATH.BUYBACK_CALCULATOR}
            element={<BuyBackCalculator />}
          />
          <Route path={ROUTE_PATH.MY_PROFILE} element={<MyProfile />} />
          <Route path={ROUTE_PATH.DOCUMENT} element={<Documents />} />
          <Route
            path={ROUTE_PATH.CREATE_SCENARIO}
            element={<CreateScenario />}
          />
          <Route
            path={`${ROUTE_PATH.CREATE_SCENARIO}/:id`}
            element={<CreateScenario />}
          />
          <Route
            path={`${ROUTE_PATH.EDIT_SCENARIO}/:id`}
            element={<CreateScenario />}
          />
          <Route
            path={`${ROUTE_PATH.COMPARE_SCENARIO}/:id?`}
            element={<CompareScenario />}
          />
          <Route
            path={ROUTE_PATH.CHANGE_PASSWORD}
            element={<ChangePassword />}
          />
          <Route
            path={ROUTE_PATH.APPLICATION_FORM}
            element={<ApplicationForm />}
          />
        </Route>

        <Route element={<PublicRoute />}>
          <Route path={ROUTE_PATH.LOGIN} element={<Login />} />
          <Route
            path={ROUTE_PATH.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route
            path={`${ROUTE_PATH.PASSWORD_RESET}/:id?`}
            element={<ResetPassword />}
          />
        </Route>
      </Routes>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={true}
      />
    </div>
  );
}


export default App;
