import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ChatbotInput from '../ChatbotInput/ChatbotInput';
import { ROUTE_PATH } from '../../helper/constant';

const Sidebar = () => {
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.user);

  return (
    <div className='w-1/7 flex flex-col flex-shrink-0 justify-between border-r border-r-bord-g10 min-h-full'>
      <ul className='px-2 flex flex-col gap-2'>
        <li>
          <Link
            to={ROUTE_PATH.MY_PROFILE}
            className={`block p-2 text-xxs font-medium ${
              location.pathname === ROUTE_PATH.MY_PROFILE
                ? 'text-white bg-primary'
                : 'text-neutral-gray'
            } rounded-lmd duration-500`}
          >
            Profile
          </Link>
        </li>
        <li>
          <Link
            to={ROUTE_PATH.SERVICE_RECORD}
            className={`block p-2 text-xxs font-medium ${
              location.pathname === ROUTE_PATH.SERVICE_RECORD
                ? 'text-white bg-primary'
                : 'text-neutral-gray'
            } rounded-lmd duration-500`}
          >
            Service Record
          </Link>
        </li>
        <li>
          <Link
            to={ROUTE_PATH.PENSION_CALCULATOR}
            className={`block p-2 text-xxs font-medium ${
              location.pathname === ROUTE_PATH.PENSION_CALCULATOR
                ? 'text-white bg-primary'
                : 'text-neutral-gray'
            } rounded-lmd duration-500`}
          >
            Pension Calculator
          </Link>
        </li>
        <li>
          <Link
            to={ROUTE_PATH.BUYBACK_CALCULATOR}
            className={`block p-2 text-xxs font-medium ${
              location.pathname === ROUTE_PATH.BUYBACK_CALCULATOR
                ? 'text-white bg-primary'
                : 'text-neutral-gray'
            } rounded-lmd duration-500`}
          >
            Buyback Calculator
          </Link>
        </li>
        {userInfo?.data?.is_applicable_for_retirement && (
          <li>
            <Link
              to={ROUTE_PATH.APPLICATION_FORM}
              className={`block p-2 text-xxs font-medium ${
                location.pathname === ROUTE_PATH.APPLICATION_FORM
                  ? 'text-white bg-primary'
                  : 'text-neutral-gray'
              } rounded-lmd duration-500`}
            >
              Retirement Application
            </Link>
          </li>
        )}
        <li>
          <Link
            to={ROUTE_PATH.DOCUMENT}
            className={`block p-2 text-xxs font-medium ${
              location.pathname === ROUTE_PATH.DOCUMENT
                ? 'text-white bg-primary'
                : 'text-neutral-gray'
            } rounded-lmd duration-500`}
          >
            Document Center
          </Link>
        </li>
      </ul>

      <div className='pt-2 border-t border-t-bord-g10'>
        <span className='block text-xxs font-normal text-neutral-gray'>
          Suggestions:{' '}
        </span>

        <div className='py-4 flex flex-col gap-4'>
          <button className='primary-btn'>Create New Scenario</button>
          <button className='secondary-btn-blue'>Compare Scenarios</button>
        </div>

        <div className='mt-4 pr-2'>
          <ChatbotInput listen={true} sidebar={true} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
