import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label, Radio } from 'flowbite-react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDatePicker, FormSubmit } from '../Generic';
import { radioButtonTheme, STORAGE_KEYS } from '../../helper';
import {
  handleCSSEffect,
  toastMessage,
  updatePensionApplicationFormStep,
} from '../../helper/function';
import {
  FIELDS_UPDATE_EFFECT_TIMEOUT,
  LIFETIME_GUARANTEE_PERIOD_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import axiosInstance from '../../api/axiosInstance';
import { setDataForChatBot } from '../../features/chatbot/chatbot';

const Elections = ({ tabHandler }) => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.user);
  const { screenName, updatedDataFromChatBot, wantToUpdateData } = useSelector(
    (state) => state.chatbot
  );
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const lastActiveStep = pensionApplicationFormData?.step;

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      date_of_retirement: '',
    },
  });
  const formData = watch();

  const [activePackage, setActivePackage] = useState(0);
  const [survivorPensionPackage, setSurvivorPensionPackage] = useState([]);
  const [pensionGuaranteePeriod, setPensionGuaranteePeriod] = useState(
    LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
  );
  const [selectedDate, setSelectedDate] = useState('');
  const [aiUpdatedDataEffectFields, setAiUpdatedDataEffectFields] = useState(
    []
  );
  
  const onSubmit = async (data) => {
    try {
      if (data?.date_of_retirement) {
        const response = await axiosInstance.get(
          '/retirement/form/verify/retirement-date',
          {
            params: {
              retirement_date: data?.date_of_retirement,
            },
          }
        );
        // toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
        if (response?.data?.data) {
          setSurvivorPensionPackage(response?.data?.data);
        } else {
          setSurvivorPensionPackage([]);
        }
        setSelectedDate(data?.date_of_retirement);
      }
    } catch (error) {
      console.error('error', error);
      setSurvivorPensionPackage([]);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while verifying retirement date'
      );
    }
  };

  const handleNextButton = async () => {
    try {
      if (!formData?.date_of_retirement) {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          'Please select a date of retirement'
        );
        return;
      }
      if (
        !survivorPensionPackage?.length &&
        (userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.MARRIED ||
          userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.DIVORCED)
      ) {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          'Please verify date then select a survivor pension package'
        );
        return;
      }

      await updatePensionApplicationFormStep({
        survivor_pension_percentage:
          survivorPensionPackage[activePackage]?.percentage,
        lifetime_guarantee_period: pensionGuaranteePeriod,
        step:
          pensionApplicationFormData?.step ===
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
            ? PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO
            : undefined,
      });
      if (
        lastActiveStep ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
      ) {
        await updatePensionApplicationFormStep({
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO,
        });
      }
      tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO);
    } catch (error) {
      console.error('error', error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while submitting election'
      );
    }
  };

  const getElectionsData = async () => {
    try {
      const response = await axiosInstance.get('retirement/form/get/data', {
        params: {
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS,
        },
      });
      if (response?.data?.data) {
        setValue('date_of_retirement', response?.data?.data?.retirement_date);
        setSelectedDate(response?.data?.data?.retirement_date);
        if (
          response?.data?.data?.retirement_date &&
          response?.data?.data?.survivor_pension_percentage
        ) {
          setSurvivorPensionPackage(
            response?.data?.data?.survivor_pension_values
          );
          setPensionGuaranteePeriod(
            response?.data?.data?.lifetime_gaurentee_option?.toString()
          );
          setActivePackage(
            response?.data?.data?.survivor_pension_values?.findIndex(
              (item) =>
                item?.percentage ===
                response?.data?.data?.survivor_pension_percentage
            )
          );
        }
      }
    } catch (error) {
      console.error('error', error);
      setSurvivorPensionPackage([]);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while getting survivor pension package'
      );
    }
  };

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS
    ) {
      getElectionsData();
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS
    ) {
      dispatch(
        setDataForChatBot({
          elections_form: {
            date: selectedDate,
            survivor_pension: survivorPensionPackage,
            lifetime_guaranteed_option: {
              ten_year_guaranteed_option:
                pensionGuaranteePeriod ===
                LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS,
              no_lifetime_guaranteed_option:
                pensionGuaranteePeriod ===
                LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE,
            },
            selected_survivor_pension: survivorPensionPackage[activePackage],
          },
          sender: localStorage.getItem(STORAGE_KEYS.token),
          node: 'elections_form',
        })
      );
    }
  }, [
    screenName,
    survivorPensionPackage,
    activePackage,
    pensionGuaranteePeriod,
    selectedDate,
  ]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occur &&
      !updatedDataFromChatBot?.want_submit
    ) {
      if (updatedDataFromChatBot?.is_want_to_verify_date) {
        onSubmit({ date_of_retirement: updatedDataFromChatBot?.data?.date });
      } else {
        const updatedFields = [];
        if (
          updatedDataFromChatBot?.data?.date !== formData?.date_of_retirement
        ) {
          updatedFields.push({
            fieldName: 'date_of_retirement',
            oldValue: formData?.date_of_retirement,
            newValue: updatedDataFromChatBot?.data?.date,
          });
        }

        setValue('date_of_retirement', updatedDataFromChatBot?.data?.date);
        setSelectedDate(updatedDataFromChatBot?.data?.date);
        if (
          updatedDataFromChatBot?.data?.date &&
          updatedDataFromChatBot?.data?.survivor_pension
        ) {
          setSurvivorPensionPackage(
            updatedDataFromChatBot?.data?.survivor_pension
          );
          const resultPensionGuaranteePeriod = updatedDataFromChatBot?.data
            ?.lifetime_guaranteed_option?.ten_year_guaranteed_option
            ? LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS
            : LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE;
          if (resultPensionGuaranteePeriod !== pensionGuaranteePeriod) {
            updatedFields.push({
              fieldName: 'pensionGuaranteePeriod',
              oldValue: pensionGuaranteePeriod,
              newValue: resultPensionGuaranteePeriod,
            });
          }
          setPensionGuaranteePeriod(resultPensionGuaranteePeriod);
          setActivePackage(
            updatedDataFromChatBot?.data?.survivor_pension?.findIndex(
              (item) =>
                item?.percentage ===
                updatedDataFromChatBot?.data?.selected_survivor_pension
                  ?.percentage
            )
          );
        } else {
          setSurvivorPensionPackage([]);
          const resultPensionGuaranteePeriod = updatedDataFromChatBot?.data
            ?.lifetime_guaranteed_option?.ten_year_guaranteed_option
            ? LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS
            : LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE;
          if (resultPensionGuaranteePeriod !== pensionGuaranteePeriod) {
            updatedFields.push({
              fieldName: 'pensionGuaranteePeriod',
              oldValue: pensionGuaranteePeriod,
              newValue: resultPensionGuaranteePeriod,
            });
          }
          setPensionGuaranteePeriod(resultPensionGuaranteePeriod);
        }
        setAiUpdatedDataEffectFields(updatedFields);
      }
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS &&
      !updatedDataFromChatBot?.is_error_occur &&
      (updatedDataFromChatBot?.want_submit || updatedDataFromChatBot?.is_next)
    ) {
      handleNextButton();
    }
  }, [updatedDataFromChatBot, wantToUpdateData]);

  useEffect(() => {
    if (aiUpdatedDataEffectFields.length > 0) {
      setTimeout(() => {
        setAiUpdatedDataEffectFields([]);
      }, FIELDS_UPDATE_EFFECT_TIMEOUT);
    }
  }, [aiUpdatedDataEffectFields]);

  return (
    <>
      <div className='relative  h-full flex flex-col justify-between'>
        <div className='pb-2 sticky top-0  bg-light-gray'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            Elections
          </h2>
        </div>

        <div className='h-[calc(100%_-_50px)] overflow-auto'>
          <div className='p-4 rounded-2xl bg-white shadow-double flex flex-col gap-10'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-6'>
                <div className='pb-2  border-b border-bord-g10 flex justify-between items-center'>
                  <h3 className='text-base font-semibold text-b10'>
                    When would you like to retire ?
                  </h3>
                  <FormSubmit
                    buttonText='Reset All'
                    className={'secondary-btn-blue'}
                    onClick={(e) => {
                      e.preventDefault();
                      setSurvivorPensionPackage([]);
                      setPensionGuaranteePeriod(
                        LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
                      );
                      setSelectedDate('');
                      setActivePackage(0);
                      setValue('date_of_retirement', '');
                    }}
                  />
                </div>
                <div className='mt-4 flex flex-col gap-4'>
                  <span className='block text-lsm font-normal text-b10'>
                    Date
                  </span>
                  <div className='flex gap-7'>
                    <div className='w-64'>
                      <Controller
                        name='date_of_retirement'
                        control={control}
                        rules={{ required: 'Date of Retirement is required' }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomDatePicker
                              value={value}
                              onChange={onChange}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete='off'
                              placeholderText='DD/MM/YYYY'
                              className={handleCSSEffect({
                                stateVariableArray: aiUpdatedDataEffectFields,
                                fieldName: 'date_of_retirement',
                              })}
                            />
                          );
                        }}
                      />
                      {errors?.date_of_retirement?.message && (
                        <p className='text-red-600 text-xxs mt-1'>
                          {errors.date_of_retirement.message}
                        </p>
                      )}
                    </div>
                    <FormSubmit type={'submit'} buttonText={'Verify Date'} />
                  </div>
                </div>
              </div>
            </form>
            {survivorPensionPackage?.length > 0 && (
              <div className='flex flex-col gap-6'>
                <div className='pb-2  border-b border-bord-g10 flex justify-between items-center'>
                  <h3 className='text-base font-semibold text-b10'>Election</h3>
                </div>

                <div className='flex flex-col gap-6'>
                  <div>
                    <p className='text-b10 font-semibold text-lsm'>
                      Survivor Pension
                    </p>
                  </div>
                  <div className='mb-6 grid grid-cols-4 gap-2.5'>
                    {survivorPensionPackage.map((item, index) => (
                      <div
                        key={index}
                        className={`px-3.5 py-2 flex flex-col items-center gap-6 border rounded-2xl duration-500  ${
                          activePackage === index
                            ? 'bg-white border-primary shadow-double'
                            : 'border-transparent'
                        }`}
                      >
                        <FormSubmit
                          onClick={() => setActivePackage(index)}
                          className={`min-w-32 ${
                            activePackage === index
                              ? 'primary-btn border border-transparent'
                              : 'secondary-btn-blue'
                          }`}
                          buttonText={item?.percentage + '%'}
                        />
                        <div className='flex flex-col gap-4'>
                          <p className='text-xxs font-medium text-neutral-gray'>
                            {`${item?.net?.annual} Annually`}
                          </p>
                          <p className='text-xxs font-medium text-neutral-gray'>
                            {`${item?.net?.monthly} Monthly`}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='flex flex-col gap-6'>
                  <div>
                    <p className='text-b10 font-semibold text-lsm'>
                      Lifetime Guaranteed Option
                    </p>
                  </div>
                  <div className='flex items-center gap-8'>
                    <div
                      className={`flex items-center gap-3 ${handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'pensionGuaranteePeriod',
                        value: LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE,
                      })}`}
                    >
                      <Radio
                        theme={radioButtonTheme}
                        id='none'
                        name='survivor-pension'
                        value={LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE}
                        onChange={(e) =>
                          setPensionGuaranteePeriod(e.target.value)
                        }
                        checked={
                          pensionGuaranteePeriod ===
                          LIFETIME_GUARANTEE_PERIOD_OPTIONS.NONE
                        }
                      />
                      <Label
                        className='text-lsm font-normal text-b10'
                        htmlFor='none'
                      >
                        None
                      </Label>
                    </div>
                    <div
                      className={`flex items-center gap-3 ${handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'pensionGuaranteePeriod',
                        value: LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS,
                      })}`}
                    >
                      <Radio
                        theme={radioButtonTheme}
                        id='10years'
                        name='survivor-pension'
                        value={LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS}
                        onChange={(e) =>
                          setPensionGuaranteePeriod(e.target.value)
                        }
                        checked={
                          pensionGuaranteePeriod ===
                          LIFETIME_GUARANTEE_PERIOD_OPTIONS.TEN_YEARS
                        }
                      />
                      <Label
                        className='text-lsm font-normal text-b10'
                        htmlFor='10years'
                      >
                        10 Years
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* bottom buttons */}
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE)
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
};
Elections.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};

export default Elections;
