import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axiosInstance from '../../api/axiosInstance';
import { STORAGE_KEYS, formatToReadableDate } from '../../helper';
import {
  toastMessage,
  updatePensionApplicationFormStep,
} from '../../helper/function';
import ConfirmationModal from '../Generic/ConfirmationModal';
import {
  PENSION_APPLICATION_FORM_STEPS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import {
  setDataForChatBot,
  setUpdatedDataFromChatBot,
} from '../../features/chatbot/chatbot';
import BeneficiaryForm from './BeneficiaryForm';

function Beneficiaries({ tabsRef }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;

  const {
    screenName,
    updatedDataFromChatBot,
    wantToUpdateData,
    dataForChatBot,
  } = useSelector((state) => state.chatbot);
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );

  const [beneficiariesInfo, setBeneficiariesInfo] = useState([]);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(null);
  const [openBeneficiaryForm, setOpenBeneficiaryForm] = useState(false);
  const [editBeneficiaryData, setEditBeneficiaryData] = useState(null);

  const getBeneficiariesInfo = async () => {
    try {
      const response = await axiosInstance('profile/beneficiaries');
      setBeneficiariesInfo(response?.data?.data);
    } catch (err) {
      console.error('error while fetching deneficiaries', err);
    }
  };

  const deleteBeneficiaryHandler = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `profile/beneficiaries/${id}`
      );
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
      }
      getBeneficiariesInfo();
    } catch (err) {
      console.error('error while deleting deneficiaries', err);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        err?.response?.data?.meta?.message ||
          'Error while deleting beneficiaries'
      );
    } finally {
      setSelectedBeneficiaryId(null);
    }
  };

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES ||
      screenName === SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.MY_PROFILE
    ) {
      getBeneficiariesInfo();
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES
    ) {
      dispatch(
        setDataForChatBot({
          beneficiary_information:
            beneficiariesInfo?.map((item) => ({
              ...item,
              street: item?.address_line_1,
              dob: item?.date_of_birth,
              beneficiary_percentage: item?.percentage,
              zip: item?.zip_code,
              zip_code: undefined,
              is_form_open: false,
              is_edit_mode: false,
            })) || [],
          sender: localStorage.getItem(STORAGE_KEYS.token),
          node: 'beneficiary_information',
        })
      );
    }
  }, [screenName, beneficiariesInfo]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit
    ) {
      const openFormData =
        updatedDataFromChatBot?.data?.beneficiary_information?.find(
          (item) => item.is_form_open
        );
      if (
        openFormData?.is_form_open &&
        !openFormData?.is_edit_mode &&
        !editBeneficiaryData
      ) {
        setOpenBeneficiaryForm(true);
        dispatch(
          setDataForChatBot({
            beneficiary_information:
              updatedDataFromChatBot?.data?.beneficiary_information || [],
            sender: localStorage.getItem(STORAGE_KEYS.token),
            node: 'beneficiary_information',
          })
        );
      } else if (
        openFormData?.is_form_open &&
        openFormData?.is_edit_mode &&
        !editBeneficiaryData
      ) {
        setOpenBeneficiaryForm(true);
        setEditBeneficiaryData(openFormData);
        dispatch(
          setDataForChatBot({
            beneficiary_information:
              updatedDataFromChatBot?.data?.beneficiary_information || [],
            sender: localStorage.getItem(STORAGE_KEYS.token),
            node: 'beneficiary_information',
          })
        );
      }
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  useEffect(() => {
    const changeScreen = async () => {
      if (
        screenName ===
          SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES &&
        (updatedDataFromChatBot?.want_submit ||
          updatedDataFromChatBot?.is_next ||
          updatedDataFromChatBot?.is_previous) &&
        Object.values(dataForChatBot?.beneficiary_information)?.length > 0
      ) {
        if (updatedDataFromChatBot?.is_next) {
          const updatedPensionApplicationFormData = await axiosInstance.get(
            'retirement/form/get'
          );
          const lastUpdatedStep =
            updatedPensionApplicationFormData?.data?.data?.step;
          if (
            pathname === ROUTE_PATH.APPLICATION_FORM &&
            (pensionApplicationFormData?.step ===
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES ||
              lastUpdatedStep ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES)
          ) {
            await updatePensionApplicationFormStep({
              step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES,
            });
          }
          tabsRef?.current?.setActiveTab(4);
        } else if (updatedDataFromChatBot?.is_previous) {
          tabsRef?.current?.setActiveTab(2);
        }
        if (
          !updatedDataFromChatBot?.is_next &&
          !updatedDataFromChatBot?.is_previous
        ) {
          dispatch(
            setUpdatedDataFromChatBot({
              text: updatedDataFromChatBot?.text,
              redable_text: updatedDataFromChatBot?.redable_text,
            })
          );
        } else {
          dispatch(setUpdatedDataFromChatBot({}));
        }
      }
    };
    changeScreen();
  }, [updatedDataFromChatBot]);

  return (
    <>
      <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
        <h3 className='text-base font-semibold text-b10'>
          Beneficiaries Information
        </h3>
        {/* <img src="/assets/images/icons/edit.svg" alt="Edit" /> */}
      </div>

      <div className='flex flex-col gap-6'>
        <p className='text-lsm font-normal text-b10'>
          Choose a beneficiary to ensure your survivor benefits are distributed
          correctly. Exclude your spouse, as they qualify for a survivor pension
          by default.
        </p>
        <div className='flex justify-between items-center'>
          <p className='text-lsm font-semibold text-black'>
            Designated beneficiaries
          </p>
          <button
            onClick={() => {
              setEditBeneficiaryData(null);
              setOpenBeneficiaryForm(true);
            }}
            className='primary-btn flex gap-3 items-center'
          >
            Add{' '}
            <span>
              <img src={'/assets/images/icons/plus.svg'} alt='icon' />
            </span>
          </button>
        </div>
        {openBeneficiaryForm && !editBeneficiaryData && (
          <BeneficiaryForm
            openBeneficiaryForm={openBeneficiaryForm}
            setOpenBeneficiaryForm={setOpenBeneficiaryForm}
            getBeneficiariesInfo={getBeneficiariesInfo}
            editBeneficiaryData={editBeneficiaryData}
            setEditBeneficiaryData={setEditBeneficiaryData}
          />
        )}
        {beneficiariesInfo?.length > 0
          ? beneficiariesInfo?.map((data, idx) => {
              const {
                first_name,
                last_name,
                percentage,
                date_of_birth,
                apartment,
                address_line_1,
                address_line_2,
                city,
                state,
                country,
                zip_code,
              } = data;
              const hasName = first_name || last_name;
              const hasAddress =
                data?.apartment ||
                data?.address_line_1 ||
                data?.address_line_2 ||
                data?.city ||
                data?.state ||
                data?.country ||
                data?.zip_code;
              return editBeneficiaryData?.id === data?.id ? (
                <BeneficiaryForm
                  key={idx}
                  openBeneficiaryForm={openBeneficiaryForm}
                  setOpenBeneficiaryForm={setOpenBeneficiaryForm}
                  getBeneficiariesInfo={getBeneficiariesInfo}
                  editBeneficiaryData={editBeneficiaryData}
                  setEditBeneficiaryData={setEditBeneficiaryData}
                />
              ) : (
                <div
                  className='p-4 border border-tertiary-gray rounded-lg flex flex-col gap-4'
                  key={idx}
                >
                  <div className='flex justify-between items-center'>
                    {hasName && (
                      <p className='text-lsm font-semibold text-black'>
                        {(first_name || '') + ' ' + (last_name || '')}
                      </p>
                    )}
                    <div className='flex gap-2'>
                      <button
                        onClick={() => {
                          setOpenBeneficiaryForm(true);
                          setEditBeneficiaryData(data);
                        }}
                      >
                        <img
                          src='/assets/images/icons/edit-dark.svg'
                          alt='edit'
                        />
                      </button>
                      <button>
                        <img
                          src='/assets/images/icons/trash.svg'
                          alt='trash'
                          onClick={() => setSelectedBeneficiaryId(data?.id)}
                        />
                      </button>
                    </div>
                  </div>
                  <div className='flex justify-between'>
                    {percentage || percentage === 0 ? (
                      <p className='text-lsm font-normal text-black'>
                        {percentage + '%'}
                      </p>
                    ) : null}
                    {date_of_birth && (
                      <p className='text-lsm font-normal text-black'>
                        {formatToReadableDate(date_of_birth, 'DD/MM/YYYY')}
                      </p>
                    )}
                    {hasAddress && (
                      <p className='text-lsm font-normal text-black'>
                        {(apartment || '') +
                          ', ' +
                          (address_line_1 || '') +
                          ', ' +
                          (address_line_2 || '') +
                          ', ' +
                          (city || '') +
                          ', ' +
                          (state || '') +
                          ', ' +
                          (country || '') +
                          ', ' +
                          (zip_code || '')}
                      </p>
                    )}
                  </div>
                </div>
              );
            })
          : !openBeneficiaryForm && (
              <p className='flex justify-center text-lsm font-normal text-b10'>
                No beneficiaries found
              </p>
            )}
      </div>
      <ConfirmationModal
        open={Boolean(selectedBeneficiaryId)}
        text='Are you sure you want to delete this beneficiary?'
        handleClose={() => setSelectedBeneficiaryId(null)}
        handleSubmit={() => deleteBeneficiaryHandler(selectedBeneficiaryId)}
      />
    </>
  );
}

Beneficiaries.propTypes = {
  tabsRef: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
};

PropTypes.defaultProps = {
  tabsRef: null,
};

export default Beneficiaries;
