import  { useEffect } from 'react'

const useResizeHeight = (elementId) => {
    useEffect(() => {
        const nav = document.getElementById('navbar');
       
        const handleResize = () => {
            const element = document.getElementById(elementId);
            const mainPanel = document.getElementById('mainPanel');
            
            mainPanel.style.height = `${window.innerHeight - (nav.offsetHeight + 48)}px`;
            if (element) {
                element.style.height = `${window.innerHeight - (nav.offsetHeight + 48)}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
}

export default useResizeHeight


