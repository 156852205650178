import React from 'react';
import PropTypes from 'prop-types';

const SummaryCard = ({ summaryData }) => {
  return (
    <div className='bg-white p-4 rounded-2xl shadow-single flex-grow'>
      <div className='mb-4 pb-2.5 border-b border-b-bord-g20 '>
        <p className='text-lbase font-semibold text-blue-900'>Summary</p>
      </div>
      <div className=' flex flex-col gap-3 '>
        <div className='flex justify-between items-center'>
          <p className='text-sm font-normal text-neutral-gray'>
            {summaryData?.year}:
          </p>
          <p className='text-md font-medium text-blue-800'>
            {summaryData?.yearly_contribution}
          </p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-sm font-normal text-neutral-gray'>
            Total year to date:
          </p>
          <p className='text-md font-medium text-blue-800'>
            {summaryData?.total_contribution}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
SummaryCard.propTypes = {
  summaryData: PropTypes.shape({
    total_contribution: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    yearly_contribution: PropTypes.string.isRequired,
  }),
};
SummaryCard.defaultProps = {
  summaryData: {
    total_contribution: '',
    year: '',
    yearly_contribution: '',
  },
};