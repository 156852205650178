import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormSubmit } from '../Generic';
import {
  PENSION_APPLICATION_FORM_STEPS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import { formatToReadableDate } from '../../helper';
import { setUpdatedDataFromChatBot } from '../../features/chatbot/chatbot';

const Completed = ({ tabHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { screenName, updatedDataFromChatBot } = useSelector(
    (state) => state.chatbot
  );

  const [completedData, setCompletedData] = useState({});

  const getCompletedData = async () => {
    try {
      const response = await axiosInstance.get('retirement/form/get/data', {
        params: {
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH,
        },
      });
      setCompletedData(response.data.data);
    } catch (error) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while getting completed data'
      );
      console.error('Error while getting completed data', error);
    }
  };
  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE
    ) {
      getCompletedData();
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE &&
      updatedDataFromChatBot?.is_next
    ) {
      navigate(ROUTE_PATH.HOME);
      dispatch(setUpdatedDataFromChatBot({}));
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE &&
      updatedDataFromChatBot?.is_previous
    ) {
      tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW);
      dispatch(setUpdatedDataFromChatBot({}));
    }
  }, [updatedDataFromChatBot]);

  return (
    <>
      <div className='relative  h-full flex flex-col justify-between  '>
        <div className='pb-2'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            Review
          </h2>
        </div>
        <div className='h-[calc(100%_-_50px)] overflow-auto'>
          <div className=' p-5 rounded-2xl bg-white shadow-double'>
            <div className='flex items-center justify-center'>
              <div className='border border-x-2 border-y-2 rounded border-[#666666] my-14 p-3'>
                <h2 className='text-xxl text-center font-ibm font-semibold text-neutral-gray'>
                  ***RETIREMENT APPLICATION COMPLETED***
                </h2>
              </div>
            </div>
            <div className='flex justify-between border-t border-b border-bord-g10 py-4 mx-3 text-xs font-medium text-neutral-gray'>
              <div>
                <p>
                  Submitted Date:{' '}
                  {formatToReadableDate(
                    completedData?.submitted_date,
                    'YYYY-MM-DD'
                  )}
                </p>
                <p>Submitted By: {completedData?.submitted_by}</p>
              </div>
              <p className=''>
                Document No. #: {completedData?.document_no || '821D-1I37-9P35'}
              </p>
            </div>
            <div className='flex justify-end mt-5'>
              <FormSubmit
                icon={'/assets/images/icons/copy.svg'}
                buttonText='Download PDF'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW)
          }
        />
        <FormSubmit
          buttonText={'Go To Dashboard'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={() => navigate(ROUTE_PATH.HOME)}
        />
      </div>
    </>
  );
};

Completed.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};

export default Completed;
