import React from 'react';

function FullScreenLoader() {
  return (
    <div className='w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center'>
      <div className='inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-primary border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white'></div>
    </div>
  );
}

export default FullScreenLoader;
