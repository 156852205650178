import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import { ROUTE_PATH, TOAST_MESSAGE_TYPE } from '../../helper/constant';

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch('password');
  const navigate = useNavigate();
  const { id } = useParams();
  const email = new URLSearchParams(location.search).get('email');

  const resetPasswordHandler = async (data) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post('password/reset', {
        token: id.trim(),
        email: email.trim(),
        password: data?.password?.trim(),
        password_confirmation: data?.confirmPassword?.trim(),
      });
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
      }
      setIsLoading(false);
      navigate(ROUTE_PATH.LOGIN);
    } catch (error) {
      setIsLoading(false);
      console.error('error while resting password', error);
      toastMessage(TOAST_MESSAGE_TYPE.ERROR, error?.response?.data?.meta?.message);
    }
  };
  const validateConfirmPassword = (value) => {
    return value === password || 'Password and Confirm password must be same';
  };

  useEffect(() => {
    if (!id || !email) {
      navigate(ROUTE_PATH.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, email]);

  return (
    <div className="bg-cover bg-center bg-no-repeat bg-auth-panel h-screen w-screen">
      <div className="p-4 h-screen w-screen flex items-center justify-center">
        <div className="p-8 bg-black bg-opacity-60 backdrop-blur-lg rounded-4xl max-w-md border border-white ">
          <form noValidate onSubmit={handleSubmit(resetPasswordHandler)}>
            <div className="flex flex-col items-center gap-7">
              <div>
                <img
                  src="/assets/images/pension-id-logo.svg"
                  alt="PENSION ID"
                />
              </div>

              <p className="text-white text-xl font-semibold">Reset Password</p>

              <div className="w-full">
                {/* password */}
                <div className="mb-6">
                  <label className="block text-white text-lsm font-normal mb-2">
                    New Password
                  </label>
                  <input
                    type="password"
                    placeholder="Password"
                    {...register('password', {
                      required: 'Password is required.',
                      validate: {
                        uppercase: (value) =>
                          /[A-Z]/.test(value) ||
                          'Password should contain at least one uppercase letter.',
                        lowercase: (value) =>
                          /[a-z]/.test(value) ||
                          'Password should contain at least one lowercase letter.',
                        digit: (value) =>
                          /[0-9]/.test(value) ||
                          'Password should contain at least one digit.',
                        special: (value) =>
                          /[!@#$*]/.test(value) ||
                          'Password should contain at least one special symbol.',
                      },
                      minLength: {
                        value: 6,
                        message: 'Password should be at-least 6 characters.',
                      },
                    })}
                    className="w-full p-3 bg-white border border-white text-lsm font-normal text-black focus:outline-none focus:border-primary duration-500 rounded-lg min-w-96 placeholder:text-neutral-gray"
                  />
                  {errors.password && (
                    <p className="text-red-600 text-xxs mt-1">
                      {errors.password.message}
                    </p>
                  )}
                </div>

                {/* confirm password */}
                <div className="">
                  <label className="block text-white text-lsm font-normal mb-2">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    {...register('confirmPassword', {
                      required: 'Password is required.',
                      validate: {
                        validateConfirmPassword,
                      },
                      minLength: {
                        value: 6,
                        message: 'Password should be at-least 6 characters.',
                      },
                    })}
                    className="w-full p-3 bg-white border border-white text-lsm font-normal text-black focus:outline-none focus:border-primary duration-500 rounded-lg min-w-96 placeholder:text-neutral-gray"
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-600 text-xxs mt-1">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              </div>

              <button
                disabled={isLoading}
                type="submit"
                className="font-ibm py-3 px-6 rounded-80x bg-primary text-white text-lsm font-semibold min-w-32 text-center"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
