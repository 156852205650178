import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  setIsChatBotProcessing,
  setRedirectScreenStep,
  setRedirectScreenTab,
  setUpdatedDataFromChatBot,
} from '../../features/chatbot/chatbot';
import { ROUTE_PATH } from '../../helper/constant';
import { eventTypeFromPathName } from '../../helper/function';

const ChatbotInput = ({ listen, sidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;
  
  const {
    dataForChatBot,
    // screenName,
    isChatBotProcessing,
    isSpeecToTextProcessing,
  } = useSelector((state) => state.chatbot);

  const [tempFinalTranscript, setTempFinalTranscript] = useState('');
  const [finalTranscript, setFinalTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [doAPICall, setDoAPICall] = useState(false);
  const recognitionRef = useRef(null);
  const timeoutRef = useRef(null);

  const forceStopMicrophone = useCallback(() => {
    if (isListening && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop();
            stream.removeTrack(track);
          });
        })
        .catch((err) => console.error('Error accessing microphone:', err));
    }
    
    // Attempt to revoke microphone permission
    if (navigator.permissions && navigator.permissions.revoke) {
      navigator.permissions
        .revoke({ name: 'microphone' })
        .then(() => console.log('Microphone permission revoked'))
        .catch((err) =>
          console.error('Error revoking microphone permission:', err)
        );
    }
  }, []);

  const stopListening = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current.abort();
        recognitionRef.current = null;
      }
      setIsListening(false);
      forceStopMicrophone();
      setDoAPICall(true);
    }, 500); // 0.5 second delay
  }, [forceStopMicrophone]);

  const startListening = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error(
        'Your browser does not support Speech Recognition. Please use Chrome.'
      );
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = (event) => {
      let interimTranscript = '';
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptPart = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcriptPart + ' ';
          
          timeoutRef.current = setTimeout(() => {
            stopListening();
          }, 3500); // 3.5 second delay
        } else {
          interimTranscript += transcriptPart;
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        }
      }

      setTempFinalTranscript(finalTranscript);
      setInterimTranscript(interimTranscript);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error detected: ', event);
      stopListening();
    };

    recognition.onend = () => {
      stopListening();
    };

    recognitionRef.current = recognition;
    recognition.start();
    setIsListening(true);
  }, [stopListening]);

  useEffect(() => {
    if (tempFinalTranscript.trim().length > 0) {
      setFinalTranscript(prev => prev + tempFinalTranscript);
    }
  }, [tempFinalTranscript]);

  useEffect(() => {
    return () => {
      stopListening();
    };
  }, [stopListening]);

  // Force microphone release on component unmount
  useEffect(() => {
    return () => {
      if (isListening && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop());
          })
          .catch((err) => console.error('Error accessing microphone:', err));
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      stopListening();
      forceStopMicrophone();
    };
  }, [stopListening, forceStopMicrophone]);

  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      startListening();
    } catch (err) {
      console.error('Microphone permission denied:', err);
    }
  };

  const handleChange = (e) => {
    setFinalTranscript(e.target.value);
  };

  useEffect(() => {
    let didCancel = false;

    if (
      doAPICall &&
      !isListening &&
      finalTranscript.trim().length > 0
    ) {
      dispatch(setIsChatBotProcessing(true));
      axios
        .post(process.env.REACT_APP_AI_WEBHOOK_URL, {
          ...dataForChatBot,
          message: finalTranscript,
          event_type: eventTypeFromPathName(pathname),
          is_not_application_form: pathname === ROUTE_PATH.APPLICATION_FORM ? true : false,
        })
        .then((res) => {
          if (!didCancel) {
            if (res?.data?.route_url) {
              navigate(res?.data?.route_url);
              dispatch(
                setRedirectScreenStep(
                  res?.data?.step || res?.data?.step === 0
                    ? res?.data?.step
                    : null
                )
              );
              dispatch(setRedirectScreenTab(res?.data?.tab || null));
            } else {
              dispatch(setUpdatedDataFromChatBot(res?.data || {}));
            }
          }
        })
        .catch((err) => {
          if (!didCancel) {
            console.error(err);
          }
        })
        .finally(() => {
          if (!didCancel) {
            setDoAPICall(false);
            dispatch(setIsChatBotProcessing(false));
            setFinalTranscript('');
          }
        });
    } else if (
      doAPICall &&
      !isListening &&
      (finalTranscript.trim().length === 0 || !finalTranscript)
    ) {
      setDoAPICall(false);
    }

    return () => {
      didCancel = true;
    };
  }, [doAPICall]);

  return (
    <div>
    <div className='w-full flex gap-2 items-center relative'>
      <div
        className={`absolute left-0 top-1/2 -translate-y-1/2 ${
          sidebar ? 'w-16 h-16' : 'w-20 h-20'
        }  rounded-full aspect-square bg-light-gray border-2 border-primary`}
      >
        <img
          src='/assets/images/piddy-bot.gif'
          className='w-full'
          alt='Piddy Bot'
        />
      </div>
      <textarea
        type='textarea'
        className={`block w-full flex-grow ml-10 p-3 h-[100px] resize-none ${
          sidebar ? 'pl-7' : 'pl-12'
        }  bg-transparent font-medium rounded-lg border !border-primary focus:ring-0 focus:ring-transparent focus:outline-none ${
          sidebar ? 'text-b10' : 'text-white'
        } `}
        value={finalTranscript + interimTranscript}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setDoAPICall(true);
          }
        }}
        readOnly={
          isListening ||
          isChatBotProcessing ||
          isSpeecToTextProcessing
        }
      />
      <div className='flex flex-col gap-1'>
        {listen && (
          <button
            className='p-0 aspect-square bg-primary text-white rounded-full'
            onClick={() => {
              if (isListening) {
                stopListening();
                setFinalTranscript('');
              } else {
                requestMicrophonePermission();
                setFinalTranscript('');
              }
            }}
          >
            {isListening ? (
              <img src='/assets/images/icons/recording.svg' alt='Recording' />
            ) : (
              <img src='/assets/images/icons/microphone.svg' alt='Microphone' />
            )}
          </button>
        )}
        <button
            onClick={() => setDoAPICall(true)}
            disabled={
              finalTranscript === '' ||
              isListening ||
              isChatBotProcessing ||
              isSpeecToTextProcessing
            }
            className='aspect-square bg-primary text-white rounded-full disabled:!border-0'
          >
            <img src='/assets/images/icons/send-icon.svg' alt='Send' />
          </button>
      </div>
    </div>
    <span className={`text-[14px] text-gray-500 ml-[40px] ${pathname === ROUTE_PATH.HOME ? 'text-white' : ''}`}>
      Version: 1.4.1
    </span>
    </div>
  );
};

ChatbotInput.propTypes = {
  listen: PropTypes.bool.isRequired,
  sidebar: PropTypes.bool,
};

ChatbotInput.defaultProps = {
  sidebar: false,
};

export default ChatbotInput;
