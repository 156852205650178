import React from 'react';
import PropTypes from 'prop-types';

function InfoRow({ label, value, bg }) {
  return (
    <div className={`p-2 flex items-center justify-between ${bg || ''}`}>
      <p className='text-lsm font-normal text-neutral-gray'>{label}</p>
      <p className='text-lsm font-medium text-b10'>{value}</p>
    </div>
  );
}

export default InfoRow;

InfoRow.propTypes = {
  bg: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InfoRow.defaultProps = {
  bg: '',
  label: '',
  value: '',
};
