import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../helper/constant';

const NavUserProfile = ({ profileUrl, username, color }) => {
    const navigate = useNavigate();

  return (
    <div className="flex gap-4 items-center w-fit cursor-pointer" onClick={() => navigate(ROUTE_PATH.MY_PROFILE)}>
      <div className="rounded-full w-10 h-10 aspect-square overflow-hidden">
        <img
          className="object-cover w-100"
          src={profileUrl}
          alt="User Profile"
        />
      </div>
      <p
        className={`text-lsm font-semibold ${
          color === 'dark' ? 'text-black' : 'text-white'
        }`}
      >
        {username}
      </p>
    </div>
  );
};

NavUserProfile.propTypes = {
  profileUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default NavUserProfile;
