import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormSubmit } from '../Generic';
import { toastMessage } from '../../helper/function';
import {
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import axiosInstance from '../../api/axiosInstance';
import { setPensionApplicationFormData } from '../../features/pensionApplicationForm/pensionApplicationFormSlice';

const FilesDocuments = ({ tabHandler }) => {
  const dispatch = useDispatch();

  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const { updatedDataFromChatBot, screenName } = useSelector(
    (state) => state.chatbot
  );

  const handleNextButton = async () => {
    try {
      if (!pensionApplicationFormData?.id) {
        const response = await axiosInstance.post('retirement/form/create');
        if (response?.data?.meta?.message) {
          toastMessage(
            TOAST_MESSAGE_TYPE.SUCCESS,
            response?.data?.meta?.message
          );
          dispatch(setPensionApplicationFormData(response?.data?.data || null));
          tabHandler(
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
          );
        }
      } else {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
        );
      }
    } catch (error) {
      console.error('error while updating personal details', error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES &&
      updatedDataFromChatBot?.is_next
    ) {
      handleNextButton();
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES &&
      updatedDataFromChatBot?.is_previous
    ) {
      tabHandler(
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT
      );
    }
  }, [screenName, updatedDataFromChatBot]);

  return (
    <>
      <div className=' relative  h-full flex flex-col justify-between   '>
        <div className='pb-2'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            Files & Documents
          </h2>
        </div>
        <div className='h-[calc(100%_-_50px)] overflow-auto'>
          <div className='mx-6 p-4 rounded-2xl bg-white shadow-double min-h-[450px]'>
            <div className='pb-2 border-b border-bord-g10 flex justify-between items-center'>
              <h3 className='text-base font-semibold text-b10'>
                Required Files
              </h3>
            </div>

            <div className='mt-8 px-4 flex flex-col gap-6'>
              <div className='w-fit border border-bord-g10 px-3 py-2.5 rounded-lg flex items-center gap-3'>
                <img src='/assets/images/icons/file.svg' alt='File' />
                <p className='text-lsm font-semibold text-neutral-gray'>
                  Birth certificate
                </p>
              </div>
              <div className='w-fit border border-bord-g10 px-3 py-2.5 rounded-lg flex items-center gap-3'>
                <img src='/assets/images/icons/file.svg' alt='File' />
                <p className='text-lsm font-semibold text-neutral-gray'>
                  Marriage certificate
                </p>
              </div>
              <div className='w-fit border border-bord-g10 px-3 py-2.5 rounded-lg flex items-center gap-3'>
                <img src='/assets/images/icons/file.svg' alt='File' />
                <p className='text-lsm font-semibold text-neutral-gray'>
                  Spouse’s Birth certificate *if applicable
                </p>
              </div>
              <div className='w-fit border border-bord-g10 px-3 py-2.5 rounded-lg flex items-center gap-3'>
                <img src='/assets/images/icons/file.svg' alt='File' />
                <p className='text-lsm font-semibold text-neutral-gray'>
                  Passport or driver’s license
                </p>
              </div>
              <div className='w-fit border border-bord-g10 px-3 py-2.5 rounded-lg flex items-center gap-3'>
                <img src='/assets/images/icons/file.svg' alt='File' />
                <p className='text-lsm font-semibold text-neutral-gray'>
                  Bank information for direct deposit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
};

FilesDocuments.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};

export default FilesDocuments;
