import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FileInput } from 'flowbite-react';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../api/axiosInstance';
import { fetchUserInfo } from '../../features/user/userThunk';
import { toastMessage } from '../../helper/function';
import { TOAST_MESSAGE_TYPE } from '../../helper/constant';

function ProfileHeaderCard({
  firstName,
  lastName,
  email,
  contactNo,
  state,
  country,
  profilePhotoUrl,
}) {
  const personalFileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleFileInputChange = (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'File size should be less than 2MB');
          return;
        }
        const formData = new FormData();
        formData.append('profile', file);

        axiosInstance
          .post('update/profile/picture', formData)
          .then((response) => {
            if (response?.data?.data?.profile) {
              dispatch(fetchUserInfo());
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='mb-6 flex gap-4 items-center'>
      <FileInput
        ref={personalFileInputRef}
        className='hidden'
        id='personal-photo-upload'
        onChange={handleFileInputChange}
        accept={['image/jpeg', 'image/png', 'image/jpg']}
      />
      <div className='relative w-20 h-20'>
        <div className='w-20 h-20 rounded-full overflow-hidden'>
          <img
            src={profilePhotoUrl || '/assets/images/user-pfp.jpeg'} 
            alt='profile'
            className='w-full object-cover'
          />
        </div>
        <button
          className='absolute bottom-[5px] right-[5px] bg-white rounded-full p-0.5 shadow-md transform translate-x-1/4 translate-y-1/4'
          aria-label='Edit profile picture'
          onClick={() => personalFileInputRef.current.click()}
        >
          <img src='/assets/images/icons/edit.svg' alt='Edit' />
        </button>
      </div>
      <div className='flex flex-col gap-4'>
        {(firstName || lastName) && (
          <h3 className='text-md font-semibold text-b10'>
            {(firstName || '') + ' ' + (lastName || '')}
          </h3>
        )}
        <div className='flex gap-4'>
          <Link
            to='mailto:alfonso.b@gmail.com'
            className='flex gap-2 items-center'
          >
            <span className='block'>
              <img src='/assets/images/icons/detail.svg' alt='Mail' />
            </span>
            {email && (
              <span className='text-lsm font-normal text-neutral-gray'>
                {email || ''}
              </span>
            )}
          </Link>
          <Link to='tel:2132261132' className='flex gap-2 items-center'>
            <span className='block'>
              <img src='/assets/images/icons/phone.svg' alt='Phone' />
            </span>
            {contactNo && (
              <span className='text-lsm font-normal text-neutral-gray'>
                {contactNo}
              </span>
            )}
          </Link>
          <Link to='#' className='flex gap-2 items-center'>
            <span className='block'>
              <img
                src='/assets/images/icons/location-plus.svg'
                alt='Location'
              />
            </span>
            {(state || country) && (
              <span className='text-lsm font-normal text-neutral-gray'>
                {(state || '') + ' ' + (country || '')}
              </span>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}

ProfileHeaderCard.propTypes = {
  contactNo: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  state: PropTypes.string,
  profilePhotoUrl: PropTypes.string,
};
PropTypes.defaultProps = {
  contactNo: '',
  country: '',
  email: '',
  firstName: '',
  lastName: '',
  state: '',
  profilePhotoUrl: '',
};

export default ProfileHeaderCard;
