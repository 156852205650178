import { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';

export const useServiceRecord = () => {
  const [serviceRecord, setServiceRecord] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchServiceRecord = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('service-records');
      setServiceRecord(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('error while fetching service records', error);
    }
  };

  useEffect(() => {
    fetchServiceRecord();
  }, []);

  return {
    serviceRecord,
    loading,
  };
};
