import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const BarChart = ({comparedData}) => {
    const chartRef = useRef(null);

    const labels = comparedData?.graphData?.lables || [];
    const updatedData = comparedData?.graphData?.datasets?.map(item => ({
        ...item,
        borderRadius: Number.MAX_VALUE,
        borderWidth: 0,
    })) || [];

    const data = {
        labels: labels,
        datasets: updatedData
    };

    const options = {
        locale: 'en-US',
        responsive: true,
        categoryPercentage: 0.2,
        barPercentage: 0.5,

        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#1A5866',
                    font: {
                        size: 16,
                        weight: 600
                    }
                },
                grid: {
                    display: false
                }
            },
            y: {
                ticks: {
                    callback: (value) => {
                        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3 }).format(value).replace(/(\$)/, '$ ') + 'K'
                    },
                    color: '#222222',
                    font: {
                        size: 16,
                        weight: 600
                    }
                },
                beginAtZero: true,
                grid: {
                    display: false
                }
            }
        }
    }
    useEffect(() => {
        if (chartRef.current) {

            const chartInstance = chartRef.current;
            const ctx = chartInstance.ctx;
            let yAxis = chartInstance.scales.y;
            let yBottom = yAxis.getPixelForValue(0)
            ctx.save();

            chartInstance.data.datasets.forEach((dataset) => {
                dataset.backgroundColor = dataset.data.map(v => {
                    let yTop = yAxis.getPixelForValue(v);
                    let gradient = ctx.createLinearGradient(0, yTop, 0, yBottom);
                    gradient.addColorStop(0.3, dataset.colorCodes.primary);
                    gradient.addColorStop(1, dataset.colorCodes.secondary);
                    return gradient;
                });
            });
            ctx.restore();
            chartInstance.update();
        }
    }, [chartRef, data]);


    const chartAreaBackground = {
        id: 'chartAreaBackground',
        beforeDatasetsDraw(chart) {
            // eslint-disable-next-line no-unused-vars
            const { ctx, chartArea: { top, bottom, left, right, width, height } } = chart;
            ctx.save();
            const gradient = ctx.createLinearGradient(0, top - 200, 0, bottom);
            gradient.addColorStop(0, '#247F93');
            gradient.addColorStop(0.87, '#0F333B');
            gradient.addColorStop(1, '#0A2429');
            ctx.fillStyle = gradient;
            ctx.fillRect(left, top, width, height);
            ctx.restore();
        }
    }

    return (
        <Bar ref={chartRef} data={data} options={options} plugins={[chartAreaBackground]} />

    );
};
export default BarChart;
BarChart.propTypes = {
  comparedData: PropTypes.shape({
    graphData: PropTypes.shape({
      lables: PropTypes.arrayOf(PropTypes.string),
      datasets: PropTypes.arrayOf(
        PropTypes.shape({
          colorCodes: PropTypes.shape({
            primary: PropTypes.string,
            secondary: PropTypes.string,
          }),
          data: PropTypes.arrayOf(PropTypes.number),
          label: PropTypes.string,
        })
      ),
    }),
    scenarios: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        color: PropTypes.string,
        gross_annual_pension: PropTypes.string,
        pension_guarantee_period: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.oneOf([null]),
        ]),
        retirement_start_date: PropTypes.string,
        survivor_pension_percentage: PropTypes.number,
      })
    ),
  }),
};
BarChart.defaultProps = {
  comparedData: {},
};