import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Sidebar from '../../components/Sidebar/Sidebar';
import useResizeHeight from '../../hooks/useResizeHeight';
// import FormSubmit from '../../components/FormSubmit/FormSubmit'
import { CustomDatePicker, FormInput } from '../../components/Generic';
import ConfirmationModal from '../../components/Generic/ConfirmationModal';
import { setNavbarTitle } from '../../features/navbar/navbarSlice';

const BuyBackCalculator = () => {
  useResizeHeight('dashPanel');
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(setNavbarTitle('Buyback Calculator'));
  }, []);

  return (
    <div className='mt-4 flex gap-2'>
      <Sidebar />
      <div
        id='dashPanel'
        className='w-full p-4 bg-dashboard-panel bg-contain bg-bottom bg-no-repeat overflow-auto'
      >
        <div className='p-6 w-1/2 bg-white rounded-2xl'>
          <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
            <h3 className='text-base font-semibold text-b10'>
              How long will your leave be?
            </h3>
          </div>
          <form>
            <div className='flex flex-col gap-6'>
              <div className='grid grid-cols-2 gap-6'>
                <div className='flex flex-col gap-4'>
                  <span className='block text-lsm font-normal text-b10'>
                    Start Date *
                  </span>
                  <CustomDatePicker
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    autoComplete='off'
                    placeholderText='DD/MM/YYYY'
                    minDate={new Date()}
                  />
                </div>
                <div className='flex flex-col gap-4'>
                  <span className='block text-lsm font-normal text-b10'>
                    End Date *
                  </span>
                  <CustomDatePicker
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    autoComplete='off'
                    placeholderText='DD/MM/YYYY'
                    minDate={new Date()}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  Your Salary*
                </span>
                <FormInput type='text' placeholder='Enter Your Salary' />
              </div>
            </div>
          </form>
          <div className='flex justify-end mt-6'>
            <button className='primary-btn' onClick={() => setOpenModal(true)}>
              Calculate
            </button>
            {/* <FormSubmit type={''} buttonText={'Calculate'} /> */}
          </div>

          <ConfirmationModal
            open={openModal}
            icon='/assets/images/icons/money.svg'
            text='Buyback Amount'
            subText='Your cost to purchase service is $4,001.53'
            handleClose={() => setOpenModal(false)}
            handleSubmit={() => setOpenModal(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyBackCalculator;
