import Sidebar from '../../components/Sidebar/Sidebar';
import useResizeHeight from '../../hooks/useResizeHeight';
import ScenarioListing from '../../components/PensionCalculator/ScenarioListing';
import useScenario from '../../hooks/useScenario';
import ScenarioSection from './ScenarioSection';

const PensionCalculator = () => {
  useResizeHeight('dashPanel');
  const {
    openProjectionDetails,
    setOpenProjectionDetails,
    scenariosData,
    selectedScenario,
    setSelectedScenario,
    isScenarioSelected,
    fetchScenarios,
    handleDeleteAllScenarios,
    projectionData
  } = useScenario();

  return (
    <div className='mt-4 flex gap-2'>
      <Sidebar />
      <div
        id='dashPanel'
        className='w-full p-4 bg-dashboard-panel bg-contain bg-bottom bg-no-repeat overflow-auto'
      >
        <div className='flex flex-col gap-6'>
          <ScenarioSection
            scenariosData={scenariosData}
            isScenarioSelected={isScenarioSelected}
            selectedScenario={selectedScenario}
            setSelectedScenario={setSelectedScenario}
            isRadioBtn
            fetchScenarios={fetchScenarios}
            handleDeleteAllScenarios={handleDeleteAllScenarios}
            projectionData={projectionData}
          />
          <ScenarioListing
            openProjectionDetails={openProjectionDetails}
            isScenarioSelected={isScenarioSelected}
            selectedScenario={selectedScenario}
            setOpenProjectionDetails={setOpenProjectionDetails}
            projectionData={projectionData}
            fetchScenarios={fetchScenarios}
          />
        </div>
      </div>
    </div>
  );
};

export default PensionCalculator;
