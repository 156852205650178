import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { STORAGE_KEYS } from '../../helper';
import { ROUTE_PATH } from '../../helper/constant';

function PublicRoute() {
  const token = localStorage.getItem(STORAGE_KEYS.token);
  return token ? <Navigate to={ROUTE_PATH.HOME} replace /> : <Outlet />;
}

export default PublicRoute
