import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  CustomDatePicker,
  FormInput,
  FormSelect,
  FormSubmit,
} from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import {
  STORAGE_KEYS,
  emailRegex,
  nameRegex,
  phoneRegex,
  zipCodeRegex,
} from '../../helper';
import {
  debounce,
  getExactValueFromEnum,
  handleCSSEffect,
  toastMessage,
  updatePensionApplicationFormStep,
  validatePostalCode,
} from '../../helper/function';
import {
  CONTACT_NUMBER_TYPE_OPTIONS,
  FIELDS_UPDATE_EFFECT_TIMEOUT,
  PENSION_APPLICATION_FORM_STEPS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  SELECT_CONTACT_NUMBER_TYPE_OPTIONS,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import {
  setDataForChatBot,
  setUpdatedDataFromChatBot,
  setWantToUpdateData,
} from '../../features/chatbot/chatbot';

function Spousal({ tabsRef }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;

  const {
    screenName,
    updatedDataFromChatBot,
    wantToUpdateData,
    dataForChatBot,
  } = useSelector((state) => state.chatbot);
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const { userInfo } = useSelector((state) => state.user);

  const [spousalInfo, setSpousalInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [aiUpdatedDataEffectFields, setAiUpdatedDataEffectFields] = useState(
    []
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: '',
      email: '',
      contact_no_type: CONTACT_NUMBER_TYPE_OPTIONS.CELL,
      contact_no: '',
      address_line_1: '',
      address_line_2: '',
      apartment: '',
      city: '',
      state: '',
      zip_code: '',
    },
  });

  const formData = watch();

  const { call: debounceUpdate, cancel: cancelDebounce } = useCallback(
    debounce((formData) => {
      dispatch(
        setDataForChatBot({
          spouse_information: {
            first_name: formData?.first_name,
            middle_name: formData?.middle_name,
            last_name: formData?.last_name,
            date_of_birth: formData?.date_of_birth,
            email: formData?.email,
            phone_number: formData?.contact_no,
            phone_number_type: formData?.contact_no_type,
            street: formData?.address_line_1,
            address_line_2: formData?.address_line_2,
            apartment: formData?.apartment,
            city: formData?.city,
            state: formData?.state,
            zip: formData?.zip_code,
          },
          node: 'spouse_information',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }, 1000),
    []
  );

  const getSpousalInfo = async () => {
    try {
      const response = await axiosInstance('profile/spousal-details');
      setSpousalInfo(response?.data?.data);
    } catch (err) {
      console.error('error while fetching personal details', err);
    }
  };

  const onSubmit = async (data, isNext = false, isPrevious = false) => {
    try {
      const formattedZipCode = validatePostalCode(data.zip_code);
      if (!formattedZipCode) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please enter a valid ZIP code');
        return;
      }
      setIsLoading(true);
      const response = await axiosInstance.post(
        'update/profile/spousal-details',
        {
          ...data,
          zip_code: formattedZipCode,
          contact_no_type: data.contact_no_type.value || data.contact_no_type,
        }
      );
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
        if (isNext) {
          const updatedPensionApplicationFormData = await axiosInstance.get(
            'retirement/form/get'
          );
          const lastUpdatedStep =
            updatedPensionApplicationFormData?.data?.data?.step;
          if (
            pathname === ROUTE_PATH.APPLICATION_FORM &&
            (pensionApplicationFormData?.step ===
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL ||
              lastUpdatedStep ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL)
          ) {
            await updatePensionApplicationFormStep({
              step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES,
            });
          }
          tabsRef?.current?.setActiveTab(3);
        } else if (isPrevious) {
          tabsRef?.current?.setActiveTab(1);
        }
      }
      getSpousalInfo();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('error while updating spousal details', err);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        err?.response?.data?.meta?.message ||
          'Error while updating personal details'
      );
    }
  };

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL ||
      screenName === SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.MY_PROFILE
    ) {
      getSpousalInfo();
    }
  }, [screenName]);

  useEffect(() => {
    if (spousalInfo) {
      setValue('first_name', spousalInfo.first_name);
      setValue('middle_name', spousalInfo.middle_name);
      setValue('last_name', spousalInfo.last_name);
      setValue('date_of_birth', spousalInfo.date_of_birth);
      setValue('email', spousalInfo.email);
      setValue('contact_no_type', spousalInfo.contact_no_type);
      setValue('contact_no', spousalInfo.contact_no);
      setValue('address_line_1', spousalInfo.address_line_1);
      setValue('address_line_2', spousalInfo.address_line_2);
      setValue('apartment', spousalInfo.apartment);
      setValue('city', spousalInfo.city);
      setValue('state', spousalInfo.state);
      setValue('zip_code', spousalInfo.zip_code);
    }
  }, [spousalInfo]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL
    ) {
      dispatch(
        setDataForChatBot({
          spouse_information: {
            first_name: spousalInfo?.first_name,
            middle_name: spousalInfo?.middle_name,
            last_name: spousalInfo?.last_name,
            date_of_birth: spousalInfo?.date_of_birth,
            email: spousalInfo?.email,
            phone_number: spousalInfo?.contact_no,
            phone_number_type: spousalInfo?.contact_no_type,
            street: spousalInfo?.address_line_1,
            address_line_2: spousalInfo?.address_line_2,
            apartment: spousalInfo?.apartment,
            city: spousalInfo?.city,
            state: spousalInfo?.state,
            zip: spousalInfo?.zip_code,
          },
          node: 'spouse_information',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }
  }, [screenName, spousalInfo]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit &&
      'data' in updatedDataFromChatBot
    ) {
      let userAddressDetails
      if(updatedDataFromChatBot?.update_address_asper_mine) {
        userAddressDetails = userInfo?.data?.contact_information
      }
      const updatedFields = [];
      if (updatedDataFromChatBot?.data?.first_name !== formData?.first_name) {
        updatedFields.push({
          fieldName: 'first_name',
          oldValue: formData?.first_name,
          newValue: updatedDataFromChatBot?.data?.first_name,
        });
      }
      if (updatedDataFromChatBot?.data?.middle_name !== formData?.middle_name) {
        updatedFields.push({
          fieldName: 'middle_name',
          oldValue: formData?.middle_name,
          newValue: updatedDataFromChatBot?.data?.middle_name,
        });
      }
      if (updatedDataFromChatBot?.data?.last_name !== formData?.last_name) {
        updatedFields.push({
          fieldName: 'last_name',
          oldValue: formData?.last_name,
          newValue: updatedDataFromChatBot?.data?.last_name,
        });
      }
      if (
        updatedDataFromChatBot?.data?.date_of_birth !== formData?.date_of_birth
      ) {
        updatedFields.push({
          fieldName: 'date_of_birth',
          oldValue: formData?.date_of_birth,
          newValue: updatedDataFromChatBot?.data?.date_of_birth,
        });
      }
      if (updatedDataFromChatBot?.data?.email !== formData?.email) {
        updatedFields.push({
          fieldName: 'email',
          oldValue: formData?.email,
          newValue: updatedDataFromChatBot?.data?.email,
        });
      }
      if (
        (getExactValueFromEnum(
          updatedDataFromChatBot?.data?.phone_number_type,
          Object.values(CONTACT_NUMBER_TYPE_OPTIONS)
        ) || spousalInfo?.contact_no_type) !== formData?.contact_no_type
      ) {
        updatedFields.push({
          fieldName: 'contact_no_type',
          oldValue: formData?.contact_no_type,
          newValue:
            getExactValueFromEnum(
              updatedDataFromChatBot?.data?.phone_number_type,
              Object.values(CONTACT_NUMBER_TYPE_OPTIONS)
            ) || spousalInfo?.contact_no_type,
        });
      }
      if (updatedDataFromChatBot?.data?.phone_number !== formData?.contact_no) {
        updatedFields.push({
          fieldName: 'contact_no',
          oldValue: formData?.contact_no,
          newValue: updatedDataFromChatBot?.data?.phone_number,
        });
      }
      if ((userAddressDetails?.address_line_1 || updatedDataFromChatBot?.data?.street) !== formData?.address_line_1) {
        updatedFields.push({
          fieldName: 'address_line_1',
          oldValue: formData?.address_line_1,
          newValue: userAddressDetails?.address_line_1 || updatedDataFromChatBot?.data?.street,
        });
      }
      if (
        (userAddressDetails?.address_line_2 ||
        updatedDataFromChatBot?.data?.address_line_2) !==
        formData?.address_line_2
      ) {
        updatedFields.push({
          fieldName: 'address_line_2',
          oldValue: formData?.address_line_2,
          newValue: userAddressDetails?.address_line_2 || updatedDataFromChatBot?.data?.address_line_2,
        });
      }
      if ((userAddressDetails?.apartment || updatedDataFromChatBot?.data?.apartment) !== formData?.apartment) {
        updatedFields.push({
          fieldName: 'apartment',
          oldValue: formData?.apartment,
          newValue: userAddressDetails?.apartment || updatedDataFromChatBot?.data?.apartment,
        });
      }
      if ((userAddressDetails?.city || updatedDataFromChatBot?.data?.city) !== formData?.city) {
        updatedFields.push({
          fieldName: 'city',
          oldValue: formData?.city,
          newValue: userAddressDetails?.city || updatedDataFromChatBot?.data?.city,
        });
      }
      if ((userAddressDetails?.state || updatedDataFromChatBot?.data?.state) !== formData?.state) {
        updatedFields.push({
          fieldName: 'state',
          oldValue: formData?.state,
          newValue: userAddressDetails?.state || updatedDataFromChatBot?.data?.state,
        });
      }

      if ((userAddressDetails?.zip_code || updatedDataFromChatBot?.data?.zip) !== formData?.zip_code) {
        updatedFields.push({
          fieldName: 'zip_code',
          oldValue: formData?.zip_code,
          newValue: userAddressDetails?.zip_code || updatedDataFromChatBot?.data?.zip,
        });
      }
      setAiUpdatedDataEffectFields(updatedFields);

      setValue('first_name', updatedDataFromChatBot?.data?.first_name);
      setValue('middle_name', updatedDataFromChatBot?.data?.middle_name);
      setValue('last_name', updatedDataFromChatBot?.data?.last_name);
      setValue('date_of_birth', updatedDataFromChatBot?.data?.date_of_birth);
      setValue('contact_no', updatedDataFromChatBot?.data?.phone_number);
      setValue('address_line_1', userAddressDetails?.address_line_1 || updatedDataFromChatBot?.data?.street);
      setValue('city', userAddressDetails?.city || updatedDataFromChatBot?.data?.city);
      setValue('state', userAddressDetails?.state || updatedDataFromChatBot?.data?.state);
      setValue('email', updatedDataFromChatBot?.data?.email);
      setValue('zip_code', userAddressDetails?.zip_code || updatedDataFromChatBot?.data?.zip);
      setValue('apartment', userAddressDetails?.apartment || updatedDataFromChatBot?.data?.apartment);
      setValue(
        'contact_no_type',
        getExactValueFromEnum(
          updatedDataFromChatBot?.data?.phone_number_type,
          Object.values(CONTACT_NUMBER_TYPE_OPTIONS)
        ) || spousalInfo?.contact_no_type
      );
      setValue('address_line_2', userAddressDetails?.address_line_2 || updatedDataFromChatBot?.data?.address_line_2);

      setSpousalInfo({
        ...spousalInfo,
        first_name: updatedDataFromChatBot?.data?.first_name,
        middle_name: updatedDataFromChatBot?.data?.middle_name,
        last_name: updatedDataFromChatBot?.data?.last_name,
        date_of_birth: updatedDataFromChatBot?.data?.date_of_birth,
        contact_no: updatedDataFromChatBot?.data?.phone_number,
        address_line_1: userAddressDetails?.address_line_1 || updatedDataFromChatBot?.data?.street,
        city: userAddressDetails?.city || updatedDataFromChatBot?.data?.city,
        state: userAddressDetails?.state || updatedDataFromChatBot?.data?.state,
        email: updatedDataFromChatBot?.data?.email,
        zip_code: userAddressDetails?.zip_code || updatedDataFromChatBot?.data?.zip,
        apartment: userAddressDetails?.apartment || updatedDataFromChatBot?.data?.apartment,
        contact_no_type:
          getExactValueFromEnum(
            updatedDataFromChatBot?.data?.phone_number_type,
            Object.values(CONTACT_NUMBER_TYPE_OPTIONS)
          ) || spousalInfo?.contact_no_type,
        address_line_2: userAddressDetails?.address_line_2 || updatedDataFromChatBot?.data?.address_line_2,
      });
      dispatch(setWantToUpdateData(false));
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL &&
      (updatedDataFromChatBot?.want_submit ||
        updatedDataFromChatBot?.is_next ||
        updatedDataFromChatBot?.is_previous) &&
      Object.values(dataForChatBot?.spouse_information)?.length > 0
    ) {
      onSubmit(
        spousalInfo,
        updatedDataFromChatBot?.is_next,
        updatedDataFromChatBot?.is_previous
      );
      if (
        !updatedDataFromChatBot?.is_next &&
        !updatedDataFromChatBot?.is_previous
      ) {
        dispatch(
          setUpdatedDataFromChatBot({
            text: updatedDataFromChatBot?.text,
            redable_text: updatedDataFromChatBot?.redable_text,
          })
        );
      } else {
        dispatch(setUpdatedDataFromChatBot({}));
      }
    }
  }, [updatedDataFromChatBot]);

  useEffect(() => {
    if (aiUpdatedDataEffectFields.length > 0) {
      setTimeout(() => {
        setAiUpdatedDataEffectFields([]);
      }, FIELDS_UPDATE_EFFECT_TIMEOUT);
    }
  }, [aiUpdatedDataEffectFields]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL
    ) {
      debounceUpdate(formData);
    }
    return () => {
      cancelDebounce();
    };
  }, [formData, debounceUpdate, screenName]);

  return (
    <>
      <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
        <h3 className='text-base font-semibold text-b10'>
          Spousal Information
        </h3>
        {/* <img src='/assets/images/icons/edit.svg' alt='Edit' /> */}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 gap-6'>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              First Name
            </span>
            <Controller
              name='first_name'
              control={control}
              rules={{
                required: 'First Name is required',
                pattern: {
                  value: nameRegex,
                  message: 'First name is not valid.',
                },
              }}
              render={({ field }) => (
                <FormInput
                  type='text'
                  placeholder='Enter First Name'
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'first_name',
                  })}
                  errorFieldName='first_name'
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Middle Name
            </span>
            <Controller
              name='middle_name'
              control={control}
              rules={{
                pattern: {
                  value: nameRegex,
                  message: 'Middle Name is not valid.',
                },
              }}
              render={({ field }) => (
                <FormInput
                  type='text'
                  placeholder='Enter Middle Name'
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'middle_name',
                  })}
                  errorFieldName='middle_name'
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Last Name
            </span>
            <Controller
              name='last_name'
              control={control}
              rules={{
                required: 'Last Name is required',
                pattern: {
                  value: nameRegex,
                  message: 'Last name is not valid.',
                },
              }}
              render={({ field }) => (
                <FormInput
                  type='text'
                  placeholder='Enter Last Name'
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'last_name',
                  })}
                  errorFieldName='last_name'
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='block text-lsm font-normal text-b10'>
              Date of Birth
            </span>
            <Controller
              name='date_of_birth'
              control={control}
              rules={{ required: 'Date of Birth is required' }}
              render={({ field }) => (
                <CustomDatePicker
                  value={field.value}
                  onChange={field.onChange}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  autoComplete='off'
                  placeholderText='DD/MM/YYYY'
                  readOnly={false}
                  maxDate={new Date()}
                  className={handleCSSEffect({
                    stateVariableArray: aiUpdatedDataEffectFields,
                    fieldName: 'date_of_birth',
                  })}
                />
              )}
            />
            {errors.date_of_birth && (
              <p className='text-red-600 text-xxs mt-1'>
                {errors.date_of_birth.message}
              </p>
            )}
          </div>
        </div>
        <div className='mb-6 py-2 mt-10 border-b border-bord-g10 flex justify-between items-center'>
          <h3 className='text-base font-semibold text-b10'>
            Spousal Contact Information
          </h3>
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Email address
              </span>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: emailRegex,
                    message: 'Email is not valid.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    type='email'
                    placeholder='Enter Email Address'
                    icon={'/assets/images/icons/mail.svg'}
                    className={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'email',
                    })}
                    errorFieldName='email'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Address Line
              </span>
              <div className='flex flex-col gap-4'>
                <Controller
                  name='address_line_1'
                  control={control}
                  rules={{
                    required: 'Address line 1 is required',
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter Address Line 1'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'address_line_1',
                      })}
                      errorFieldName='address_line_1'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='address_line_2'
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter Address Line 2'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'address_line_2',
                      })}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Apartment / Unit
              </span>
              <Controller
                name='apartment'
                control={control}
                render={({ field }) => (
                  <FormInput
                    type='text'
                    placeholder='Enter Apartment / Unit'
                    className={handleCSSEffect({
                      stateVariableArray: aiUpdatedDataEffectFields,
                      fieldName: 'apartment',
                    })}
                    errorFieldName='apartment'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='w-full flex gap-4'>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  City
                </span>
                <Controller
                  name='city'
                  control={control}
                  rules={{
                    required: 'City is required',
                    pattern: {
                      value: nameRegex,
                      message: 'City is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter City'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'city',
                      })}
                      errorFieldName='city'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  State / Province
                </span>
                <Controller
                  name='state'
                  control={control}
                  rules={{
                    required: 'State is required',
                    pattern: {
                      value: nameRegex,
                      message: 'State is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter State'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'state',
                      })}
                      errorFieldName='state'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  ZIP / Postal code
                </span>
                <Controller
                  name='zip_code'
                  control={control}
                  rules={{
                    required: 'Zip code is required',
                    pattern: {
                      value: zipCodeRegex,
                      message: 'Zip code is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Enter ZIP'
                      className={handleCSSEffect({
                        stateVariableArray: aiUpdatedDataEffectFields,
                        fieldName: 'zip_code',
                      })}
                      errorFieldName='zip_code'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gal-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Phone Numbers
              </span>
              <div className='flex gap-4'>
                <Controller
                  name='contact_no_type'
                  rules={{ required: 'Contact type is required' }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormSelect
                        id='contactNoType'
                        errors={errors}
                        SelectOptions={SELECT_CONTACT_NUMBER_TYPE_OPTIONS}
                        value={SELECT_CONTACT_NUMBER_TYPE_OPTIONS?.find(
                          (option) => option.value === value
                        )}
                        onChange={onChange}
                        updateEffect={handleCSSEffect({
                          stateVariableArray: aiUpdatedDataEffectFields,
                          fieldName: 'contact_no_type',
                        })}
                      />
                    );
                  }}
                />
                <div className='w-full'>
                  <Controller
                    name='contact_no'
                    control={control}
                    rules={{
                      required: 'Contact number is required',
                      pattern: {
                        value: phoneRegex,
                        message: 'Contact number is not valid',
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        type='text'
                        placeholder='Enter Phone Number'
                        className={handleCSSEffect({
                          stateVariableArray: aiUpdatedDataEffectFields,
                          fieldName: 'contact_no',
                        })}
                        errorFieldName='contact_no'
                        errors={errors}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end mt-6'>
          <FormSubmit
            type={'submit'}
            buttonText={'Save Changes'}
            btnDisable={isLoading}
          />
        </div>
      </form>
    </>
  );
}

Spousal.propTypes = {
  tabsRef: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
};

PropTypes.defaultProps = {
  tabsRef: null,
};

export default Spousal;
