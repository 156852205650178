import React, { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../api/axiosInstance';
import { FILE_FORMAT_ICONS, PENSION_APPLICATION_FORM_STEPS } from '../../../helper/constant';

const modalTheme = {
  content: {
    base: ' relative h-full w-full p-4 md:h-auto flex items-center justify-center max-w-[100%] px-20 ',
    inner:
      'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 w-full',
  },
};

const DocumentCenterViewModal = ({ open, text, handleClose }) => {
  const [documentCenter, setDocumentCenterData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    let didCancel = false;
    const fetchDocumentCenterData = async () => {
      try {
        const response = await axiosInstance.get('retirement/form/get/data', {
          params: {
            step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER,
          },
        });
        if (!didCancel) {
          setDocumentCenterData(response?.data?.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error while fetching document centre data', error);
        if (!didCancel) {
          setLoading(false);
        }
      }
    };
    fetchDocumentCenterData();
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Modal
      position='center'
      theme={modalTheme}
      show={open}
      popup
      // size='' to make the modal full screen
      size=''
      onClose={handleClose}
    >
      <Modal.Header>
        <h2 className='mt-[10px] ml-[20px] text-3xl font-semibold text-blue-900'>
          {text}
        </h2>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className='flex items-center justify-center w-full h-full'>
            <div className='inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-primary border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white'></div>
          </div>
        ) : (
          <div className='flex flex-col gap-8 items-center justify-center text-center w-full'>
            <div className='bg-white p-4 rounded-2xl shadow-single flex-shrink-0 w-full'>
            <div className="overflow-auto">
            
            <table className="min-w-full relative">
              {documentCenter?.length > 0 && (
                <thead className="bg-white sticky top-0">
                  <tr>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-center"
                    >
                      File Size
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-center"
                    >
                      Uploaded
                    </th>
                    <th
                      scope="col"
                      className="text-lsm font-semibold text-b10 px-6 py-4 text-left"
                    ></th>
                  </tr>
                </thead>
              )}

              {documentCenter.map((document) => (
                <tbody key={document?.id}>
                  <tr className="bg-light-gray ">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center gap-3">
                        <img
                          src={FILE_FORMAT_ICONS[document?.extension?.toLowerCase() || 'pdf']}
                          alt={document?.extension || 'pdf'}
                        />
                        <span className="text-xxs font-normal text-b10">
                          {document?.name}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-xxs font-normal text-b10">
                        {document?.size}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-xxs font-normal text-b10">
                        {document?.uploaded_at}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex w-full justify-end">
                        <div className="flex items-center gap-5">
                          <button onClick={() => window.open(document?.url, '_blank')}>
                            <img
                              src="/assets/images/icons/view.svg"
                              alt="view"
                            />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}

              {!documentCenter?.length && (
                <tbody className="bg-light-gray">
                  <tr>
                    <td colSpan="100%" className="px-6 py-4">
                      <div className="flex items-center justify-center">
                        <span className="text-xxs font-normal text-b10">
                          No document uploaded
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

DocumentCenterViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DocumentCenterViewModal;
