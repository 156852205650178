import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'flowbite-react';
import { scenarioPropType } from '../../../helper';
import InfoRow from './InfoRow';
import Section from './Section';
import TableRow from './TableRow';

const customTabTheme = {
  base: 'block',
  tablist: {
    base: 'flex gap-6 mb-4 pb-2 border-b border-bord-g30',
    styles: 'border-b border-bord-g30 ',
    tabitem: {
      base: 'text-md font-semibold duration-300  hover:bg-transparent ',
      styles: {
        base: '',
        default: {
          base: 'text-bord-g20 hover:text-bord-g10',
          active: {
            off: 'text-bord-g10',
            on: 'text-b10',
          },
        },
      },
    },
  },
  tabitemcontainer: {
    base: 'p-0',
  },
  tabpanel: {
    base: 'p-0',
  },
};

function TabContent({ isScenarioSelected, selectedScenario, projectionData }) {
  const pensionSummary = isScenarioSelected
    ? selectedScenario?.pension_summery
    : projectionData?.pension_summery;
  const profileDetails = isScenarioSelected
    ? selectedScenario?.profile_details
    : projectionData?.profile_details;
  const serviceRecords = isScenarioSelected
    ? selectedScenario?.service_records
    : projectionData?.service_records;

  return (
    <Tabs theme={customTabTheme}>
      <Tabs.Item active title='Pension'>
        <div className='py-2.5 flex flex-col gap-2 max-h-80 overflow-auto'>
          <InfoRow
            label='Date of Resignation'
            value={pensionSummary?.date_of_resignation || '- -'}
          />
          <InfoRow
            label='Pension Start Date'
            value={pensionSummary?.pension_start_date || '- -'}
            bg='bg-bord-g40'
          />
          <InfoRow
            label='Qualifying Factor'
            value={pensionSummary?.qualifying_factor || '- -'}
          />
          <InfoRow
            label='Qualifying Years'
            value={pensionSummary?.qualifying_years || '- -'}
            bg='bg-bord-g40'
          />
          <InfoRow
            label='Age of Retirement'
            value={pensionSummary?.age_of_retirement || '- -'}
          />
          <InfoRow
            label='Average Salary'
            value={pensionSummary?.average_salary || '- -'}
            bg='bg-bord-g40'
          />
        </div>
      </Tabs.Item>
      <Tabs.Item title='Profile'>
        <div className='flex flex-col gap-4 max-h-80 overflow-auto'>
          <Section
            title='Personal Information'
            items={[
              {
                label: 'Date of Birth',
                value:
                  profileDetails?.personal_details?.date_of_birth ||
                  'Feb 7, 1981',
              },
              {
                label: 'Marital Status',
                value:
                  profileDetails?.personal_details?.marital_status || 'Married',
              },
            ]}
          />
          <Section
            title='Survivor Information'
            items={[
              {
                label: 'Spouse Name',
                value: `${
                  profileDetails?.spousal_details?.first_name || 'Ms. Emilia'
                } ${profileDetails?.spousal_details?.last_name || 'Johnson'}`,
              },
              {
                label: 'Date of Birth',
                value:
                  profileDetails?.spousal_details?.date_of_birth ||
                  'Mar 21, 1984',
              },
              {
                label: 'Pension Guaranteed Period',
                value:
                  profileDetails?.spousal_details?.pension_guarantee_period ||
                  '10 Years',
              },
              {
                label: 'Survivor Pension',
                value:
                  profileDetails?.spousal_details
                    ?.survivor_pension_percentage || '75%',
              },
            ]}
          />
        </div>
      </Tabs.Item>
      <Tabs.Item title='Service'>
        <div className='py-2.5 max-h-80 overflow-auto'>
          <table className='min-w-full relative bg-white rounded-xl'>
            <thead className='bg-white sticky top-0'>
              <tr>
                {[
                  'Year',
                  'Type of Credit',
                  'Qualifying years',
                  'Annualized earnings($)',
                ].map((header, index) => (
                  <th
                    key={index}
                    scope='col'
                    className='text-lsm font-semibold text-b10 px-6 py-4 text-left'
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {serviceRecords?.map((record, index) => (
                <TableRow key={index} {...record} isOdd={index % 2 !== 1} />
              ))}
            </tbody>
          </table>
        </div>
      </Tabs.Item>
    </Tabs>
  );
}

TabContent.propTypes = {
  isScenarioSelected: PropTypes.bool,
  projectionData: scenarioPropType,
  selectedScenario: scenarioPropType,
};

TabContent.defaultProps = {
  isScenarioSelected: false,
  selectedScenario: null,
};
export default TabContent;
