import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormSubmit } from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import {
  MARITAL_STATUS_OPTIONS,
  ROUTE_PATH,
  FLAG_Y_N,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { scenarioPropType } from '../../helper';
import LeaveForm from './LeaveForm';

const LeaveDetails = ({ tabHandler, activeTab, scenarioData }) => {
  const { userInfo } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [openLeaveForm, setOpenLeaveForm] = useState(false);
  const [leavesData, setLeavesData] = useState([]);
  const [editLeaveData, setEditLeaveData] = useState(null);

  const fetchAllLeaves = async () => {
    try {
      if (!scenarioData?.id || activeTab !== 2) return;
      const response = await axiosInstance.get(
        `scenario/${scenarioData?.id}/leaves`
      );
      if (response?.data?.data?.length > 0) {
        setLeavesData(response.data.data);
      } else if (response?.data?.data?.length === 0) {
        setLeavesData([]);
        setOpenLeaveForm(true);
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  const calculateScenario = async ({ scenarioDataId, showSuccessToast }) => {
    const response = await axiosInstance.get(
      `scenarios/${scenarioDataId}/calculate`
    );
    if (response.status === 200 || response.status === 201) {
      if (showSuccessToast) {
        toastMessage(
          TOAST_MESSAGE_TYPE.SUCCESS,
          response?.data?.meta?.message || 'Calculation done successfully'
        );
      }
      if(showSuccessToast) {
        navigate(ROUTE_PATH.PENSION_CALCULATOR);
      }
    }
    if (!(response.status === 200 || response.status === 201)) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        response?.data?.meta?.message || 'Unable to calculate scenario.'
      );
    }
  };
  const handleNextStep = async () => {
    try {
      /* if (leavesData.length === 0) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please add leave details');
        return;
      } */
      const isSingle =
        userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.SINGLE;
      if (isSingle) {
        calculateScenario({
          scenarioDataId: scenarioData?.id,
          showSuccessToast: true,
        });
        return;
      }

      if (scenarioData?.step === 2) {
        const response = await axiosInstance.put(
          `scenarios/${scenarioData?.id}`,
          {
            step: 3,
          }
        );
        if (response?.data?.data) {
          tabHandler(3);
          if (scenarioData?.is_calculated === FLAG_Y_N.YES) {
            calculateScenario({
              scenarioDataId: scenarioData?.id,
              showSuccessToast: false,
            });
          }
        } else {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            response?.data?.meta?.message || 'Something went wrong'
          );
        }
      } else if (scenarioData?.step >= 3) {
        tabHandler(3);
        if (scenarioData?.is_calculated === FLAG_Y_N.YES) {
          calculateScenario({
            scenarioDataId: scenarioData?.id,
            showSuccessToast: false,
          });
        }
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    fetchAllLeaves();
  }, [scenarioData?.id, activeTab]);

  return (
    <>
      <div className='pb-6 flex flex-col gap-6'>
        <div className='py-2 border-b border-bord-g10 flex justify-between items-center'>
          <h3 className='text-base font-semibold text-b10'>
            Leave Details{' '}
            <span className='ml-2 text-lsm font-normal text-neutral-gray'>
              (Future date/age)
            </span>
          </h3>
          <FormSubmit
            onClick={() => setOpenLeaveForm(true)}
            className={'secondary-btn-blue '}
            buttonText={'Add Leave'}
            btnDisable={openLeaveForm}
          />
        </div>

        {/* leave form start */}
        {openLeaveForm && !editLeaveData && (
          <LeaveForm
            setOpenLeaveForm={setOpenLeaveForm}
            fetchAllLeaves={fetchAllLeaves}
            editLeaveData={editLeaveData}
            setEditLeaveData={setEditLeaveData}
            scenarioDataId={scenarioData?.id}
            openLeaveForm={openLeaveForm}
          />
        )}
        {/* leave form end */}

        <div className='flex flex-col gap-6'>
          {leavesData.map((leave) =>
            editLeaveData?.id === leave?.id ? (
              <LeaveForm
                key={leave?.id}
                setOpenLeaveForm={setOpenLeaveForm}
                fetchAllLeaves={fetchAllLeaves}
                editLeaveData={editLeaveData}
                setEditLeaveData={setEditLeaveData}
                scenarioDataId={scenarioData?.id}
                openLeaveForm={openLeaveForm}
              />
            ) : (
              <div
                key={leave?.id}
                className='odd:bg-white even:bg-light-gray p-4 border border-bord-g30 rounded-xl'
              >
                <div className='flex gap-6 justify-between items-center'>
                  <div className='flex flex-col gap-4'>
                    <p className='text-lsm font-normal text-b10'>
                      Leave Start Date
                    </p>
                    <p className='px-3 text-lsm font-ibm font-semibold text-b10'>
                      {leave?.start_date}
                    </p>
                  </div>
                  <div className='flex flex-col gap-4'>
                    <p className='text-lsm font-normal text-b10'>
                      Leave End Date
                    </p>
                    <p className='px-3 text-lsm font-ibm font-semibold text-b10'>
                      {leave?.end_date}
                    </p>
                  </div>
                  <FormSubmit
                    className={'secondary-btn-blue '}
                    buttonText={'Edit'}
                    onClick={() => {
                      setOpenLeaveForm(true);
                      setEditLeaveData(leave);
                    }}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() => tabHandler(1)}
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={() => handleNextStep()}
        />
      </div>
    </>
  );
};

LeaveDetails.propTypes = {
  tabHandler: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  scenarioData: scenarioPropType,
};

export default LeaveDetails;
