import PropTypes from 'prop-types';

export const STORAGE_KEYS = {
  token: 'token',
  selectedSceanrioId: 'selectedSceanrioId',
};

/**
 * Formats a date into DD/MM/YYYY format.
 *
 * @param {string|number|Date} date - The date to format. Can be a string, a number (timestamp), or a Date object.
 * @returns {string} The formatted date.
 */
export const formatDateDDMMYYYY = (date) => {
  const dateObject = new Date(date);
  const formattedDate =
    dateObject.getDate().toString().padStart(2, '0') +
    '/' +
    (dateObject.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    dateObject.getFullYear();
  return formattedDate;
};

/**
 * Formats a date string into the "MMMM DD, YYYY" format.
 *
 * @function formatToReadableDate
 * @param {string} date - The date string to format.
 * @param {string} formatType - The format type of the input date string. Supported formats are "DD/MM/YYYY" and "YYYY-MM-DD".
 * @returns {string} The formatted date string in "MMMM DD, YYYY" format.
 * @throws {Error} Throws an error if the format type is unsupported.
 * @example
 * // Returns "July 29, 2024"
 * formatToReadableDate('29/07/2024', 'DD/MM/YYYY');
 *
 * @example
 * // Returns "July 29, 2024"
 * formatToReadableDate('2024-07-29', 'YYYY-MM-DD');
 */
export const formatToReadableDate = (date, formatType) => {
  let dateObject;

  if (formatType === 'DD/MM/YYYY') {
    const [day, month, year] = date.split('/');
    dateObject = new Date(`${year}/${month}/${day}`);
  } else if (formatType === 'YYYY-MM-DD') {
    dateObject = new Date(date);
  } else {
    throw new Error('Unsupported date format');
  }

  const formattedDate = dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return formattedDate;
};

export const nameRegex = /^\s*[a-zA-Z]+([ \t][a-zA-Z]+)*\s*$/;

export const emailRegex = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/;

export const zipCodeRegex = /^(?:\d{5}|[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d)$/i;

export const phoneRegex =
  /^(?:(?:\+?1[-.\s]?)?(?:\(?[2-9]\d{2}\)?[-.\s]?){1,2}\d{4}|(?:\+91|0)?[-.\s]?[6789]\d{2}[-.\s]?\d{3}[-.\s]?\d{4})$/;

export const percentageRegex = /^\d*\.?\d*$/;

export const ageRegex = /^\d+$/;

export const scenarioPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  retirement_type: PropTypes.string,
  formula: PropTypes.shape({
    multipler: PropTypes.string,
    credit: PropTypes.string,
    average_salary: PropTypes.string,
    pension: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  }),
  less_reduction: PropTypes.shape({
    survivor_pension_percentage: PropTypes.number ,
    less_reduction_survior_pension_amount: PropTypes.string,
    pension_guarantee_period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    less_reduction_pension_guarantee_period_amount: PropTypes.string,
  }),
  net: PropTypes.shape({
    annual_pension: PropTypes.string,
    monthly_pension: PropTypes.string,
  }),
  pension_summery: PropTypes.shape({
    date_of_resignation: PropTypes.string,
    pension_start_date: PropTypes.string,
    qualifying_factor: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    qualifying_years: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    age_of_retirement: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    average_salary: PropTypes.string,
  }),
  profile_details: PropTypes.shape({
    personal_details: PropTypes.shape({
      date_of_birth: PropTypes.string,
      marital_status: PropTypes.string,
    }),
    spousal_details: PropTypes.shape({
      first_name: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      middle_name: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      last_name: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      date_of_birth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      pension_guarantee_period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      survivor_pension_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  service_records: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string,
      type_of_credit: PropTypes.string,
      qualifying_years: PropTypes.string,
      annulized_earnings: PropTypes.string,
    })
  ),
  is_calculated: PropTypes.string,
  color: PropTypes.string,
  step: PropTypes.number,
});

export const datePickerTheme = {
  root: {
    base: 'relative',
    input: {
      field: {
        base: 'w-full',
        icon: {
          base: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
        },
        input: {
          base: '!p-3 !bg-transparent shadow-none block w-full text-lsm font-normal text-black border !border-black rounded-lg ring-0 placeholder:text-neutral-gray focus:ring-0 focus:ring-opacity-0 focus:border-black',
        },
      },
    },
  },
  popup: {
    footer: {
      button: {
        base: 'px-4 py-2 rounded-xl focus:ring-0 focus:border-none',
        today: 'bg-primary',
        clear: 'border-primary',
      },
    },
  },
  views: {
    days: {
      items: {
        item: {
          selected: 'bg-primary',
          disabled: '!bg-transparent border-none text-bord-g30',
        },
      },
    },
    months: {
      items: {
        item: {
          selected: 'bg-primary',
          disabled: '!bg-transparent border-none text-bord-g30',
        },
      },
    },
    years: {
      items: {
        item: {
          selected: 'bg-primary',
          disabled: '!bg-transparent border-none text-bord-g30',
        },
      },
    },
    decades: {
      items: {
        item: {
          selected: 'bg-primary',
          disabled: '!bg-transparent border-none text-bord-g30',
        },
      },
    },
  },
};

export const radioButtonTheme = {
  root: {
    base: 'h-[18px] w-[18px] border-black  text-primary  focus:ring-0 focus:ring-transparent ring-opacity-0  !ring-offset-0',
  },
};


/**
 * Converts a date string in "dd/MM/yyyy" format to a JavaScript Date object string.
 * Returns undefined if the input date is invalid or empty.
 *
 * @param {string} inputDate - The date string in "dd/MM/yyyy" format.
 * @returns {string|undefined} - The JavaScript Date object as a string if valid, or undefined if invalid.
 */
export function convertToDateString(inputDate) {
  if (!inputDate) {
    return undefined;
  }

  const [day, month, year] = inputDate.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month - 1 ||
    date.getDate() !== day
  ) {
    return undefined;
  }

  return date.toString();
}