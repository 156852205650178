import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Sidebar from '../../components/Sidebar/Sidebar';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import ServiceDetails from '../../components/ServiceDetails/ServiceDetails';
import useResizeHeight from '../../hooks/useResizeHeight';
import { setNavbarTitle } from '../../features/navbar/navbarSlice';
import { useServiceRecord } from '../../hooks/useServiceRecord';
import FullScreenLoader from '../../components/Generic/FullScreenLoader';
import PensionInfoSummaryCard from '../../components/SummaryCard/PensionInfoSummaryCard';

const ServiceRecord = () => {
  useResizeHeight('dashPanel');
  const dispatch = useDispatch();
  const { serviceRecord, loading } = useServiceRecord();

  useEffect(() => {
    dispatch(setNavbarTitle('Service Record'));
  }, []);

  return (
    <div className='mt-4 flex gap-2'>
      <Sidebar />
      <div
        id='dashPanel'
        className='w-full p-4 bg-dashboard-panel bg-contain bg-bottom bg-no-repeat overflow-auto'
      >
        {!loading ? (
          <div className='flex flex-col gap-6'>
            <div className='flex gap-6 '>
              <div className='flex flex-col w-1/2'>
                <p className='text-xxl font-bold text-b10 mb-4'>
                  Contributions
                </p>
                <SummaryCard
                  summaryData={serviceRecord?.data?.contribution_summery}
                />
              </div>
              <div className='flex flex-col w-1/2'>
                <p className='text-xxl font-bold text-b10 mb-4'>
                  Pension Information
                </p>
                <PensionInfoSummaryCard
                  summaryData={serviceRecord?.data?.service_records_summery}
                />
              </div>
            </div>

            <div>
              <ServiceDetails
                serviceData={serviceRecord?.data?.service_records}
              />
            </div>
          </div>
        ) : (
          <FullScreenLoader />
        )}
      </div>
    </div>
  );
};

export default ServiceRecord;
