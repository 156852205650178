import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NavUserProfile from '../NavUserProfile/NavUserProfile';
import axiosInstance from '../../api/axiosInstance';
import { STORAGE_KEYS } from '../../helper';
import { toastMessage } from '../../helper/function';
import { ROUTE_PATH, TOAST_MESSAGE_TYPE } from '../../helper/constant';

const Navbar = ({ transparent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.user);

  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const menuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.post('logout');
      localStorage.removeItem(STORAGE_KEYS.token);
      navigate(ROUTE_PATH.LOGIN);
      toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, 'Logout Successful');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('error while logging out', error);
    }
  };

  const navItems = [
    {
      tag: 'Link',
      to: ROUTE_PATH.MY_PROFILE,
      text: 'My Profile',
      active: 'bg-primary text-white',
      className: 'hover:bg-bord-g40 text-neutral-gray',
    },
    {
      tag: 'Link',
      to: ROUTE_PATH.DOCUMENT,
      text: 'My Documents',
      active: 'bg-primary text-white',
      className: 'hover:bg-bord-g40 text-neutral-gray',
    },
    {
      tag: 'Link',
      to: ROUTE_PATH.CHANGE_PASSWORD,
      text: 'Change Password',
      active: 'bg-primary text-white',
      className: 'hover:bg-bord-g40 text-neutral-gray',
    },
    {
      tag: 'button',
      text: 'Logout',
      onClick: handleLogout,
      disabled: isLoading,
      active: 'bg-primary text-white',
      className: 'hover:bg-bord-g40 text-neutral-gray',
    },
  ];
  const navTitle = location.pathname;

  const renderNavTitle = () => {
    switch (navTitle) {
      case ROUTE_PATH.SERVICE_RECORD:
        return 'Service Record';
      case ROUTE_PATH.PENSION_CALCULATOR:
        return 'Pension Calculator';
      case ROUTE_PATH.BUYBACK_CALCULATOR:
        return 'Buyback Calculator';
      case ROUTE_PATH.CREATE_SCENARIO:
        return 'Create Scenario';
      case ROUTE_PATH.COMPARE_SCENARIO:
        return 'Compare Scenario';
      case ROUTE_PATH.APPLICATION_FORM:
        return 'Retirement Application';
      default:
        return 'Dashboard';
    }
  };

  return (
    <header
      id='navbar'
      className={`relative z-10 w-full flex  ${
        transparent
          ? 'p-4 bg-transparent justify-end'
          : 'py-2 px-4 border border-bord-g10 rounded-lg  justify-between items-center'
      }`}
    >
      {!transparent && (
        <div className='flex items-center gap-6'>
          <img
            src='/assets/images/nav-logo.png'
            alt='site-logo'
            className='cursor-pointer'
            onClick={() => navigate(ROUTE_PATH.HOME)}
          />
          <p className='text-sm font-bold text-piddy-dark-blue font-ibm ml-2'>
            {renderNavTitle()}
          </p>
        </div>
      )}

      <div className='flex items-center gap-10 relative'>
        <NavUserProfile
          username={
            (userInfo?.data?.first_name || '') +
            ' ' +
            (userInfo?.data?.last_name || '')
          }
          profileUrl={userInfo?.data?.profile || '/assets/images/user-pfp.jpeg'}
          color={transparent ? '' : 'dark'}
        />
        <button
          className='relative flex items-center justify-center'
          onClick={() => setOpenMenu((prev) => !prev)}
        >
          <img
            className={`${
              openMenu ? 'opacity-0' : 'opacity-100'
            }  duration-300`}
            src='/assets/images/icons/menu-btn.svg'
            alt='Menu'
          />
          <img
            className={`${
              openMenu ? 'opacity-100' : 'opacity-0'
            }  absolute left-0 top-0 duration-300`}
            src='/assets/images/icons/close-menu.svg'
            alt='Menu'
          />
        </button>

        {openMenu && (
          <div
            className={`${
              openMenu ? 'animate-fadeShow' : 'animate-fadeHide'
            } shadow-heavy absolute w-max top-full right-0 p-2 bg-white rounded-lg duration-300`}
          >
            <ul ref={menuRef} className='flex flex-col gap-1'>
              {navItems?.map((item, index) => {
                const Tag = item.tag === 'Link' ? Link : 'button';
                const tagAttribute =
                  item.tag === 'Link'
                    ? { to: item.to, onClick: () => setOpenMenu(false) }
                    : { onClick: item.onClick, disabled: item.disabled };
                return (
                  <li key={index}>
                    <Tag
                      {...tagAttribute}
                      className={`block p-2 rounded-lmd text-xxs font-medium duration-300 w-full text-left ${
                        location.pathname === item.to
                          ? item.active
                          : item.className
                      }`}
                    >
                      {item.text}
                    </Tag>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

Navbar.propTypes = {
  transparent: PropTypes.bool.isRequired,
};

export default Navbar;
