import React from 'react';
import { Modal } from 'flowbite-react';
import PropTypes from 'prop-types';
import { FormSubmit } from './';

const modalTheme = {
  content: {
    base: ' relative h-full w-full p-4 md:h-auto flex items-center justify-center',
  },
};

const ConfirmationModal = ({ open, icon, text, subText, handleClose, handleSubmit }) => {
  return (
    <Modal
      position="center"
      theme={modalTheme}
      show={open}
      size="md"
      popup
      onClose={handleClose}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="flex flex-col gap-8 items-center justify-center text-center">
          {icon && <img src={icon} alt="money" />}
          <div>
            <h3 className="mb-2 text-slg font-medium text-black">{text}</h3>
            {subText && <p className='text-lsm font-medium text-blue-400'>{subText}</p>}
          </div>
          <div className="flex justify-center gap-4">
            <FormSubmit buttonText="Yes" onClick={handleSubmit} />
            <FormSubmit
              className="secondary-btn-blue"
              buttonText=" No"
              onClick={handleClose}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  subText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ConfirmationModal;
