import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // import styles
import 'quill/dist/quill.snow.css'; // import Quill styles
import ReactQuill from 'react-quill';
import { FileInput } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { FormSubmit } from '../Generic';
import {
  handleCSSEffect,
  toastMessage,
  updatePensionApplicationFormStep,
} from '../../helper/function';
import {
  FIELDS_UPDATE_EFFECT_TIMEOUT,
  MAX_DOCUMENT_UPLOAD_SIZE,
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import {
  setDataForChatBot,
  setWantToUpdateData,
} from '../../features/chatbot/chatbot';
import { STORAGE_KEYS } from '../../helper';
import axiosInstance from '../../api/axiosInstance';

function NoFlow({ tabHandler }) {
  const dispatch = useDispatch();
  const emailAttachmentFileInputRef = useRef(null);

  const { updatedDataFromChatBot, screenName, wantToUpdateData } = useSelector(
    (state) => state.chatbot
  );

  const [recipients, setRecipients] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [aiUpdatedDataEffectFields, setAiUpdatedDataEffectFields] = useState(
    []
  );

  const handleSend = async () => {
    try {
      if (!recipients) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please enter recipients');
        return;
      }
      if (!subject) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please enter subject');
        return;
      }
      if (!message) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please enter message');
        return;
      }

      const formData = new FormData();
      formData.append('send_to', recipients);
      formData.append('subject', subject);
      formData.append('message', message);
      formData.append('attachment', attachment);

      const response = await axiosInstance.post(
        'retirement/form/send-retirement-mail',
        formData
      );

      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
        await handleNextButton();
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleNextButton = async () => {
    await updatePensionApplicationFormStep({
      step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL_AFTER_SCREEN,
    });
    tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL_AFTER_SCREEN);
  };

  const handleFileForEmailAttachment = async (event) => {
    const file = event.target.files?.[0];
    if (!file || !file?.size || !file?.name) {
      toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'No file selected');
      return;
    }
    if (file.size > MAX_DOCUMENT_UPLOAD_SIZE) {
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        'File size exceeds the 2MB limit.'
      );
      event.target.value = '';
      return;
    }

    setAttachment(file);
  };

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL
    ) {
      dispatch(
        setDataForChatBot({
          resignation_email_letter: {
            resignation_letter_email_to: '',
            email_subject: '',
            message: '',
            is_send_true: false,
          },
          node: 'notify_employer_email',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL &&
      updatedDataFromChatBot?.want_submit
    ) {
      if (updatedDataFromChatBot?.is_next) {
        updatePensionApplicationFormStep({
          step:
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL_AFTER_SCREEN,
        })
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL_AFTER_SCREEN
        );
      } else if (updatedDataFromChatBot?.is_previous) {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
        );
      }
    }
  }, [screenName, updatedDataFromChatBot]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit &&
      'data' in updatedDataFromChatBot
    ) {
      const updatedFields = [];
      if(updatedDataFromChatBot?.data?.resignation_letter_email_to !== recipients) {
        updatedFields.push({
          fieldName: 'recipients',
          oldValue: recipients,
          newValue: updatedDataFromChatBot?.data?.resignation_letter_email_to,
        })
      }
      if(updatedDataFromChatBot?.data?.email_subject !== subject) {
        updatedFields.push({
          fieldName: 'subject',
          oldValue: subject,
          newValue: updatedDataFromChatBot?.data?.email_subject,
        })
      }
      setAiUpdatedDataEffectFields(updatedFields);

      setRecipients(
        updatedDataFromChatBot?.data?.resignation_letter_email_to || ''
      );
      setSubject(updatedDataFromChatBot?.data?.email_subject || '');
      setMessage(updatedDataFromChatBot?.data?.message || message);
      dispatch(
        setDataForChatBot({
          resignation_email_letter: {
            resignation_letter_email_to:
              updatedDataFromChatBot?.data?.resignation_letter_email_to || '',
            email_subject: updatedDataFromChatBot?.data?.email_subject || '',
            message: updatedDataFromChatBot?.data?.message || message,
            is_send_true: false,
          },
          node: 'notify_employer_email',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
      dispatch(setWantToUpdateData(false));
      if (updatedDataFromChatBot?.data?.is_send_true) {
        handleSend();
      }
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  useEffect(() => {
    if (aiUpdatedDataEffectFields.length > 0) {
      setTimeout(() => {
        setAiUpdatedDataEffectFields([]);
      }, FIELDS_UPDATE_EFFECT_TIMEOUT);
    }
  }, [aiUpdatedDataEffectFields]);

  return (
    <>
      <div className='relative p-4 bg-white rounded-lg shadow h-full'>
        <h2 className='text-xl font-semibold mb-4'>New Message</h2>

        <div className='mb-4'>
          <label
            htmlFor='recipients'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            To
          </label>
          <input
            id='recipients'
            type='text'
            value={recipients}
            onChange={(e) => setRecipients(e.target.value)}
            placeholder='Enter Recipients'
            className={`w-full p-2 border border-gray-300 rounded-md ${handleCSSEffect({
              stateVariableArray: aiUpdatedDataEffectFields,
              fieldName: 'recipients',
            })}`} 
          />
        </div>

        <div className='mb-4'>
          <label
            htmlFor='subject'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            Subject
          </label>
          <input
            id='subject'
            type='text'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder='Enter Subject'
            className={`w-full p-2 border border-gray-300 rounded-md ${handleCSSEffect({
              stateVariableArray: aiUpdatedDataEffectFields,
              fieldName: 'subject',
            })}`} 
          />
        </div>

        <div className='mb-4'>
          <label
            htmlFor='message'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            Message
          </label>
          <ReactQuill
            value={message}
            onChange={setMessage}
            placeholder='Enter Message'
            className='bg-white border border-gray-300 rounded-md'
          />
        </div>

        <div className='flex justify-end items-center'>
          {attachment?.name && (
            <div className='flex items-center mr-2 p-[7px] bg-[#F3F4F6] rounded-full'>
              <span className='mr-2 ml-1 text-sm'>{attachment.name}</span>
              <button onClick={() => setAttachment(null)}>
                <img src='/assets/images/icons/close.svg' alt='close' />
              </button>
            </div>
          )}
          <button
            className='mr-2 p-2 rounded-full bg-gray-100 hover:bg-gray-200'
            onClick={() => emailAttachmentFileInputRef.current.click()}
          >
            <img src='/assets/images/icons/attachment.svg' alt='attachment' />
          </button>
          <button onClick={handleSend} className='primary-btn'>
            Send
          </button>
          <FileInput
            ref={emailAttachmentFileInputRef}
            className='hidden'
            id='email-attachment'
            onChange={handleFileForEmailAttachment}
            accept={['application/pdf', 'image/jpeg', 'image/png']}
          />
        </div>
      </div>
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
}

NoFlow.propTypes = {
  tabHandler: PropTypes.func,
};
NoFlow.defaultProps = {
  tabHandler: () => {},
};

export default NoFlow;
