import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

function TypingEffect({ text, speed }) {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prevText) => prevText + text[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }, speed);

      return () => clearTimeout(timer);
    }
  }, [index, text, speed]);

  const components = {
    h1: ({ children }) => (
      <h1 className='text-xxl font-semibold font-ibm text-b10'>{children}</h1>
    ),
    h2: ({ children }) => (
      <h2 className='text-xl font-semibold font-ibm text-b10'>{children}</h2>
    ),
    h3: ({ children }) => (
      <h3 className='text-base font-semibold text-b10 font-ibm'>{children}</h3>
    ),
    p: ({ children }) => {
      return (
        <p className='text-lbase font-semibold text-neutral-gray'>{children}</p>
      );
    },
    strong: ({ children }) => (
      <strong className='text-lbase font-semibold text-b10'>{children}</strong>
    ),
    li: ({ children }) => (
      <li className='text-lbase font-semibold text-neutral-gray'>{children}</li>
    ),
    ul: ({ children }) => (
      <ul className='list-disc p-auto ms-10'>{children}</ul>
    ),
  };

  return (
    <Markdown className='flex flex-col gap-y-5' components={components}>
      {displayedText}
    </Markdown>
  );
}

TypingEffect.propTypes = {
  speed: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default TypingEffect;
