import React, { useEffect } from 'react';
import { Label, Radio } from 'flowbite-react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { CustomDatePicker, FormInput, FormSubmit } from '../Generic';
import {
  ageRegex,
  formatDateDDMMYYYY,
  radioButtonTheme,
  scenarioPropType,
} from '../../helper';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import {
  DATE_DD_MM_YYYY_FORMAT_REGEX,
  RETIREMENT_TYPE_OPTIONS,
  FLAG_Y_N,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';

const ScenarioDetails = ({ tabHandler, scenarioData, setScenarioData }) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      retirement_type: RETIREMENT_TYPE_OPTIONS.ASAP_WITHOUT_EARLY_REDUCTION,
      name: '',
    },
  });

  const watchAllFields = watch();

  const onSubmit = async (data) => {
    try {
      if (scenarioData?.id) {
        const response = await axiosInstance.put(
          `/scenarios/${scenarioData.id}`,
          {
            ...data,
            retirement_date:
              data.retirement_type === RETIREMENT_TYPE_OPTIONS.AT_DATE
                ? data.retirement_date
                : undefined,
            retirement_age:
              data.retirement_type === RETIREMENT_TYPE_OPTIONS.AT_AGE
                ? parseInt(data.retirement_age)
                : undefined,
          }
        );
        if (response?.data?.data) {
          tabHandler(1);
          if (
            response?.data?.data?.is_calculated === FLAG_Y_N.YES
          ) {
            const calculateResponse = await axiosInstance.get(
              `scenarios/${scenarioData.id}/calculate`
            );
            if (
              !(
                calculateResponse.status === 200 ||
                calculateResponse.status === 201
              )
            ) {
              toastMessage(
                TOAST_MESSAGE_TYPE.ERROR,
                calculateResponse?.data?.meta?.message ||
                  'Unable to calculate scenario.'
              );
            }
          }
        } else {
          toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Unable to update scenario.');
        }
        return;
      } else {
        const response = await axiosInstance.post('/scenarios', {
          ...data,
          step: 1,
          retirement_date:
            data.retirement_type === RETIREMENT_TYPE_OPTIONS.AT_DATE
              ? data.retirement_date
              : undefined,
          retirement_age:
            data.retirement_type === RETIREMENT_TYPE_OPTIONS.AT_AGE
              ? parseInt(data.retirement_age)
              : undefined,
        });
        if (response?.data?.data) {
          setScenarioData(response?.data?.data);
          tabHandler(1);
        } else {
          toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Unable to create scenario.');
        }
      }
    } catch (error) {
      console.error(error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    if (scenarioData?.id) {
      setValue('name', scenarioData.name);
      setValue('retirement_type', scenarioData.retirement_type);
      if (scenarioData.retirement_type === RETIREMENT_TYPE_OPTIONS.AT_DATE) {
        setValue(
          'retirement_date',
          formatDateDDMMYYYY(scenarioData?.pension_summery?.date_of_resignation)
        );
      } else if (
        scenarioData.retirement_type === RETIREMENT_TYPE_OPTIONS.AT_AGE
      ) {
        setValue(
          'retirement_age',
          scenarioData?.pension_summery?.age_of_retirement
        );
      }
    }
  }, [scenarioData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-4 py-2 border-b border-bord-g10 flex flex-col justify-between'>
          <h3 className='text-lsm font-semibold text-b10'>Scenario Name</h3>
          <Controller
            name='name'
            control={control}
            rules={{
              required: 'Scenario Name is required',
            }}
            render={({ field }) => (
              <FormInput
                type='text'
                placeholder='Scenario Name'
                errorFieldName='name'
                errors={errors}
                {...field}
              />
            )}
          />
          {/* <img src="/assets/images/icons/edit.svg" alt="Edit" /> */}
        </div>

        <div className='p-4 border border-bord-g30 rounded-xl'>
          <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
            <h3 className='text-lsm font-semibold text-b10'>Pension Details</h3>
          </div>
          <p className='mb-4 text-lsm font-semibold text-b10'>
            When would you like to retire ?
          </p>
          <div
            className={`pb-3 ${
              watchAllFields.retirement_type ===
                RETIREMENT_TYPE_OPTIONS.AT_AGE ||
              watchAllFields.retirement_type === RETIREMENT_TYPE_OPTIONS.AT_DATE
                ? 'border-b border-bord-g30'
                : ''
            }`}
          >
            <div className='flex gap-6 items-center'>
              <div className='flex items-center gap-3'>
                <Radio
                  theme={radioButtonTheme}
                  id='asap-without-early-reductions'
                  {...register('retirement_type', {
                    required: 'This field is required',
                  })}
                  value={RETIREMENT_TYPE_OPTIONS.ASAP_WITHOUT_EARLY_REDUCTION}
                />
                <Label
                  className='text-lsm font-normal text-b10'
                  htmlFor='asap-without-early-reductions'
                >
                  ASAP without early reductions
                </Label>
              </div>
              <div className='flex items-center gap-3'>
                <Radio
                  theme={radioButtonTheme}
                  id='as-soon-as-possible'
                  {...register('retirement_type', {
                    required: 'This field is required',
                  })}
                  value={RETIREMENT_TYPE_OPTIONS.AS_SOON_AS_POSSIBLE}
                />
                <Label
                  className='text-lsm font-normal text-b10'
                  htmlFor='as-soon-as-possible'
                >
                  As soon as possible
                </Label>
              </div>
              <div className='flex items-center gap-3'>
                <Radio
                  theme={radioButtonTheme}
                  id='on-date'
                  {...register('retirement_type', {
                    required: 'This field is required',
                  })}
                  value={RETIREMENT_TYPE_OPTIONS.AT_DATE}
                />
                <Label
                  className='text-lsm font-normal text-b10'
                  htmlFor='on-date'
                >
                  On date
                </Label>
              </div>
              <div className='flex items-center gap-3'>
                <Radio
                  theme={radioButtonTheme}
                  id='at-age'
                  {...register('retirement_type', {
                    required: 'This field is required',
                  })}
                  value={RETIREMENT_TYPE_OPTIONS.AT_AGE}
                />
                <Label
                  className='text-lsm font-normal text-b10'
                  htmlFor='at-age'
                >
                  At age
                </Label>
              </div>
            </div>
            <div>
              {errors?.retirement_type && (
                <p className='text-red-600 text-xxs mt-1'>
                  {errors.retirement_type.message}
                </p>
              )}
            </div>
          </div>
          {watchAllFields.retirement_type ===
            RETIREMENT_TYPE_OPTIONS.AT_DATE && (
            <div className='mt-4 flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>Date</span>
              <div className='flex gap-4 items-center'>
                <div>
                  <Controller
                    name='retirement_date'
                    control={control}
                    rules={{
                      required: 'On Date is required',
                      pattern: {
                        value: DATE_DD_MM_YYYY_FORMAT_REGEX,
                        message: 'On Date must be in the format DD/MM/YYYY',
                      },
                    }}
                    render={({ field }) => (
                      <CustomDatePicker
                        value={field.value}
                        onChange={field.onChange}
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        autoComplete='off'
                        placeholderText='DD/MM/YYYY'
                      />
                    )}
                  />
                  {errors.retirement_date && (
                    <p className='text-red-600 text-xxs mt-1'>
                      {errors.retirement_date.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          {watchAllFields.retirement_type ===
            RETIREMENT_TYPE_OPTIONS.AT_AGE && (
            <div className='mt-4 flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>Age</span>
              <div className='flex gap-4 items-center'>
                <div>
                  <Controller
                    name='retirement_age'
                    control={control}
                    rules={{
                      required: 'Age is required',
                      pattern: {
                        value: ageRegex,
                        message: 'Please enter a valid age',
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        parentClass='!w-fit'
                        className='max-w-64'
                        type='number'
                        placeholder='Age'
                        errors={errors}
                        errorFieldName='retirement_age'
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-end '>
          <FormSubmit
            buttonText={'Next'}
            className={'primary-btn bg-black hover:bg-black hover:text-white'}
            type={'submit'}
          />
        </div>
      </form>
    </>
  );
};

ScenarioDetails.propTypes = {
  tabHandler: PropTypes.func.isRequired,
  scenarioData: scenarioPropType,
  setScenarioData: PropTypes.func.isRequired,
};
ScenarioDetails.defaultProps = {
  scenarioData: {},
};

export default ScenarioDetails;
