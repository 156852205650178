import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RangeSlider } from 'flowbite-react';
import { FormSubmit } from '../Generic'
Chart.register(ChartDataLabels);
const PensionBenefitsComparison = ({ tabHandler }) => {
    const [sliderValue, setSliderValue] = useState(50);
    const handleSliderValue = (e, value) => {
        e.preventDefault();
        setSliderValue(value)
    }
    const chartRef = useRef(null);

    const labels = ['Gross Pension', 'Gross Survivor Pension'];
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'No Buyback',
                borderRadius: 4,
                borderWidth: 0,
                data: [51032.84, 38878.28],
                colorCodes: {
                    primary: '#409D35',
                    secondary: '#142D33'
                }
            }, {
                label: 'With Buyback',
                borderRadius: 4,
                borderWidth: 0,
                data: [56771.04, 45416.83],
                colorCodes: {
                    primary: '#5ED9F6',
                    secondary: '#142D33'
                }

            }
        ],
    };



    const options = {

        locale: 'en-US',
        responsive: true,
        categoryPercentage: 0.5,
        barPercentage: 0.92,

        plugins: {
            title: {
                display: true,
                text: 'Buyback vs No Buyback',
                align: 'center',
                color: '#1A5866',
                font: {
                    size: 18,
                    weight: 600
                }
            },
            legend: {
                display: true,
                position: 'top',
            },
            datalabels: {
                color: '#ffffff',
                anchor: 'center',
            }

        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: '80% Survivor Pension 10 Years Lifetime Guarantee',
                    align: 'center',
                    color: '#1A5866',
                    font: {
                        size: 14,
                        weight: 600,
                    }
                },
                ticks: {
                    color: '#1A5866',
                    font: {
                        size: 16,
                        weight: 600
                    }
                },
                grid: {
                    display: false
                }
            },
            y: {
                min: 0, // minimum value
                max: 100000, // maximum value
                title: {
                    display: true,
                    text: 'Gross Pension (Annual)',
                    align: 'center',
                    rotation: 90,
                    color: '#1A5866',
                    font: {
                        size: 14,
                        weight: 600,
                    }
                },
                ticks: {
                    stepSize: 20000,
                    callback: (value) => {
                        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3 }).format(value).replace(/(\$)/, '$ ')
                    },
                    color: '#222222',
                    font: {
                        size: 16,
                        weight: 600
                    }
                },
                beginAtZero: true,
                grid: {
                    display: false
                }
            }
        }
    }
    useEffect(() => {
        if (chartRef.current) {

            const chartInstance = chartRef.current;
            const ctx = chartInstance.ctx;
            let yAxis = chartInstance.scales.y;
            let yBottom = yAxis.getPixelForValue(0)
            ctx.save();

            chartInstance.data.datasets.forEach((dataset) => {
                dataset.backgroundColor = dataset.data.map(v => {
                    let yTop = yAxis.getPixelForValue(v);
                    let gradient = ctx.createLinearGradient(0, yTop, 0, yBottom);
                    gradient.addColorStop(0.3, dataset.colorCodes.primary);
                    gradient.addColorStop(1, dataset.colorCodes.secondary);
                    return gradient;
                });
            });
            ctx.restore();
            chartInstance.update();
        }
    }, [chartRef, data]);


    const chartAreaBackground = {
        id: 'chartAreaBackground',
        beforeDatasetsDraw(chart) {
            // eslint-disable-next-line no-unused-vars
            const { ctx, chartArea: { top, bottom, left, right, width, height } } = chart;
            ctx.save();
            const gradient = ctx.createLinearGradient(0, top - 200, 0, bottom);
            gradient.addColorStop(0, '#247F93');
            gradient.addColorStop(0.87, '#0F333B');
            gradient.addColorStop(1, '#0A2429');
            ctx.fillStyle = gradient;
            ctx.fillRect(left, top, width, height);
            ctx.restore();
        }
    }






    return (
        <>
            <div className=' relative  h-full flex flex-col justify-between   '>
                <div className='pb-2'>
                    <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
                        Comparison of Pension Benefits
                    </h2>
                </div>
                <div className='h-[calc(100%_-_74px)] overflow-auto'>
                    <div className='mx-6 p-4 rounded-2xl bg-white shadow-double min-h-[450px]'>
                        <div className="flex flex-col gap-20">
                            <Bar ref={chartRef} data={data} options={options} plugins={[chartAreaBackground, ChartDataLabels]} />
                        </div>
                    </div>

                    <div className="bg-white my-10 px-4 py-6 shadow-double rounded-2xl border border-primary ">
                        <div className=" p-4 bg-light-gray rounded-lg flex flex-col gap-2">
                            <div className="text-right">
                                <button ><img src="/assets/images/icons/close.svg" alt="close" /></button>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="text-lsm text-b10 font-semibold">Buyback this leave for</p>
                                <p className="text-md text-b10 font-semibold">$99,999</p>
                            </div>

                            <div className="flex flex-col gap-6">
                                <RangeSlider onChange={(e) => setSliderValue(e.target.value)} min={0} max={100} step={25} id="sm-range" sizing="sm" value={sliderValue} />
                                <div className="flex items-center gap-[30px] ">
                                    <button
                                        onClick={(e) => handleSliderValue(e, 0)}
                                        className={`${sliderValue == 0 ? 'primary-btn' : 'secondary-btn-blue'}  w-full flex-grow`} >
                                        0%
                                    </button>
                                    <button
                                        onClick={(e) => handleSliderValue(e, 25)}
                                        className={`${sliderValue == 25 ? 'primary-btn' : 'secondary-btn-blue'}  w-full flex-grow`} >
                                        25%
                                    </button>
                                    <button
                                        onClick={(e) => handleSliderValue(e, 50)}
                                        className={`${sliderValue == 50 ? 'primary-btn' : 'secondary-btn-blue'}  w-full flex-grow`} >
                                        50%
                                    </button>
                                    <button
                                        onClick={(e) => handleSliderValue(e, 75)}
                                        className={`${sliderValue == 75 ? 'primary-btn' : 'secondary-btn-blue'}  w-full flex-grow`} >
                                        75%
                                    </button>
                                    <button
                                        onClick={(e) => handleSliderValue(e, 100)}
                                        className={`${sliderValue == 100 ? 'primary-btn' : 'secondary-btn-blue'}  w-full flex-grow`} >
                                        100%
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="mt-10 flex gap-2 w-full items-center justify-end">
                            <FormSubmit className={'secondary-btn-blue border-transparent'} buttonText={'Cancel'} />
                            <FormSubmit className={'secondary-btn-blue border-transparent'} buttonText={'Buy Later'} />
                            <FormSubmit className={'primary-btn'} buttonText={'Buy Now'} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
                <FormSubmit buttonText={'Previous'} className={'secondary-btn-white text-black border-black hover:text-black'} onClick={() => tabHandler(7)} />
                <FormSubmit buttonText={'Next'} className={'primary-btn bg-black hover:bg-black hover:text-white'} onClick={() => tabHandler(9)} />
            </div>
        </>
    )
}

PensionBenefitsComparison.propTypes = {
    tabHandler: PropTypes.func.isRequired,
};
export default PensionBenefitsComparison
