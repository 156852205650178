import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const FormSelect = forwardRef(({
  icon,
  errorFieldName,
  errors,
  parentClass,
  className,
  SelectOptions,
  defaultValue,
  updateEffect,
  ...rest
}, ref) => {

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '2px',
      borderRadius: '50px',
      transition: 'background-color 0.3s',
      animation: updateEffect ? 'fields-pulse-bg 1.5s infinite' : '',
    }),
    input: (provided) => ({
      ...provided,
      padding: '7px',
    }),
  };
  
  return (
    <>
      <div className={`relative w-full ${parentClass}`}>
        <Select
          ref={ref}
          className={`basic-single ${className}`}
          classNamePrefix="custom-select"
          defaultValue={defaultValue}
          name="color"
          options={SelectOptions}
          styles={customStyles}
          isSearchable={false}
          {...rest}
        />
        {icon && (
          <img
            className="absolute inset-0 left-3 top-1/2 -translate-y-1/2"
            src={icon}
            alt="Icon"
          />
        )}
      </div>
      {errors?.[errorFieldName] && (
        <p className="text-red-600 text-xxs mt-1">
          {errors[errorFieldName].message}
        </p>
      )}
    </>
  );
});

FormSelect.displayName = 'FormSelect';

FormSelect.propTypes = {
  icon: PropTypes.string,
  parentClass: PropTypes.string,
  className: PropTypes.string,
  errorFieldName: PropTypes.string,
  errors: PropTypes.object,
  SelectOptions: PropTypes.array,
  defaultValue: PropTypes.object,
  updateEffect: PropTypes.string,
};

FormSelect.defaultProps = {
  errorFieldName: '',
  errors: {},
  updateEffect: '',
};

export default FormSelect;