import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

export const fetchPensionApplicationFormData = createAsyncThunk(
  'pensionApplicationForm/fetchPensionApplicationFormData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('retirement/form/get');
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
