import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AudioPlayer = ({ audioData, audioFormat }) => {
  const { userInfo } = useSelector((state) => state.user);
  const audioRef = useRef(null);
  const isVoiceOverEnabled = userInfo?.data?.is_voice_over_enabled;
  useEffect(() => {
    if (audioData && audioFormat) {
      const audio = audioRef.current;
      audio.src = `data:audio/${audioFormat};base64,${audioData}`;
    }
  }, [audioData, audioFormat]);
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      if (isVoiceOverEnabled) {
        audio.muted = false;
      } else {
        audio.pause();
        audio.currentTime = 0;
        audio.muted = true;
      }
    }
  }, [isVoiceOverEnabled]);
  return (
    <div className='flex-col items-center hidden'>
      <audio autoPlay ref={audioRef} muted={!isVoiceOverEnabled} />
    </div>
  );
};

AudioPlayer.propTypes = {
  audioFormat: PropTypes.string,
  audioData: PropTypes.string,
};

export default AudioPlayer;
