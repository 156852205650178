import React from 'react';
import PropTypes from 'prop-types';
import { scenarioPropType } from '../../helper';
import ScenarioListingDetail from './ScenarioListingDetail';
import ScenarioListingMoreDetail from './ScenarioListingMoreDetail';

function ScenarioListing({
  openProjectionDetails,
  isScenarioSelected,
  selectedScenario,
  setOpenProjectionDetails,
  projectionData,
  fetchScenarios
}) {
  return (
    <div className='relative w-full'>
      <p className='mb-2 text-xxl font-bold text-b10'>
        {isScenarioSelected ? selectedScenario?.name : 'My Projection'}
      </p>
      <div className='w-1/2 relative'>
        <ScenarioListingDetail
          openProjectionDetails={openProjectionDetails}
          setOpenProjectionDetails={setOpenProjectionDetails}
          isScenarioSelected={isScenarioSelected}
          selectedScenario={selectedScenario}
          projectionData={projectionData}
          fetchScenarios={fetchScenarios}
        />
        <ScenarioListingMoreDetail
          openProjectionDetails={openProjectionDetails}
          isScenarioSelected={isScenarioSelected}
          selectedScenario={selectedScenario}
          projectionData={projectionData}
        />
      </div>
    </div>
  );
}

export default ScenarioListing;
ScenarioListing.propTypes = {
  isScenarioSelected: PropTypes.bool,
  openProjectionDetails: PropTypes.bool,
  selectedScenario: scenarioPropType,
  setOpenProjectionDetails: PropTypes.func,
  projectionData: scenarioPropType,
  fetchScenarios: PropTypes.func,
};

ScenarioListing.defaultProps = {
  isScenarioSelected: false,
  openProjectionDetails: false,
  selectedScenario: null,
  setOpenProjectionDetails: () => {},
  fetchScenarios: () => {},
};
