import React from 'react';
import PropTypes from 'prop-types';

function Section({ title, items }) {
  return (
    <div className='p-2.5 bg-light-gray border border-bord-g10 rounded-lg'>
      <div className='mb-4 flex items-center gap-2'>
        <p className='font-ibm text-lsm font-semibold text-b10'>{title}</p>
        <img
          className='w-4 h-4'
          src='/assets/images/icons/info-circle.svg'
          alt='Info'
        />
      </div>
      <div className='flex flex-col gap-4'>
        {items?.map(({ label, value }, idx) => (
          <div key={idx} className='flex justify-between'>
            <p className='text-lsm font-normal text-neutral-gray'>{label}</p>
            <p className='text-lsm font-medium text-b10'>{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Section;
Section.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  title: PropTypes.string,
};

Section.defaultProps = {
  items: [],
  title: '',
};
