import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormSubmit } from '../Generic';
import {
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { setDataForChatBot } from '../../features/chatbot/chatbot';
import { STORAGE_KEYS } from '../../helper';
import {
  toastMessage,
  updatePensionApplicationFormStep,
} from '../../helper/function';

const DocumentInfo = ({ tabHandler }) => {
  const dispatch = useDispatch();

  const { screenName, updatedDataFromChatBot } = useSelector(
    (state) => state.chatbot
  );
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );

  const handleNextButton = async () => {
    try {
      if (
        pensionApplicationFormData?.step ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO
      ) {
        await updatePensionApplicationFormStep({
          step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER,
        });
      }
      tabHandler(
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER
      );
    } catch (error) {
      console.error('error', error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message ||
          'Error while next step from document info'
      );
    }
  };

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO
    ) {
      dispatch(
        setDataForChatBot({ sender: localStorage.getItem(STORAGE_KEYS.token) })
      );
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO &&
      !updatedDataFromChatBot?.is_error_occur &&
      (updatedDataFromChatBot?.is_next || updatedDataFromChatBot?.want_submit || updatedDataFromChatBot?.is_previous)
    ) {
      if(updatedDataFromChatBot?.is_previous){
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
        )
      } else {
        handleNextButton();
      }
    }
  }, [updatedDataFromChatBot]);

  return (
    <>
      <div className='relative  h-full flex flex-col justify-center items-center'>
        <img
          src='/assets/images/icons/folder.svg'
          alt='download'
          height={160}
          width={160}
        />
      </div>
      {/* bottom buttons */}
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
};
DocumentInfo.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};

export default DocumentInfo;
